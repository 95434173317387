import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  address: [],
  coords: {
    latitude: 0,
    longitude: 0,
  },
  shortData: {},
};

export const oneProfileSlice = createSlice({
  name: "oneProfile",
  initialState,
  reducers: {
    setOneProfileAddress: (state, action) => {
      state.address = [action.payload];
    },
    setOneProfileCoords: (state, action) => {
      const { latitude, longitude } = action.payload;
      state.coords = {
        latitude,
        longitude,
      };
    },
    setShortProfileData: (state, action) => {
      state.shortData = action.payload;
    },
  },
});

export const {
  setOneProfileAddress,
  setOneProfileCoords,
  setShortProfileData,
} = oneProfileSlice.actions;

export const getOneProfileAddress = (state) => state.oneProfile.address;
export const getOneProfileCoords = (state) => state.oneProfile.coords;
export const getShortProfileData = (state) => state.oneProfile.shortData;

export default oneProfileSlice.reducer;
