import useLadyService from "@/services/LadyService"
import { useEffect } from "react";

export const RedirectLk = () => {
    const {userType, navigate, token} = useLadyService();

    useEffect(() => {
        if(userType !== "default" && token) {
            if(userType === "indi") {
                navigate(`/lk/indi`)
            }
            if(userType === "customer") {
                navigate(`/lk/customer`)
            }
        }
    }, [userType, token]);
  return null
}
