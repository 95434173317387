import useLadyService from "@/services/LadyService";
import {Checkbox, Select} from "@/components/ui";
import "./langAi.scss";

const langs = ["en", "cz", "ru"];
const langOptions = [
    {id: "en", title: "EN", value: "en"},
    {id: "ru", title: "RU", value: "ru"},
    {id: "cz", title: "CZ", value: "cz"},
];

const LangAi = ({selectedLangsAi, langAi, setLangAi, setSelectedLangsAi}) => {
    const {t, lang} = useLadyService();
    const windowWidth = window.innerWidth;
    const isMobile = windowWidth < 767.98;

    const MobileWrapper = ({children}) => {
        return isMobile ? (
            <div className="d-flex gap-8 w-100 align-center">{children}</div>
        ) : (
            children
        );
    };

    const handleCheckboxChange = (item) => {
        if (selectedLangsAi.includes(item)) {
            setSelectedLangsAi((prev) => prev.filter((i) => i !== item));
        } else {
            setSelectedLangsAi((prev) => [...prev, item]);
        }
    };

    return (
        <div className={`d-flex gap-8 w-100 lang-ai`}>
            <div
                className={`d-flex gap-8 align-center  ${isMobile ? "flex-wrap" : ""}`}
            >
                <span>{t("textlanguage") + ":"}</span>
                <Select
                    onChange={setLangAi}
                    options={langOptions.sort((a, b) => {
                        if (a.value === lang) return -1;
                        if (b.value === lang) return 1;
                        return 0;
                    })}
                    clazz={"button--secondary"}
                    arrowSize={"s"}
                    size={"xs"}
                    name={"lang-ai"}
                ></Select>
                <MobileWrapper>
                    <span className="width-max">{t("translatetoother")}</span>
                    {langs.map((item) => {
                        if (item !== langAi) {
                            return (
                                <Checkbox
                                    title={item.toUpperCase()}
                                    disabled={false}
                                    clazz={"lh-16"}
                                    id={`lang_for_traslate${item}`}
                                    checked={selectedLangsAi.includes(item)}
                                    onChange={() => handleCheckboxChange(item)}
                                />
                            );
                        }
                    })}
                </MobileWrapper>
            </div>
        </div>
    );
};
export default LangAi;
