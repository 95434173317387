import { ButtonLink, Tippy, Icon } from "@/components/ui";
import { useState } from "react";
import useLadyService from "@/services/LadyService";

import "./tariffsPage.scss";

const TariffsTable = ({refElem}) => {
  const { windowWidth, t, userTariff } = useLadyService();

  const tariff = userTariff?.data?.length ? userTariff?.data[0] : "silver";

  const isSilver = tariff === "silver";
  const isGold = tariff === "gold";
  const isPlatinum = tariff === "platinum";

  const tabCount = tariff === "silver" ? 1 : tariff === "gold" ? 2 : 4;


  const [tab, setTab] = useState(String(tabCount));
  function changeTabOnClick(num) {
    if (windowWidth < 767.98) {
      setTab(num);
    }
  }

  function changeActiveTab(num) {
    if (windowWidth > 767.98) {
      setTab(num);
    }
  }

  return (
    <section>
      <h1 className={"mb-40 mb-24-mob"}>{t("selecttariff")}</h1>

      <div id={'tariffs'} ref={refElem} data-active-tariff={tab} className="tariffs-table">
        <div className="tariffs-table__header">
          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__heading"}>{t("options")}</div>
            <div className="tariffs-table__row tariffs-table__buttons">
              <button
                onClick={() => changeTabOnClick('1')}
                onMouseEnter={() => changeActiveTab('1')}
                type={"button"}
                className={`tariffs-table__silver${
                  !!isSilver ? " _active" : ""
                }`}
              >
                Silver
              </button>
              <button
                onClick={() => changeTabOnClick('2')}
                onMouseEnter={() => changeActiveTab('2')}
                type={"button"}
                className={`tariffs-table__gold${
                  !!isGold ? " _active" : ""
                }`}
              >
                Gold
              </button>
              <button
                onClick={() => changeTabOnClick('3')}
                onMouseEnter={() => changeActiveTab('3')}
                type={"button"}
                className={`tariffs-table__platinum${
                  !!isPlatinum ? " _active" : ""
                }`}
              >
                Platinum
              </button>
            </div>
          </div>
        </div>

        <div className="tariffs-table__body">
          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>{t("guaranteeanon")}</div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>{t("favotitemodels")}</div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>
              {t("notificationsnew")}
            </div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>
              {t("retentionfilters")}
            </div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>{t("reviewsandcom")}</div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>
          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>{t("complaints")}</div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>
              {t("videochatswithmodel")}
            </div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')}>3 €/{t("min")}</div>
              <div onMouseEnter={() => changeActiveTab('2')}>2 €/{t("min")}</div>
              <Tippy
                onMouseEnter={() => changeActiveTab('3')}
                title={`${t("include")} 30 ${t("minit")} ${t("next")} – 3 €`}
              >
                {t("free")}*
              </Tippy>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>
              {t("erocontentaccess")}
            </div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')}>{t("paid")}</div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                {t("discount")} 50%
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>{t("free")}</div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>
              {t("wanotice")}
            </div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')} className={"_gray"}>—</div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>{t("orderescort")}</div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')} className={"_gray"}>
                —
              </div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>{t("inviteseveral")}</div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')} className={"_gray"}>
                —
              </div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>{t("verifytags")}</div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')} className={"_gray"}>
                —
              </div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>
              {t("invitestoparties")}
            </div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')} className={"_gray"}>
                —
              </div>
              <div onMouseEnter={() => changeActiveTab('2')} className={"_gray"}>
                —
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>{t("vipprofile")}</div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')} className={"_gray"}>
                —
              </div>
              <div onMouseEnter={() => changeActiveTab('2')} className={"_gray"}>
                —
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>{t("authenticity")}</div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')} className={"_gray"}>
                —
              </div>
              <div onMouseEnter={() => changeActiveTab('2')} className={"_gray"}>
                —
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>
        </div>

        <div className="tariffs-table__bottom">
          <div
            onMouseEnter={() => changeActiveTab('1')}
            className="tariffs-table__box"
          >
            <p>{t("free")}</p>
            <ButtonLink
              href={`/lk/customer`}
              size={"l"}
              clazz={"button--green"}
              disabled={isSilver}
            >
              {t(isSilver ? "currenttariff" : "select")}
            </ButtonLink>
          </div>

          <div
            onMouseEnter={() => changeActiveTab('2')}
            className="tariffs-table__box"
          >
            <p>25 €/{t("month")}</p>
            <ButtonLink
              href={`/lk/topup`}
              size={"l"}
              clazz={"button--green"}
              disabled={isGold}
              // onClick={(e) => toBalance(e)}
            >
              {t(isGold ? "currenttariff" : "select")}
            </ButtonLink>
          </div>

          <div
            onMouseEnter={() => changeActiveTab('3')}
            className="tariffs-table__box"
          >
            <p>
              33 € <span>100 €</span>
            </p>
            <ButtonLink
              href={`/lk/topup`}
              size={"l"}
              clazz={"button--green"}
              disabled={isPlatinum}
              // onClick={(e) => toBalance(e)}
            >
              {t(isPlatinum ? "currenttariff" : "select")}
            </ButtonLink>
            <p className={"p2"}>
              <span className={"title fz-22 fz-20-mob mb-8"}>
                {t("soperaction")}
              </span>
              {t("promotion")}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TariffsTable;
