import { Link, Route, Routes, Navigate } from "react-router-dom";

const ExternalLink = ({ to, text, target = '_blanc', ...routeProps }) => {
    return (
        <>
            <Link to={to} target={target}>{text ?? to}</Link>
            <Routes>
                <Route
                    {...routeProps}
                    element={<Navigate to={to} replace />}
                />
            </Routes>
        </>
    );
};

export default ExternalLink;
