import {
  ButtonLink,
  Icon,
  Button,
  InputInLabel,
  Dropdown,
} from "@/components/ui";
import debounce from "lodash.debounce";

import "./addHeader.scss";

import useLadyService from "@/services/LadyService";
import { setAddOrImportProfile } from "@/stores/slices/popupSlice";
import { useCallback, useState } from "react";
import showToast from "@/components/toast/Toast";
import { makeRequest } from "@/services/makeRequest";
import { Link } from "react-router-dom";
import { ExternalLink } from "../../../../../../ui";

const options = [
  { name: "banging.cz" },
  { name: "secretgirlprague.com" },
  { name: "eurogirlsescort.com" },
  { name: "escorts.cz" },
];

const AddHeader = () => {
  const { t, dispatch } = useLadyService();

  const [importAdValue, setImportAdValue] = useState("");

  const fetchData = async () => {
    try {
      const route = `user/profile/import`;
      const method = "POST";
      const payload = {
        url: importAdValue,
      };

      const result = await makeRequest({ route, method, payload });
      if(result) {
        
      }

      if (!result) {
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    } catch (error) {
      console.log(error);

      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  const importAd = () => { 
    fetchData()
  };

  return (
    <div className="add__root">
      <Button
        // href={`/lk/indi/create`}
        clazz={"button--green _no-underline"}
        size="l"
        onClick={() => dispatch(setAddOrImportProfile(true))}
      >
        <Icon spritePath={"plus"} size={"l"} />
        <span className="p1 add__add">{t("add")}</span>
        <span className="p1 add__addad">{t("addad")}</span>
      </Button>
      <div className="add__right">
        <span className={"p2 color-main add__text"}>
          {t("importfor")}, <p>{t("parametrs")}</p>
        </span>

        {/* <Dropdown
          title={t("selectweb")}
          name={"site"}
          clazz={"add__select"}
          size={"l"}
        >
          {options.map((i, index) => {
            return (
              <ButtonLink
                clazz={`_no-underline justify-start`}
                // href={i.bo}
                title={i.name}
                size={"xs"}
                key={index}
                name={i.name}
              >
                {i.name}
              </ButtonLink>
            );
          })}
        </Dropdown> */}

        <div className="import-ad__block">
          {t("avaliblesites")}
          <Icon size={"s"} spritePath={"info"} />

          <div className="import-ad__links">
          {options.map((i) => {
            return (
              <ExternalLink exact path={`/${i.name}`} to={`https://${i.name}`} />
            )
          })}

          </div>
        </div>

        <InputInLabel
          type={"text"}
          id={"import-ad"}
          placeholder={t("linkinenglish")}
          //   register={{ ...register("tg") }}
          //   clazz={"w-100"}
          value={importAdValue}
          onChange={(e) => {
            setImportAdValue(e.target.value);
          }}
          clazz={"add__link"}
        >
          {t("url")}
        </InputInLabel>

        <Button
          clazz={"add__import__modal button--primary"}
          size={"l"}
          onClick={() => {
            importAd()
          }}
        >
          <Icon spritePath={"import"} size={"l"} />
          <span className="p1">{t("import")}</span>
        </Button>

        <Button clazz={"add__import button--primary"} size={"l"}    onClick={ () => {importAd()}}>
          <Icon spritePath={"import"} size={"l"} />
          <span className="p1">{t("import")}</span>
        </Button>
      </div>
    </div>
  );
};

export default AddHeader;
