import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getApiUserReady } from "@/stores/slices/userSlice";
import { useEffect, useState } from "react";
import useLadyService from "@/services/LadyService";

const routeRoles = {
  "/lk": ["indi", "customer"],
  "/lk/customer": ["customer"],
  "/lk/agency": ["agency"],
  "/lk/indi": ["indi"],
  "/lk/topup": ["indi", "customer", "agency"],
  "/lk/balance": ["indi", "customer", "agency"],
  "/lk/withdraw": ["indi", "customer", "agency"],
};

function PrivateRoute({ children }) {
  const { userType, token } = useLadyService();
  const navigate = useNavigate();

  const apiReady = useSelector(getApiUserReady);

  const location = useLocation();
  const currentPath = location.pathname;

  const [renderElem, setRenderElem] = useState(false);

  useEffect(() => {
    if (token && apiReady) {
      const isAuthorized = Object.keys(routeRoles).some((route) => {
        if (currentPath.includes(route)) {
          setRenderElem(true);
          return routeRoles[route].includes(userType);
        }
        setRenderElem(false);
        return false;
      });

      if (!isAuthorized) {
        console.log('private-router');
        navigate("/");
      }
    }
    if (!token && !apiReady) {
      console.log('private-router');
      navigate("/");
    }
  }, [apiReady, currentPath, userType, token, navigate]);

  if (!token && !renderElem) {
    return null;
  } else {
    return children;
  }
}

export default PrivateRoute;
