import { Link, useNavigate } from "react-router-dom";
import { Avatar, Button, RatingStars } from "@/components/ui";

import ResStatus from "./ResStatus";
import moment from "moment";
import { wordEndings } from "../../../../../../helper/wordEndings";
import { CheckPhonePopup } from "../../../../../popups";
import React, { useState } from "react";

import "./resContainer.scss";
import useLadyService from "../../../../../../services/LadyService";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import {ButtonLink, Icon, Tag, UserBadge} from "../../../../../ui";

const statusObj = {
  0: "workstatus",
  1: "approvestatus",
  2: "declinestatus",
  3: "completestatus",
};

const typeObj = {
  1: "incall",
  2: "onclient",
  3: "accompaniment",
};

const ResContainer = (props) => {
  const { data, setData } = props;

  const {
    from,
    price: priceFrom,
    whatsapp,
    phone,
    profile,
    book_at,
    status,
    prepay,
    created_at,
    address,
    id: resId,
    reviews = [],
  } = data;

  const {
    name: nameFrom,
    // phone: phoneFrom,
    rating: ratingFrom,
    tariff: tariffFrom,
    // whatsapp: whatsappFrom,
  } = from;

  const phoneRes =  formatPhoneNumberIntl(`+${phone}`);

  const { duration, price, type } = priceFrom;

  const { name: nameProfile, photo: photoProfile, slug: slugProfile } = profile;

  const [isOpenCheckPhone, setIsOpenCheckPhone] = useState(false);

  const { t, setMediaSize, windowWidth } = useLadyService();

  let classVariant;
  switch (statusObj[status]) {
    case "approvestatus": {
      classVariant = " approve";
      break;
    }

    case "completestatus": {
      classVariant = " approve";
      break;
    }

    case "declinestatus": {
      classVariant = " decline";
      break;
    }

    default: {
      classVariant = " work";
    }
  }

  const handleOpen = () => {
    setIsOpenCheckPhone(true);
  };

  const bookAt = moment.unix(book_at).format("DD.MM.YYYY HH:mm");
  const startAt = moment.unix(created_at).format("DD.MM.YYYY HH:mm");

  const durRes =
    duration >= 60
      ? `${t("onthe")} ` +
        duration / 60 +
        " " +
        wordEndings(duration / 60, [t("hour"), t("hourfew"), t("hourmany")])
      : `${t("onthe")}  ${duration} ${t("minit")}`;

  return (
    <>
      {isOpenCheckPhone && (
        <CheckPhonePopup
          open={isOpenCheckPhone}
          setOpen={setIsOpenCheckPhone}
          phoneClient={phone}
        />
      )}
      <div className={"res__root"}>
        <div className={`res__user__main${classVariant}`}>
          <div className="res__user__block">
            <UserBadge
              props={{
                slug: tariffFrom,
                name: nameFrom,
                score: ratingFrom
              }}
            />

            <span className={'p3 color-main'}>{startAt}</span>
          </div>
          <div className="res__user__block">
            {!!whatsapp ? (
              <div className="res__user-contants fd-column">
                <Link to={`tel:${phone}`} className={"hover-line"}>
                  <b>{phoneRes}</b>
                </Link>

                <ButtonLink
                  href={`https://wa.me/${whatsapp}`}
                  clazz={"button--primary"}
                  size={"xs"}
                  target={"_blank"}
                >
                  <Icon
                    size={"m"}
                    spritePath={"whatsapp"} />
                  {whatsapp}
                </ButtonLink>
              </div>
            ) : (
              <Link to={`tel:${phone}`} className={"hover-line"}>
                <b>{phoneRes}</b>
              </Link>
            )}

            <Button
              size={"xs"}
              buttonType={"submit"}
              clazz={"button_outline--green"}
              title={t("crossnumber")}
              onClick={handleOpen}
            >
              {t("crossnumber")}
            </Button>
          </div>
        </div>

        <div className={`res__info__block${classVariant}`}>

            <div className={`res__info__block__money${classVariant}`}>
              <div className="res__price ">
                <span className={'p3'}>{t("dateandtime")}</span>
                <span className="p1 color-main">{bookAt}</span>
              </div>
              <div className="res__price">
                <div className="res__var">
                  <Tag clazz={'p2'}>
                    {t(`${typeObj[type]}`)}
                  </Tag>
                  <span className="color-main">{durRes}</span>
                </div>
                {address && (
                  <Link
                    target={'_blank'}
                    to={`//www.google.com/maps/search/?api=1&query=${address.replace(/ /g, '+')}`}
                    title={t('viewonmap')}
                    className="girl-card__address color-main p1 hover-line d-none-tablet-small"
                  >
                    <Icon spritePath={"map"} size={"xs"} clazz={'mr-8'}/>{address}
                  </Link>
                )}
              </div>

              <div className="res__price">
                <span className={'p3'}>{t("prepayment")}</span>
                <span className={'title_h3 currency--erocoin'}>{prepay}</span>
              </div>

              <div className="res__price">
                <span className={'p3'}>{t('onplace')}</span>
                <span className={'title_h3'}>{price - prepay}{' '}€</span>
              </div>
            </div>

          <Link
            to={`/profile/${slugProfile}`}
            target={"_blank"}
            className="res__ad"
            alt="ad"
          >
            <img
              src={setMediaSize(photoProfile, "xs")}
              className="res__ad__photo"
              alt="ad"
            />
            <span title={nameProfile} className="p1 mt-12 res__ad__title color-green text-dots">
              {nameProfile}
            </span>
          </Link>
        </div>

        {address && (
          <Link
            target={'_blank'}
            to={`//www.google.com/maps/search/?api=1&query=${address.replace(/ /g, '+')}`}
            title={t('viewonmap')}
            className="girl-card__address color-main p1 hover-line d-none-tablet-small-reverce"
          >
            <Icon spritePath={"map"} size={"xs"} clazz={'mr-8'}/>
            {address}
          </Link>
        )}

        <ResStatus
          status={status}
          reviews={reviews}
          id={resId}
          setData={setData}
          book_at={book_at}
        />
      </div>
    </>
  );
};

export default ResContainer;
