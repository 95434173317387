import { Outlet } from "react-router-dom";
import { Favicon } from "@/components/ui";
import Filter from "../../filter/Filter";
import {
  Authorization,
  Adult,
  RestorePasswordPopup,
  StartVideochat,
  CreateVideoCallRoom,
  AfterAuth,
} from "../../popups";
import VideoChat from "../../videoChat/VideoChat";
import { useSelector } from "react-redux";
import { getAdultState } from "@/stores/slices/popupSlice";

import MapFilter from "../../mapFilter/MapFilter";

import NewPassPopup from "../../popups/newPass/NewPass";
import { showFilter } from "@/stores/slices/showFilterSlice";
import { getAuthorizationStatus } from "@/stores/slices/popupSlice";
import { getCreateVideoRoomModalStatus } from "@/stores/slices/videoCallSlice";
import { getNewPassStatus } from "@/stores/slices/popupSlice";
import { getStartVideochatStatus } from "@/stores/slices/popupSlice";
import { getVideoCallModalStatus } from "@/stores/slices/videoCallSlice";
import { getMapStatus } from "@/stores/slices/popupSlice";
import { getAfterAuth } from "@/stores/slices/popupSlice";

import Header from "../../header/Header";
import Footer from "../../footer/Footer";

const Layout = ({ titleParams, postDesc }) => {
  const adultPopupState = useSelector(getAdultState);
  const filterValue = useSelector(showFilter);
  const authorizationValue = useSelector(getAuthorizationStatus);
  const modalStatus = useSelector(getCreateVideoRoomModalStatus);
  const statusNewPass = useSelector(getNewPassStatus);
  const videochatStatus = useSelector(getStartVideochatStatus);
  const modalStatusCall = useSelector(getVideoCallModalStatus);
  const mapPopupStatus = useSelector(getMapStatus);
  const afterAuthStatus = useSelector(getAfterAuth);

  return (
    <>
      <Favicon />
      {/* <Helmet>
        <title>{titleParams}</title>
        <meta name="description" content={postDesc} />
      </Helmet> */}

      <Header />
      <Outlet />
      <Footer />


      {!!filterValue && <Filter />}
      {!!authorizationValue && <Authorization />}
      <RestorePasswordPopup />
      {!!statusNewPass && <NewPassPopup />}
      {!!modalStatus && <CreateVideoCallRoom />}
      {!!videochatStatus && <StartVideochat />}
      {!!modalStatusCall && <VideoChat />}
      {!!mapPopupStatus && <MapFilter />}
      {!!adultPopupState && <Adult />}
      {!!afterAuthStatus && <AfterAuth />}
    </>
  );
};

export default Layout;
