import { makeRequest } from "@/services/makeRequest";

export function getIdsFromUrls(objects, slug) {
    const ids = objects.map(obj => {
      
      const target = obj.name || obj.link;
      const match = target.match(/https:\/\/api\.lady4love\.com\/api\/storage\/(\d+)\.(webp|mp4)/);

      if (match) {
        return match[1];
      }

      return null;
    }).filter(id => id !== null);

    const fetchDataEro = async () => {
        try {
          const route = `user/media/${slug}/order`;
          const method = "PUT";
          const payload = {
            ids: ids,
          };
          const data = await makeRequest({ route, method, payload });
          if (data) {
          }
        } catch (error) {
          console.log(error);
          
        }
      };
      fetchDataEro();
  }