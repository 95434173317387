import { Helmet } from "react-helmet";
import { Button, Icon } from "@/components/ui";
import { makeRequest } from "@/services/makeRequest";
import showToast from "../../toast/Toast";
import useLadyService from "@/services/LadyService";
import {Popup} from "../index";

const ActivateWa = (props) => {
  const { open, setOpen, setStatus, status } = props;

  const { t } = useLadyService();

  const handleCloseDelete = () => {
    setOpen(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const fetchData = async () => {
      try {
        const route = `user/set-profiles-status`;
        const method = "PUT";
        const payload = {
          status: !status,
        };

        await makeRequest({ route, method, payload });
        setStatus((prev) => !prev);
        setOpen(false);

        showToast({
          message: t("success"),
          variant: "success",
        });
      } catch (error) {
        setStatus((prev) => !prev);
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };

    // fetchData();
  };

  return (
    <Popup
      open={open}
      setOpen={handleCloseDelete}
      container={'div'}
      id={'activateWa'}
    >
          <h2 className="text-center m-12-24">{t("activatee") + " " + t('notifications').toLowerCase() + " WhatsApp"}</h2>

        <div className="popup-form__inner mb-16 gap-8">
          <p className={"p2 text-center color-main"}>
            {t('metafee')}
          </p>
        </div>
        <h3 className="color-main text-center ">
            {t("pricever")}{" "}
            <span className="currency--erocoin">20</span>
            <span>{`/${t('month')}`}</span>
          
        </h3>
        <div className="d-flex justify-sb gap-16">
          <Button
            size={"l"}
            buttonType={"button"}
            clazz={"button--secondary w-100"}
            onClick={handleCloseDelete}
          >
            {t("back")}
          </Button>
          <Button size={"l-forever"} onClick={handleCloseDelete} clazz={"button--green w-100"}>
            {" "}
            {t("activatee")}
          </Button>
        </div>
    </Popup>
  );
};

export default ActivateWa;
