import { Icon } from "@/components/ui";
import { useTranslation } from "react-i18next";
import useLadyService from "@/services/LadyService";
import { HashLink } from "react-router-hash-link";

import "./rules.scss";

const ContainerBlock = (props) => {
  const { windowWidth } = useLadyService();

  const isSmallTablet = windowWidth < 1000.98;

  const size = isSmallTablet ? "5xl" : "6xl";

  const { text, spritePath, href } = props;
  return (
    <HashLink to={href} className={"td-none rules__block"}>
      <Icon size={size} spritePath={spritePath} />
      <span>{text}</span>
    </HashLink>
  );
};

const Rules = () => {
  const { t } = useLadyService();

  return (
    <div className={"rules__root"}>
      <h2>{t("guide")}</h2>
      <div className={"rules__main"}>
        <ContainerBlock
          text={t("findout")}
          spritePath={"instruction"}
          href={`/guide`}
        />
        <ContainerBlock
          text={t("opportunities")}
          spritePath={"euro"}
          href={`/guide#eroCoins`}
        />
      </div>
      <span className={"rules__secure"}>
        <Icon size={"l"} spritePath={"secure"} />
        <span>{t("secured")}</span>
      </span>
    </div>
  );
};

export default Rules;
