import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {useEffect} from "react";
import useLadyService from "../../services/LadyService";

const ComingSoon = () => {
  const { t } = useTranslation("translation");
  const {scrollToTop} = useLadyService()
  useEffect(() => {
    scrollToTop()
  }, []);
  return (
    <div className="error-page__container text-center">
      <h1>{t("soon")}.</h1>
      <Link to={`/`}>{t("backtomain")}</Link>
    </div>
  );
};

export default ComingSoon;
