import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Icon from "../icon/Icon";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanSorted,
  setAllModels,
  setPageDefault,
  setSearchValue,
  setSortBy,
  setSortedModelsByFilter,
  setStories,
} from "@/stores/slices/modelsState";

import "./select.scss";
import {
  getUserCurrentCity,
  getUserLang,
  setCity,
  setCurrentCity,
  setLang,
} from "@/stores/slices/userSlice";
import { showFilter } from "@/stores/slices/showFilterSlice";
import { resetAll, setSearchFilter } from "@/stores/slices/filterSlice";
import { clearTitle } from "@/stores/slices/titleSlice";
import { resetAllCounters } from "@/stores/slices/counterFilterSlice";
import { useTranslation } from "react-i18next";
import useCityChange from "../../../hooks/useCityChange";
import useLanguageChange from "../../../hooks/useLanguageChange";
import Input from "../input/Input";

const Select = ({...props}) => {
  const {
    type = "",
    arrowSize,
    clazz,
    clazzSvg,
    clazzWrapper,
    options,
    name,
    size = 'l-forever',
    subTitle = "",
    setValue = undefined,
    isSearch = false,
    searchPlaceHolder = "",
    postApi = false,
    onChange = false,
    paramsOnChange = false,
    defaultTitle = "",
    withoutIcon = false,
    hideFlag = false,
    withoutTitle = false,
    placeholder,
    disabled,
    required,
  } = props;

  const dispatch = useDispatch();

  const { i18n } = useTranslation();
  const filterOpen = useSelector(showFilter);
  const currentCity = useSelector(getUserCurrentCity);
  const lang = useSelector(getUserLang);
  const changeCity = useCityChange();
  const changeLanguage = useLanguageChange();

  const [title, setTitle] = useState(
    defaultTitle || withoutTitle
      ? defaultTitle
      : !!options?.length
      ? options[0].title
      : ""
  );

  useEffect(() => {
    if(!!options.length && name === "lang-ai") {
      setTitle(options[0].title)
    }
  }, [lang])

  const [searchVal, setSearchVal] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  const selectRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (selectRef.current &&
      !selectRef.current.contains(event.target))
    {
      setIsOpen(false);
      event.stopPropagation();
    }
  };

  const iconNat = !!options?.length
    ? options?.filter((i) => {
        return i.name === defaultTitle;
      })
    : "";

  const [mainIcon, setMainIcon] = useState(
    !withoutIcon && !!options?.length
      ? !!defaultTitle && name === "create-nationalist" && !!iconNat?.length
        ? iconNat[0].icon
        : options[0].icon
      : null
  );

  useEffect(() => {
    if (name !== "city-create" && !postApi) {
      setTitle(options[0].title);
    }

    if (name === "city") {
      if (currentCity) {
        setTitle(currentCity.title);
        setMainIcon(currentCity.icon);
      }
    }
    if (name === "language") {
      setTitle(lang.toUpperCase());
    }
  }, [currentCity]);

  useEffect(() => {
    if (name === "city") {
      setTitle(currentCity.title);
      setMainIcon(currentCity.icon);
    }
  }, [filterOpen]);

  function setValues(i, paramsOnChange) {
    setTitle(i.title);
    setMainIcon(i.icon);
    !!setValue && setValue(i.title);
    if (onChange) {
      if (paramsOnChange) {
        onChange(i, paramsOnChange);
      } else {
        onChange(i.value ? i.value : i.name);
      }
    }
  }

  const handleClick = (i) => {
    setValues(i, paramsOnChange);
    if (name === "language") {
      changeLanguage(i.value);
      i18n.changeLanguage(i.value);
      dispatch(setLang(i.value));
      console.log('lang change');
    }
    if (name === "sort") {
      dispatch(setSortBy(i.value));
    }
    if (name === "city") {
      dispatch(setPageDefault());
      dispatch(setAllModels([]));
      dispatch(setStories([]));
      dispatch(setSortedModelsByFilter(""));
      dispatch(resetAllCounters());
      changeCity(i.slug);
      dispatch(
        setCurrentCity({
          id: i.id,
          title: i.title,
          icon: i.icon,
          highlight: i.highlight,
          slug: i.slug,
        })
      );

      dispatch(setCity(i.slug));
      if (!filterOpen) {
        dispatch(setAllModels([]));
      }
      dispatch(resetAll());
      dispatch(setSearchFilter(false));
      dispatch(cleanSorted());
      dispatch(setSearchValue(""));
      dispatch(setSortedModelsByFilter(""));
      dispatch(clearTitle());
      dispatch(resetAllCounters());
    }

    setIsOpen(false)
  };

  const searchedData =
    searchVal && !!options?.length
      ? options?.filter((item) => {
          return item.title?.toLowerCase().includes(searchVal.toLowerCase());
        })
      : options;

  return (
    <div
      className={`select${
        required ? ' _required' : ''
      }${
        isOpen ? " select_active" : ""
      }${
        clazzWrapper ? ` ${clazzWrapper}` : ""
      }`}
        ref={selectRef}
    >
      <button
        type={'button'}
        className={`select__button${
          clazz ? ` ${clazz}` : ""
        }${
          size ? ` size-${size}` : ""
        }${
          disabled ? " _disabled" : ""
        }`}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {placeholder ?
          title !== '' ?
            <span className={'select__subtitle'}>{placeholder}</span>
            :
            subTitle && <span className="select__placeholder">{subTitle}</span>
          : null}

        {name === "paymentsMethods" ? (
          <Icon
            type={"payment"}
            size={"m"}
            spritePath={mainIcon}
            clazz={`select__icon${clazzSvg ? ` ${clazzSvg}` : ""}`}
          />
        ) : null}

        {name === "sort" ? (
          <Icon
            size={"m"}
            spritePath={"sort"}
            clazz={`select__icon${clazzSvg ? ` ${clazzSvg}` : ""}`}
          />
        ) : null}

        {(name === "city" || name === "city-create" || type === "flag") &&
        (!withoutIcon || !hideFlag) ? (
          <Icon
            type={"flag"}
            spritePath={mainIcon ? mainIcon : "EU"}
            clazz={`select__icon${clazzSvg ? ` ${clazzSvg}` : ""}`}
          />
        ) : null}

        {placeholder ?
          title === '' ?
            <span className={'select__placeholder'}>{placeholder}</span>
            : title
          : title}

        {arrowSize ? (
          <Icon
            clazz={"select__arrow"}
            size={arrowSize}
            spritePath={"chevrone-down"}
          />
        ) : null}
      </button>

      <div className="select__body">
        {isSearch && (
          <label htmlFor={`${name}-search`} className="select__search">
            <Input
              type="search"
              placeholder={searchPlaceHolder}
              id={`${name}-search`}
              clazz={"search__input"}
              name={`${name}-search`}
              value={searchVal}
              setValue={setSearchVal}
            />
          </label>
        )}
        {!isSearch &&
          !!options?.length &&
          searchedData.map((i, index) => {
            return (
              <button
                type={'button'}
                key={index + 1}
                onClick={() => handleClick(i)}
                onKeyUp={(e) => {
                  if (e.key === "Enter") handleClick(i);
                }}
                className={`select__item`}
              >
                {i.title || i.name}

                {i.icon && !hideFlag ? (
                  <Icon type={"flag"} spritePath={`${i.icon}`} />
                ) : (
                  ""
                )}
              </button>
            );
          })}
        {isSearch && (
          <div className="dropdown__search-select">
            {!!options?.length &&
              searchedData.map((i, index) => {
                return (
                  <button
                    type={'button'}
                    key={index + 1}
                    onClick={() => handleClick(i)}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") handleClick(i);
                    }}
                    className={`select__item`}
                  >
                    {i.title || i.name}

                    {i.icon ? (
                      <Icon type={"flag"} spritePath={`${i.icon}`} />
                    ) : (
                      ""
                    )}
                  </button>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

Select.propTypes = {
  options: PropTypes.array,
  currentValue: PropTypes.object,
  size: PropTypes.string,
  color: PropTypes.string,
  setValue: PropTypes.func,
  mainIcon: PropTypes.string,
};

export default Select;
