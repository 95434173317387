import {
  ButtonLink,
  Icon,
  SwitchTheme,
  Burger,
} from "@/components/ui";
import useLadyService from "@/services/LadyService";
import {
  BurgerMenu,
  DropdownLanguage,
  HeaderTabs, InstructionLink,
  Logo,
  MenuNavBar,
  OrderAndVidChatToggles,
  SupportButton,
  UserWrapperDesktop
} from "../components";

import "../header.scss";

const IndiLK = () => {
  const { windowWidth, t, } = useLadyService();

  if (windowWidth > 1199.98)
    return (
      <>
        <div className="header__top">
          <ButtonLink
            href={"/lk/photograph"}
            title={t("photographer")}
            size={"xs"}
            clazz={"add-form button_outline--green _no-underline"}
          >
            <Icon size={"m"} spritePath={"camera"} />
            {t("photographer")}
          </ButtonLink>

          <InstructionLink/>

          <OrderAndVidChatToggles />

          <SupportButton square={false} size={"xs"} iconSize={"m"} />

          <DropdownLanguage />

          <SwitchTheme type={"switch"} />
        </div>

        <div className="header__bottom">
          <Logo/>

          <div className={"header__tabs"}>
            <HeaderTabs lk/>
          </div>

          <UserWrapperDesktop lk/>
        </div>
      </>
    );

  if (windowWidth > 767.98)
    return (
      <div className="header__top">
        <Logo/>

        <div className="user__wrapper">
          <OrderAndVidChatToggles />

          <SupportButton square={true} size={"s"} iconSize={"m"} />

          <Burger />

          <DropdownLanguage size={"s"} />

          <SwitchTheme type={"switch"} />

          <BurgerMenu lk />
        </div>
      </div>
    );

  return (
    <>
      <div className="header__top">
        <div className="user__wrapper">
          <Burger />

          <DropdownLanguage />
        </div>

        <Logo/>

        <div className="user__wrapper">
          <SupportButton square={true} size={"xs"} iconSize={"s"} />

          <SwitchTheme type={"switch"} />
        </div>

        <BurgerMenu lk />

        <MenuNavBar lk/>
      </div>

      <div className="header__bottom">
        <OrderAndVidChatToggles />
      </div>
    </>
  );
};
export default IndiLK;
