import { useForm } from "react-hook-form";
import { makeRequest } from "@/services/makeRequest";
import { useEffect, useRef, useState } from "react";
import showToast from "@/components/toast/Toast";
import useLadyService from "@/services/LadyService";
import { HeaderForm } from "./HeaderForm";
import { InputForm } from "./InputForm";
import { Checkboxes } from "./Checkboxes";
import { FactureBlock } from "./FactureBlock";
import { BankBlock } from "./BankBlock";
import { ButtonsForm } from "./ButtonsForm";
import { setUserInfoAlreadyExist } from "@/stores/slices/userSlice";
import { setUserInfo } from "@/stores/slices/userSlice";

export const MainForm = ({ type, tubOptions }) => {
  const { commission, formTitle, creditType, commissionText, commissionRate } =
    tubOptions.filter((item) => item.type === type)[0];
  const {
    t,
    userId,
    region,
    userCity,
    userEmail,
    userPhone,
    dispatch,
    userInfoStatus,
  } = useLadyService();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = `user/info`;
        const method = "GET";

        const data = await makeRequest({ route, method });

        if (data) {
          const result = data.data;
          dispatch(setUserInfoAlreadyExist());
          if (result.phone) {
            dispatch(setUserInfo({ stateName: "phone", value: result.phone }));
          }
          if (result.email) {
            dispatch(setUserInfo({ stateName: "email", value: result.email }));
          }
          if (result.address) {
            setValue("address", result.address);
          }
          if (result.city) {
            setValue("city", result.city);
          }
        }
      } catch (error) {
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };
    if (!userInfoStatus) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (region) {
      setValue("country", region);
    }
  }, [region]);

  useEffect(() => {
    if (userCity.title) {
      setValue("city", userCity.title);
    }
  }, [userCity.title]);

  useEffect(() => {
    if (userEmail) {
      setValue("email", userEmail);
    }
  }, [userEmail]);

  useEffect(() => {
    if (userPhone) {
      setPhoneValue(userPhone);
      setValue("phone", userPhone);
    }
  }, [userPhone]);

  const isBank = type === "bank";
  const [valueCoin, setValueCoin] = useState(100);

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    getValues,
    setValue,
  } = useForm({
    mode: "onSubmit",
  });

  const [phoneValue, setPhoneValue] = useState("");

  const [invoiceLoad, setInvoiceLoad] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [currency, setCurrency] = useState("eur");

  const [isRulesCheck, setIsRulesCheck] = useState(false);
  const [rulesError, setRulesError] = useState(false);

  const [isMonthCheck, setIsMonthCheck] = useState(false);
  const [isFactureCheck, setIsFactureCheck] = useState(false);
  const [typePayCheckbox, setTypePayCheckbox] = useState("phiz");

  const fetchInvoice = () => {
    const data = getValues();

    const fetchData = async () => {
      setInvoiceLoad(true);
      try {
        const route = `payment/generate-invoice`;

        const method = "POST";
        const payload = {
          amount: valueCoin,
          ...(typePayCheckbox !== "phiz" && {
            company: data.name,
          }),
          ...(typePayCheckbox === "phiz" && {
            name: data.name,
          }),
          ...(typePayCheckbox !== "phiz" && {
            number: data.lastName,
          }),
          ...(typePayCheckbox === "phiz" && {
            surname: data.lastName,
          }),
          address: data.address,
          city: data.city,
          country: data.country,
          email: data.email,
          phone: data.phone,
          userId: userId,
          priceForOneCoin: currency === "eur" ? 1 : 25,
          ...(!isBank && {
            commission:
              type === "card"
                ? "6"
                : type === "crypto"
                ? "1"
                : type === "paypal"
                ? "3"
                : "0",
          }),
          currency: currency === "eur" ? "EUR" : "CZK",
        };

        const result = await makeRequest({ route, method, payload });
        if (result) {
          const downloadUrl = result.link;
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.target = "_blank";
          link.setAttribute("download", "file.pdf");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setInvoiceLoad(false);
        }
      } catch (error) {
        setInvoiceLoad(false);
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };

    fetchData();
  };

  const onSubmit = (data) => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const route = `payment/create_invoice`;

        const method = "POST";
        const payload = {
          amount: valueCoin,
          payment:
            type === "card"
              ? "eml"
              : type === "paypal"
              ? "paypal"
              : type === "crypto"
              ? "confirmo"
              : "eml",
          data: {
            name: data.name,
            address: data.address,
            city: data.city,
            country: data.country,
            email: data.email,
            lastName: data.lastName,
            phone: data.phone,
            userId: userId,
            priceForOneCoin: currency === "eur" ? 1 : 25,
            ...(!type === "bank" && {
              commission:
                type === "card"
                  ? "6"
                  : type === "crypto"
                  ? "1"
                  : type === "paypal"
                  ? "3"
                  : "0",
            }),
            ...(isMonthCheck && { isMonthCheck: true }),
            ...(isFactureCheck && { factureType: typePayCheckbox }),
            currency: currency === "eur" ? "eur" : "czk",
          },
        };

        const result = await makeRequest({ route, method, payload });
        if (result) {
          const link = result.data.invoice.payment_link;
          const a = document.createElement("a");
          a.href = link;
          a.target = "_blank";
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };

    fetchData();
  };
  const rulesRef = useRef(null);

  return (
    <form
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
      className="d-flex gap-16 fd-column flex-grow form-topup"
    >
      <div className="verads control" ref={rulesRef}>
        <HeaderForm formTitle={formTitle} type={type} />
        <div className="d-flex align-center ver__block__control mb-16">
          <InputForm
            type={type}
            currency={currency}
            setCurrency={setCurrency}
            commissionRate={commissionRate}
            commission={commission}
            commissionText={commissionText}
            creditType={creditType}
            valueCoin={valueCoin}
            setValueCoin={setValueCoin}
          />
        </div>
        {
          <>
            <p>
              {t("recipient") + ": "}
              <span className="color-700">
                IT4L s.r.o., IČO: 19470711, Poděbradská 1026/52, Vysočany, 190
                00 Praha 9, Czech Republic
              </span>
            </p>
            <span>
              {t("purpose") + ": "}
              <span className="color-700">
                {t('purchasing')}
              </span>
            </span>
          </>
        }
        {isBank && <BankBlock currency={currency} />}
        <Checkboxes
          type={type}
          typePayCheckbox={typePayCheckbox}
          setTypePayCheckbox={setTypePayCheckbox}
          isFactureCheck={isFactureCheck}
          setIsFactureCheck={setIsFactureCheck}
          setIsMonthCheck={setIsMonthCheck}
          isMonthCheck={isMonthCheck}
          setRulesError={setRulesError}
          rulesError={rulesError}
          isRulesCheck={isRulesCheck}
          setIsRulesCheck={setIsRulesCheck}
          invoiceLoad={invoiceLoad}
          fetchInvoice={fetchInvoice}
        />
        {isFactureCheck && (
          <FactureBlock
            register={register}
            errors={errors}
            clearErrors={clearErrors}
            typePayCheckbox={typePayCheckbox}
            phoneValue={phoneValue}
          />
        )}
        {!isBank && (
          <ButtonsForm
            invoiceLoad={invoiceLoad}
            isRulesCheck={isRulesCheck}
            rulesRef={rulesRef}
            setRulesError={setRulesError}
            errors={errors}
            register={register}
            clearErrors={clearErrors}
            isFactureCheck={isFactureCheck}
            fetchInvoice={fetchInvoice}
            isLoading={isLoading}
          />
        )}
      </div>
    </form>
  );
};
