import { Icon } from "@/components/ui";
import useLadyService from "@/services/LadyService";
import { HashLink } from "react-router-hash-link";

const ContainerBlock = (props) => {
  const { windowWidth } = useLadyService();

  const isSmallTablet = windowWidth < 1000.98;

  const size = isSmallTablet ? "5xl" : "6xl";

  const { text, spritePath, href } = props;
  return (
    <HashLink to={href} className={"td-none rules__block"}>
      <Icon size={size} spritePath={spritePath} />
      <span>{text}</span>
    </HashLink>
  );
};

const ClientRules = () => {
  const { t } = useLadyService();

  return (
    <div className={"rules__root"}>
      <h2>{t("guide")}</h2>

      <ContainerBlock
        text={t("findout")}
        spritePath={"instruction"}
        href={`/guide`}
      />

      <span className={"rules__secure"}>
        <Icon size={"l"} spritePath={"secure"} />
        <span>{t("secured")}</span>
      </span>
    </div>
  );
};

export default ClientRules;
