import { useEffect, useRef, useState } from "react";
import { getForgotPasswordEmailStatus } from "../../../stores/slices/popupSlice";
import { useSelector } from "react-redux";

export const CodeInput = ({setCodeError, onComplete, setValue}) => {
  const numberOfDigits = 4;
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
  const otpBoxReference = useRef([]);
  const forgotPassEmailState = useSelector(getForgotPasswordEmailStatus);

  useEffect(() => {
    if (forgotPassEmailState) {
      setOtp(new Array(numberOfDigits).fill(""));
      setValue(new Array(numberOfDigits).fill(""))
      if(setCodeError) {
          setCodeError("");
      }
    }
  }, [forgotPassEmailState]);

  function handleChange(value, index) {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);
    setValue(newArr)
    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  function handleBackspaceAndEnter(e, index) {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }
  return (
    <div className="mb-16 mt-16 code-elems">
      {otp.map((digit, index) => (
        <input
          key={index}
          value={digit}
          maxLength={1}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
          ref={(reference) => (otpBoxReference.current[index] = reference)}
        />
      ))}
    </div>
  );
};
