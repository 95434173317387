import useLadyService from "@/services/LadyService";
import { InputInLabel, Icon } from "@/components/ui";
import { useEffect, useMemo, useState } from "react";
import { Select } from "@/components/ui";
import { calculateWithCommission } from "@/helper/calculateWithCommission";
import { setUserInfo } from "@/stores/slices/userSlice";

export const InputForm = ({
  commission,
  commissionText,
  creditType,
  commissionRate,
  currency,
  setCurrency,
  type,
  valueCoin,
  setValueCoin
}) => {
  const windowWidth = window.innerWidth;
  const isMobile = useMemo(() => windowWidth < 767.98, [windowWidth]);

  const { t, dispatch } = useLadyService();
  const currencyRates = {
    czk: 25,
    eur: 1,
  };
  const [valuePrice, setValuePrice] = useState("");

  const currencyOption = {
    main_title: t("currency"),
    options: [
      { id: 1, title: "EUR", value: "eur" },
      { id: 2, title: "CZK", value: "czk" },
    ],
  };

  const calculateEuro = (val) => {
    setValueCoin(val);
    const value = parseFloat(val) || 0;
    const rate = currencyRates[currency] || 1;
    const adjustedValue = value * rate;
    const convertedValue = calculateWithCommission(
      adjustedValue,
      commissionRate
    );
    const roundedValue = parseFloat(convertedValue.toFixed(2));
    setValuePrice(roundedValue);
    dispatch(setUserInfo({ stateName: "amount", value: adjustedValue }));
  };

  const newValue =
    valueCoin * currencyRates[currency] +
    (valueCoin * currencyRates[currency] * commissionRate);

  useEffect(() => {
    if (currency) {
      setValuePrice(newValue);
      dispatch(
        setUserInfo({
          stateName: "amount",
          value: newValue,
        })
      );
    }
  }, [currency]);

  useEffect(() => {
    if (type) {
      setValuePrice(newValue);
    }
  }, [type]);

  return (
    <>
      <div className="d-flex align-center w-100 border-right gap-16">
        <InputInLabel
          price={"erocoin"}
          id={"earn-to"}
          type={"number"}
          placeholder={""}
          value={valueCoin}
          onChange={(e) => calculateEuro(e.target.value)}
          clazz={`${isMobile ? "" : "min-w-160"}`}
        >
          <span>{isMobile ? t("summ") : t("topupamount")}</span>
        </InputInLabel>
        <Select
          onChange={setCurrency}
          options={currencyOption.options}
          clazz={"button button-xs justify-start"}
          arrowSize={"s"}
          name={"currency"}
          defaultTitle={
            currencyOption.options.filter((item) => item.value === currency)[0]
              ?.title || "eu"
          }
          subTitle={t("currency")}
          placeholder={t("currency")}
          withoutTitle={true}
          postApi={true}
        />
        <Icon size={"m"} spritePath={"arrow-right"} />
        <div className="balance-page your__balance gap-8">
          <span class={`p1 ${isMobile ? "fz-13" : ""}`}>
            {t("topayamount")}
          </span>
          <span
            class={`title_h3 mb-0 ${
              currency === "eur" ? "currency--euro" : "currency--czk"
            }`}
          >
            {valuePrice}
          </span>
        </div>
      </div>
      <span className="topup-input__desc">
        {creditType}
        <br />
        <p className={"color-green"}>
          {!!commission
            ? commissionText + " - " + commission + " %"
            : commissionText}
        </p>
      </span>
    </>
  );
};
