import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import {
  Button,
  Icon,
  Dropdown,
  InputInLabel,
  ButtonLink,
} from "@/components/ui";
import {Popup} from "../index";
import { Link } from "react-router-dom";
import { ExternalLink } from "../../ui";

const options = [
  { name: "banging.cz" },
  { name: "secretgirlprague.com" },
  { name: "eurogirlsescort.com" },
  { name: "escorts.cz" },
];

const ImportAdPopup = (props) => {
  const { open, setOpen } = props;

  const { t } = useTranslation("translation");

  const handleCloseDelete = () => {
    setOpen(false);
  };

  return (
    <Popup
      open={open}
      setOpen={handleCloseDelete}
      method={"POST"}
      clazz={'gap-16'}
    >

        <h3 className="m-12-24">{t("importfor")}</h3>
        <div className="popup-form__inner">
          <div className="popup-form__inner text-center">
            <p className={"color-700 p2"}>{t("parametrs")}</p>
          </div>
          <div className="import-ad__block">
          {t("avaliblesites")}
          <Icon size={"s"} spritePath={"info"} />

          <div className="import-ad__links">
          {options.map((i) => {
            return (
              <ExternalLink exact path={`/${i.name}`} to={`https://${i.name}`} />
            )
          })}

          </div>
        </div>
          <InputInLabel
            type={"text"}
            size={"l"}
            // id={"create-tg"}
            placeholder={"https://ashoo.org/profile-91238523126"}
            //   register={{ ...register("tg") }}
            //   clazz={"w-100"}
            clazz={"w-100 link"}
          >
            {t("url")}
          </InputInLabel>
          <Button
            size={"l-forever"}
            buttonType={"submit"}
            clazz={"button--green justify-center mt-16"}
            onClick={handleCloseDelete}
          >
            {t("import")}
          </Button>
        </div>
    </Popup>
  );
};

export default ImportAdPopup;
