import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Button, Icon, InputInLabel } from "@/components/ui";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import {Popup} from "../index";

const options = ["Вася", "Клиент", "Василий", "Начальник"];

const CheckPhonePopup = (props) => {
  const { open, setOpen, phoneClient } = props;

  const { t } = useTranslation("translation");

  const phoneRes = !!formatPhoneNumberIntl(`${phoneClient}`)
    ? formatPhoneNumberIntl(`${phoneClient}`)
    : formatPhoneNumberIntl(`+${phoneClient}`);

  const handleCloseDelete = () => {
    setOpen(false);
  };

  return (
    <Popup
      open={open}
      onClick={handleCloseDelete}
      method={"POST"}
      clazz={'gap-16'}
    >
        <h3 className="m-12-24">
          {t("phoneTegs")} <p>{phoneRes}</p>
        </h3>
        <div className="popup-form__inner">
          
          <div className="popup-form__scroll__list">
            {options.map((item, index) => {
              return (
                <span className="p1 color-main" key={index}>
                  #{item}
                </span>
              );
            })}
          </div>
          <InputInLabel
            id={"tegPhoneClient"}
            title={"#Хороший человек"}
            type={"input"}
            placeholder={"#Хороший человек"}
          >
            {t("addTeg")}
          </InputInLabel>

          <Button
            size={"l-forever"}
            buttonType={"submit"}
            clazz={"button--green justify-center"}
            onClick={handleCloseDelete}
          >
            {t("save")}
          </Button>
        </div>
    </Popup>
  );
};

export default CheckPhonePopup;
