import {useDispatch, useSelector} from "react-redux";
import {
    getForgotPasswordPhoneInputsStatus,
    setForgotPasswordPhoneInputsStatus,
    setNewPassStatus,
    setForgotPasswordPhoneStatus,
} from "@/stores/slices/popupSlice";
import {number, object} from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useEffect, useRef, useState} from "react";
import {setNewToken} from "../../../stores/slices/userSlice";
import {makeRequest} from "@/services/makeRequest";
import "./restorePhoneInputsPopup.scss";
import {Link} from "react-router-dom";
import {t} from "i18next";
import {Popup} from "../index";
import showToast from "../../toast/Toast";
import { CodeInput } from "../../ui/codeInput/CodeInput";

const RestorePhoneInputsPopup = ({telephone}) => {
        const dispatch = useDispatch();
        const showForgotPasswordPhoneInputs = useSelector(
            getForgotPasswordPhoneInputsStatus
        );
        const [codeError, setCodeError] = useState("");
        const formSchema = object().shape({
            phone: number()
                .typeError(t('phonenumerrequired'))
                .required(t('phonenumerrequired')),
        });

        const {
            handleSubmit,
            reset,
            formState: {errors},
        } = useForm({
            mode: "onSubmit",
            resolver: yupResolver(formSchema),
        });

        const {isLoading, setIsLoading} = useState(false)

        const resendCode = async () => {
            try {
                const route = `auth/password/reset`;
                const method = "POST";
                const payload = {
                    identity: parseInt(telephone.replace(/\s/g, '')),
                };

                const {result, message} = await makeRequest({route, method, payload});
                if (!result) {
                    showToast({
                        message: t(message),
                        variant: "error",
                    });
                } else {
                    showToast({
                        message: t('coderesended'),
                        variant: "success",
                    });
                }
                // setIsLoading(false);
            } catch (error) {
                // setIsLoading(false);
                showToast({
                    message: t("oops"),
                    variant: "error",
                });
            }
        };
        const onSubmit = () => {
            const fetchData = async () => {
                try {
                    console.log('otp', otp);
                    
                    const route = `auth/password/verify`;
                    const method = "POST";
                    const payload = {
                        identity: parseInt(telephone.replace(/\s/g, '')),
                        code: otp.join(""),
                    };

                    const data = await makeRequest({route, method, payload});

                    if (data?.result) {
                        dispatch(setNewToken(data.token));
                        dispatch(setForgotPasswordPhoneInputsStatus(false));
                        dispatch(setNewPassStatus(true));
                        reset();
                    } else {
                        setCodeError(t("codeisincorrect"));
                    }
                    // setIsLoading(false);
                } catch (error) {
                    if (error.errors) {
                        setCodeError(t("codeisincorrect"));
                    }
                }
            };

            fetchData();
        };
        const postPhone = t("ifphoneexist")?.replace(
            /%([^%]+)%/g,
            telephone
        );
        const [otp, setOtp] = useState(['']);

        useEffect(() => {
            if(otp.filter(item => item).length === 4) {
                onSubmit()
            }
            if(codeError) {
                setCodeError("")
            }   
        }, [otp])

        return (
            <>
                {!!showForgotPasswordPhoneInputs && (
                    <Popup
                        id={"forgotPasswordPhonePopupInputs"}
                        open={!!showForgotPasswordPhoneInputs}
                        setOpen={() => {
                            dispatch(setForgotPasswordPhoneStatus(false))
                        }}
                        wrapperClazz="forgot-phone-inputs"
                        method={"POST"}
                    >
                        <p className={"popup-form__text"}>
                            {postPhone}{'.'}
                        </p>

                        <CodeInput setValue={setOtp}/>


                        {codeError && (
                            <span className={"popup-form__error text-center mb-8"}>
                    {codeError}
                        </span>
                        )}

                        <Link to={"/"} onClick={() => {
                            resendCode()
                        }} className={"forgot-phone-inputs__link"}>
                            {t('resendcode')}
                        </Link>
                    </Popup>
                )}
            </>
        );
    }
;

export default RestorePhoneInputsPopup;
