import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./stores/store";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Suspense } from "react";
import { Loader } from "./components/ui";
import "react-toastify/dist/ReactToastify.css";
import "./styles/index.scss";
import App from "./components/app/App";
import "./styles/inlineStyles.scss";
import "react-tooltip/dist/react-tooltip.css";
import "react-tippy/dist/tippy.css";
import i18n from './helper/i18n';
import { I18nextProvider } from 'react-i18next';
let rootElement = document.getElementById("root");

const root = createRoot(rootElement);
root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<Loader height={100} />}>
        <BrowserRouter>
          <ToastContainer />
          <App />
        </BrowserRouter>
      </Suspense>
    </I18nextProvider>
  </Provider>
);
