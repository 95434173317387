import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import useLadyService from "@/services/LadyService";
import { Accordion, Icon } from "@/components/ui";

const GuideAside = ({ props }) => {
  const { show, setShow, scrolledClass } = props;

  const { userType, windowWidth, t } = useLadyService();

  let data = {
    indi: [
      {
        first: true,
        title: t("i-maxeffective"),
        links: [
          { link: "maxViews", text: t("i-offermaxviews") },
          { link: "mediaContent", text: t("i-mediacontent") },
          { link: "questionnairePosition", text: t("i-positionpromo") },
          { link: "statusElite", text: `${t("i-markaselite")} Elite` },
          { link: "statuses", text: t("i-statusesobtain") },
          { link: "verify", text: `${t("verification")} ${t("myads")}` },
          { link: "healthControl", text: t("healthy") },
          { link: "specials", text: t("i-otherfeatures") },
        ],
      },
      {
        title: t("i-howearmoney"),
        links: [
          { link: "eroCoins", text: t("i-erocoinandpay") },
          { link: "videochat", text: t("videochat") },
          { link: "onlineOrder", text: t("i-onlineorder") },
          { link: "eroContent", text: t("erocontent") },
        ],
      },
      {
        title: t("i-rulesandinfo"),
        links: [
          { link: "statistic", text: t("i-detailedstat") },
          {
            link: "yourContacts",
            text: `${t("i-yourcontacts")}: Email, WhatsApp, Telegram`,
          },
          { link: "uniqueFeatures", text: t("i-uniquefeatures") },
        ],
      },
    ],
    customer: [
      {
        first: true,
        title: t("g-portaltext"),
        links: [
          { link: "guarantee", text: t("g-guarantee") },
          { link: "sortAndFilter", text: t("g-sortandfilter") },
          { link: "saveFilters", text: t("g-savefilters") },
          { link: "statusElite", text: t("g-searchmodels") },
          { link: "interactions", text: t("g-interactions") },
          { link: "notes", text: t("g-modelsnotes") },
          { link: "feedback", text: t("g-feedback") },
          { link: "complaints", text: t("g-complaints") },
        ],
      },
      {
        title: t("g-uniquefeatures"),
        links: [
          { link: "eroCoins", text: `EroCoins ${t("and")} ${t("i-payonportal")}` },
          { link: "vidWithModel", text: t("videochatswithmodel") },
          { link: "eroContent", text: t("erocontent") },
          { link: "preorders", text: t("g-preorders") },
          { link: "previews", text: t("modelpreviews") },
          { link: "parties", text: t("g-party") },
          { link: "modelStatuses", text: t("g-modelstatuses") },
          { link: "tariffs", text: t("g-usertariffs") },
        ],
      },
      {
        title: t("lk"),
        links: [
          { link: "tariffs", text: t("tarifbenefit") },
          { link: "numbuster", text: t("checknum") },
          { link: "deleteProfile", text: t("deactdelete") },
        ],
      },
    ],
    default: [
      {
        first: true,
        title: t("g-portaltext"),
        links: [
          { link: "guarantee", text: t("g-guarantee") },
          { link: "sortAndFilter", text: t("g-sortandfilter") },
          { link: "saveFilters", text: t("g-savefilters") },
          { link: "statusElite", text: t("g-searchmodels") },
          { link: "interactions", text: t("g-interactions") },
          { link: "notes", text: t("g-modelsnotes") },
          { link: "feedback", text: t("g-feedback") },
          { link: "complaints", text: t("g-complaints") },
        ],
      },
      {
        title: t("g-uniquefeatures"),
        links: [
          { link: "videochat", text: t("videochatswithmodel") },
          { link: "eroContent", text: t("erocontent") },
          { link: "preorders", text: t("g-preorders") },
          { link: "previews", text: t("modelpreviews") },
          { link: "parties", text: t("g-party") },
          { link: "modelStatuses", text: t("g-modelstatuses") },
          { link: "tariffs", text: t("g-usertariffs") },
        ],
      },
    ],
  };

  const [dataContent, setDataContent] = useState(data.indi);

  useEffect(() => {
    switch (userType) {
      case "indi":
        return setDataContent(data.indi);

      case "customer":
        return setDataContent(data.customer);

      default:
        return setDataContent(data.default);
    }
  }, [userType]);

  return (
    <aside className={`p1${show ? " _active" : ""}${scrolledClass}`}>
      {!!show ? (
        <Helmet>
          <html className={"lock"}></html>
        </Helmet>
      ) : null}

      {windowWidth > 767.98 && (
        <span>
          <Icon size={"l"} spritePath={"instruction-cont"} />
          {t("i-contents")}
        </span>
      )}

      {dataContent.map((i) => {
        return (
          <Accordion
            type={"no-border"}
            open={i.first ? i.first : windowWidth > 767.98}
            clazz={"p2"}
            title={i.title}
          >
            {i.links.map((i) => (
              <a onClick={() => setShow(false)} href={`#${i.link}`}>
                {i.text}
              </a>
            ))}
          </Accordion>
        );
      })}
    </aside>
  );
};

export default GuideAside;
