import "./waitingAdmin.scss";

import { Icon, Button } from "@/components/ui";

import useLadyService from "@/services/LadyService";
import { setFastVerStatus } from "@/stores/slices/popupSlice";
import moment from "moment";
import { setRequestVerStatus } from "../../../../../../../stores/slices/popupSlice";

const PhotoUploadSuccess = ({
  moderatedHealthy = false,
  moderatedVerify = false,
  isHealthBoosted,
  type,
  at = "",
  setCurrentType = false,
  healthy_reason = "",
  isCreatePage = false
}) => {
  
  const { t, dispatch } = useLadyService();

  const isTypeAnaliz = type === "analiz";
  const sentenceAnaliz = !isHealthBoosted
    ? t("fastmoderatedinfo")
    : t("fastedmoder") + " ~ 1 " + t('hour');
  const sentencesArray = sentenceAnaliz.split(". ");
  const firstSentence = sentencesArray[0];
  const secondSentence = sentencesArray[1];


  const isPhotoDecline = moderatedHealthy === 2 || moderatedVerify === 2;

  const verified = moment.unix(at || (new Date().getFullYear() + 1) / 1000).utc().format("DD.MM.YYYY");

  const sentence =
    type === "photo"
      ? t("idphoto") + " " + t("uploaded")
      : t("superidphoto") + " " + t("uploaded");

  return (
    <div className="waiting">
      <div className="d-flex gap-8 align-center">
        {!isTypeAnaliz && !isPhotoDecline && (
          <Icon
            title={""}
            spritePath={"check"}
            size={"l"}
            clazz={"color-green"}
          />
        )}
        {isTypeAnaliz && at && at !== null && (
          <Icon
            title={""}
            spritePath={"check"}
            size={"l"}
            clazz={"color-green"}
          />
        )}
        <div className="d-flex gap-4 fd-column">
          {!isTypeAnaliz && !isPhotoDecline && <span className="p1 color-green">{sentence}</span>}
          {!isPhotoDecline && isTypeAnaliz && !at && (
            <span className="p1 color-green">{t("tested")}</span>
          )}
          {isTypeAnaliz && at && at !== null && (
            <span className="p1 color-green">{t("publishedono")}</span>
          )}
          {isPhotoDecline && (
            <span className="p1 color-red-700 text-center">
              {t("declinedbymoderator")}
            </span>
          )}
          {!isTypeAnaliz && !isPhotoDecline && (
            <span className="p1 color-main">
              {t("valid") + " " + t("until") + " " + verified}
            </span>
          )}
          {isTypeAnaliz && at && at !== null && (
          <span className="p1 color-main">
            {t("valid") + " " + t("until") + " " + verified}
          </span>
        )}
        </div>
        
      </div>

      {!isPhotoDecline && isTypeAnaliz && at === null && !isPhotoDecline && (
        <p className="color-main">{firstSentence}</p>
      )}
      {isTypeAnaliz && at === null && !isHealthBoosted && !isPhotoDecline && (
        <p>{secondSentence}</p>
      )}
      {isPhotoDecline && (
        <div className="text-center">
          <p className="color-700">{healthy_reason}</p>
          <span>{t("fixandtry")}</span>
        </div>
      )}
      
      {!isPhotoDecline && isTypeAnaliz && at === null && !isHealthBoosted && (
        <Button
          clazz={`ver__block button--primary`}
          size="s"
          onClick={() => {
            if (setCurrentType) {
              setCurrentType("healthy");
            }
            dispatch(setFastVerStatus(true));
          }}
        >
          {t("fasttrack")}
        </Button>
      )}
      {!isTypeAnaliz && !isPhotoDecline && <p className="text-center">{t("nowyoucan")}</p>}
      {isCreatePage && (
        <Button
        clazz={`button--green`}
        size="s"
        onClick={() => {
          dispatch(setRequestVerStatus(true));
        }}
      >
        {t("request ")}
      </Button>
      )
      }
      {isTypeAnaliz && at && at !== null && (
        <p className="text-center">{t("autohelth")}</p>
      )}
    </div>
  );
};

export default PhotoUploadSuccess;
