import { Helmet } from "react-helmet";
import { Button, Icon } from "@/components/ui";
import useLadyService from "../../../services/LadyService";
import {Popup} from "../index";
import { Link } from "react-router-dom";

const DeletePhotoPopup = (props) => {
  const { open, setOpen, removeImg, type="superId" } = props;

  const { t } = useLadyService();

  const handleCloseDelete = () => {
    setOpen(false);
  };

  const isSuperId = type === "superId"
  const isHealthy = type === "healthy"

  const h3 = isSuperId ? t('delete') + " " + t('idphoto') : t("suredelete")

  return (
    <Popup
      open={open}
      setOpen={handleCloseDelete}
      clazz={'gap-16'}
    >
        <h3 className="m-12-24">{h3}</h3>
        <div className="popup-form__inner text-center">
          {isSuperId && <span className={"color-red-700 fw-500 text-left"}>{t("important")}! </span>}
          {isSuperId && <span className={"color-main text-left fw-500"}>{t("ifremoveidphoto")}{" "}
            <Link className="color-green" to={`/guide#verification`}>{t('verification')}</Link>{" "}
            {t('and')}{" "}
            <Link className="color-green" to={`/guide#healthControl`}>{t('healthy')}</Link>{' '}
            {t('forallverads')}.
          </span>}
          {isHealthy && <span className={"color-green hover-line"}>{t("statnotapproved")}</span>}
        </div>
        <div className="popup-form__buttons">
          <Button
            size={"l-forever"}
            clazz={"button--secondary justify-center"}
            onClick={() => handleCloseDelete()}
          >
            {t("cancel")}
          </Button>

          <Button
            size={"l-forever"}
            buttonType={"button"}
            clazz={"button--error justify-center"}
            onClick={removeImg}
          >
            {t("yesdelete")}
          </Button>
        </div>
    </Popup>
  );
};

export default DeletePhotoPopup;
