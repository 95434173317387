import { Button } from "@/components/ui";

import "./resContainer.scss";
import useLadyService from "../../../../../../services/LadyService";
import moment from "moment";
import { useEffect, useState } from "react";
import { makeRequest } from "../../../../../../services/makeRequest";
import { useTimer } from "react-timer-hook";
import { setBookingAmount } from "../../../../../../stores/slices/userSlice";
import showToast from "../../../../../toast/Toast";
import { Rating, Star } from "@smastrom/react-rating";
import { Controller, useForm } from "react-hook-form";
import { RatingStars } from "../../../../../ui";

const statusObj = {
  0: "workstatus",
  1: "approvestatus",
  2: "declinestatus",
  3: "completestatus",
};

const Variant = ({ status, id, book_at, setData, reviews }) => {
  const { t, dispatch } = useLadyService();

  const approveDate = moment.unix(book_at - 3600).format("DD.MM.YYYY HH:mm");

  const [dateForDeclined, setDateForDeclined] = useState(
    moment.unix(book_at - 3600).format("DD.MM.YYYY HH:mm")
  );

  const [isDate, setIsDate] = useState(
    new Date((book_at - 3600) * 1000) > new Date()
  );

  useEffect(() => {
    if (!isDate && status === 1) {
      const interval = setInterval(() => {
        setIsDate(new Date((book_at - 3600) * 1000) > new Date());
        clearInterval(interval);
      }, 500);

      return () => clearInterval(interval);
    }
  }, [book_at, dateForDeclined, isDate, status]);

  const [isCurrent, setIsCurrent] = useState(
    new Date() > new Date(book_at * 1000) && status === 1
  );

  useEffect(() => {
    if (!isCurrent && status === 1) {
      const interval = setInterval(() => {
        if (new Date() > new Date(book_at * 1000)) {
          setIsCurrent(true);
          clearInterval(interval);
        }
      }, 500);

      return () => clearInterval(interval);
    }
  }, [book_at, status, isCurrent]);

  function getLastReviews(reviews) {
    let lastCustomerReview = null;
    let lastIndiReview = null;

    reviews.forEach((review) => {
      const userType = review.from.user_type;

      if (userType === "customer") {
        if (
          !lastCustomerReview ||
          review.created_at > lastCustomerReview.created_at
        ) {
          lastCustomerReview = review;
        }
      } else if (userType === "indi") {
        if (!lastIndiReview || review.created_at > lastIndiReview.created_at) {
          lastIndiReview = review;
        }
      }
    });

    return { lastCustomerReview, lastIndiReview };
  }

  const { lastIndiReview } = getLastReviews(reviews);

  const [isIndi, setIsIndi] = useState(!!lastIndiReview);
  const [indiRating, setIndiRating] = useState(lastIndiReview?.score || 0);

  const { control, getValues } = useForm({
    mode: "onChange",
  });

  const starsStyles = {
    itemShapes: Star,
  };

  const RatingComponent = (props) => {
    const { name, onChange: onSubmit } = props;
    return (
      <Controller
        control={control}
        name={name}
        rules={{
          validate: (clazz) => clazz > 0,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Rating
            className={"rating-stars rating-stars--gold"}
            value={value}
            isRequired
            spaceInside="none"
            onChange={(value) => {
              onChange(value);
              onSubmit({
                score: value,
                body: getValues("body"),
              });
            }}
            onBlur={onBlur}
            itemStyles={starsStyles}
          />
        )}
      />
    );
  };

  const handleStatus = (status, id) => {
    const fetchData = async () => {
      const method = "PUT";

      try {
        if (status === "confirm") {
          const route = `bookings/${id}/confirm`;
          await makeRequest({ route, method });
          setDateForDeclined(
            moment.unix(book_at - 3600).format("DD.MM.YYYY HH:mm")
          );
          dispatch(
            setBookingAmount((prev) => {
              if (prev - 1 < 0) {
                return 0;
              } else {
                return prev - 1;
              }
            })
          );
          setData((prevData) => {
            return prevData.map((item) => {
              if (item.id === id) {
                return { ...item, status: 1 };
              }
              return item;
            });
          });
        } else if (status === "cancel") {
          const route = `bookings/${id}/cancel`;
          const res = await makeRequest({ route, method });

          if (!!res?.status) {
            dispatch(
              setBookingAmount((prev) => {
                if (prev - 1 < 0) {
                  return 0;
                } else {
                  return prev - 1;
                }
              })
            );
            setData((prevData) => {
              return prevData.map((item) => {
                if (item.id === id) {
                  return { ...item, status: 2 };
                }
                return item;
              });
            });
          } else {
            showToast({
              message: t("oops"),
              variant: "error",
            });
          }
        }
      } catch (error) {}
    };

    fetchData();
  };

  const onSubmit = (data) => {
    const fetchData = async () => {
      try {
        const route = `reviews`;
        const method = "PUT";
        const payload = {
          score: data?.score || 0,
          body: null,
          reviewable_type: "booking",
          reviewable_id: id,
        };

        const { message, review_id } = await makeRequest({
          route,
          method,
          payload,
        });

        if (message === "review_created" && review_id) {
          setIsIndi(true);
          setIndiRating(data?.score);
        } else {
          setIndiRating(0);
          showToast({
            message: t("oops"),
            variant: "error",
          });
        }
      } catch (error) {
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };

    fetchData();
  };

  switch (true) {
    case !isIndi && isCurrent && statusObj[status] === "approvestatus": {
      return (
        <>
          <span className="p1 color-green text-center">
            {t("approvestatus")}
          </span>
          <div className={`res__status`}>
            <span className={"p3"}>{t("yourratingvideo")}</span>
            <RatingComponent name={"score"} onChange={onSubmit} />
          </div>
        </>
      );
    }

    case statusObj[status] === "completestatus": {
      return (
        <>
          <span className="p1 color-green text-center">
            {t("approvestatus")}
          </span>
          <div className={` d-flex fd-column gap-12 justify-center align-center`}>
            <span>{t("yourclientreview")}</span>
            <RatingStars color={'gold'} value={indiRating} />
          </div>
        </>
      );
    }
    //
    // case statusObj[status] === "completestatus" : {
    //   return (
    //     <>
    //       <span className="p1 color-green text-center">{t("orderaccept")}</span>
    //     </>
    //   );
    // }

    case statusObj[status] === "approvestatus" : {
      return (
        <>
          <span className="p1 color-green">{t("orderaccept")}</span>
          <span className="p3 res__status">
            {t("mybecanceled")}
            <span className={"color-main"}>{dateForDeclined}</span>

            <Button
            size={"m"}
            clazz={"button--secondary"}
            onClick={() => handleStatus("cancel", id)}
          >
            {t("cancel")}
          </Button>
          </span>

        </>
      );
    }

    case statusObj[status] === "declinestatus": {
      return (
        <span className="p1 color-red-700">
          {t("declinestatus")}
        </span>
      );
    }

    default: {
      return (
        <>
          <span className={`res__status__text p3`}>
            {t("confirm")} {t("until")}{" "}
            <span className="color-main p2">{approveDate}</span>
          </span>
          <div className={`res__status__actions`}>
            <Button
              size={"m"}
              clazz={"button--green justify-center"}
              onClick={() => handleStatus("confirm", id)}
            >
              {t("confirm")}
            </Button>

            <Button
              size={"m"}
              clazz={"button--secondary justify-center"}
              onClick={() => handleStatus("cancel", id)}
            >
              {t("cancel")}
            </Button>
          </div>
        </>
      );
    }
  }
};

const VariantTimer = ({ id, setData, book_at }) => {
  const { t, dispatch } = useLadyService();

  const expiryTimestamp = new Date(book_at * 1000 - 5400000);

  const newExpiryTimestamp = expiryTimestamp.setSeconds(
    expiryTimestamp.getSeconds() + 1800
  );

  const fetchData = async () => {
    // const route = `bookings/${id}/cancel`;
    // const method = "PUT";

    // await makeRequest({ route, method });
    setData((prevData) => {
      return prevData.map((item) => {
        if (item.id === id) {
          return { ...item, status: 2 };
        }
        return item;
      });
    });
  };

  const { seconds, minutes } = useTimer({
    autoStart: true,
    expiryTimestamp: newExpiryTimestamp,
    onExpire: fetchData,
  });

  const secondTime = seconds < 10 ? `0${seconds}` : `${seconds}`;
  const minuteTime = minutes < 10 ? `0${minutes}` : `${minutes}`;

  const handleApprove = (id) => {
    const fetchData = async () => {
      const method = "PUT";

      try {
        const route = `bookings/${id}/confirm`;
        await makeRequest({ route, method });
        dispatch(setBookingAmount((prev) => (prev - 1 < 0 ? 0 : prev - 1)));
        setData((prevData) => {
          return prevData.map((item) => {
            if (item.id === id) {
              return { ...item, status: 1 };
            }
            return item;
          });
        });
      } catch (error) {}
    };

    fetchData();
  };

  const handleDeclined = (id) => {
    const fetchData = async () => {
      const method = "PUT";

      try {
        const route = `bookings/${id}/cancel`;
        await makeRequest({ route, method });
        dispatch(setBookingAmount((prev) => (prev - 1 < 0 ? 0 : prev - 1)));
        setData((prevData) => {
          return prevData.map((item) => {
            if (item.id === id) {
              return { ...item, status: 2 };
            }
            return item;
          });
        });
      } catch (error) {}
    };

    fetchData();
  };

  return (
    <>
      <span>
        {t("timetoaccept")}{" "}
        <span className="p2 color-main">
          {minuteTime}:{secondTime}
        </span>
      </span>
      <div className={`res__status__actions`}>
        <Button
          size={"m"}
          clazz={"button--green justify-center"}
          onClick={() => handleApprove(id)}
        >
          {t("confirm")}
        </Button>

        <Button
          size={"m"}
          clazz={"button--secondary justify-center"}
          onClick={() => handleDeclined(id)}
        >
          {t("cancel")}
        </Button>
      </div>
    </>
  );
};

const ResStatus = (props) => {
  const { status, book_at } = props;

  function isCurrentTime30MinBefore(targetTimestamp) {
    const targetTime = new Date(targetTimestamp * 1000);
    const currentTime = new Date();
    const threshold = new Date(targetTime.getTime() - 60 * 60 * 1000);

    const timeDifferenceInMilliseconds = threshold - currentTime;

    const timeDifferenceInMinutes = timeDifferenceInMilliseconds / (1000 * 60);

    return timeDifferenceInMinutes <= 30;
  }


  let classVariant;
  switch (statusObj[status]) {
    case "approvestatus": {
      classVariant = " approve";
      break;
    }

    case "completestatus": {
      classVariant = ' done';
      break;
    }

    case "declinestatus": {
      classVariant = " decline";
      break;
    }

    default: {
      classVariant = " work";
    }
  }

  return (
    <div className={`res__status${classVariant}`}>
      {status === 0 ? (
        <VariantTimer {...props} />
      ) : (
        <Variant {...props} />
      )}
    </div>
  );
};

export default ResStatus;
