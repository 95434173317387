import {useEffect, useState} from "react";
import useTitle from "@/hooks/useTitle";
import {useTranslation} from "react-i18next";
import Guide from "../guide/Guide";
import AboutService from "../../aboutService/AboutService";
import BenefitsBlock from "../../benefitsBlock/BenefitsBlock";
import {Tabs} from "../../ui";

import './about.scss'
import AttentionBox from "../guide/components/AttentionBox/AttentionBox";
import useLadyService from "../../../services/LadyService";

const About = () => {
  const {t} = useTranslation("translation");
  const {scrollToTop} = useLadyService()

  useEffect(() => {
    scrollToTop()
  }, []);

  useTitle(t("about"));

  const [activeTab, setActiveTab] = useState(0);

  const tabsData = [
    {title: t("toclients")},
    {title: t("tomodels")},
  ];

  return (
    <main className={'about-page__container'}>
      <h1>{t('g-greetings')} Lady4Love</h1>
      <Tabs
        props={{
          tabsData,
          activeTab,
          setActiveTab,
        }}
      />

        <AboutService
          clazz={`${activeTab === 0 ? 'd-grid' : 'display-none'}`}
        />

        <BenefitsBlock
          showContent
          counters={false}
          clazz={`${activeTab === 1 ? 'd-block' : 'display-none'}`}
        />

      <AttentionBox tag={'div'}>
        <h2>
          {t("portaloperator")}
          <br/>
          <br/>
          IT4L s.r.o
        </h2>

        <p>
          {t("fulladdress")}
          <span className={'p1 color-main '}>Poděbradská 1026/52, Vysočany, 190 00 Praha 9</span>
        </p>

        <p>
          IČO
          <span className={'p1 color-main '}>19470711</span>
        </p>

        <p>
          {t("phonenumber")}
          <a className={'p1 color-main hover-line'} href={'tel:420774231617'}>+420 774231617</a>
        </p>

        <p>
          E-mail
          <a className={'p1 color-main hover-line'} href={'mailto:info@lady4love.com'}>info@lady4love.com</a>
        </p>

        <p>
          IDSN
          <span className={'color-main'}>skvp3cu</span>
        </p>
      </AttentionBox>

    </main>
  );
};

export default About;