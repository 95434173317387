import {
  Button,
  Icon,
  Accordion,
  InputRangeFilter,
  CheckboxFilter,
  Input,
} from "@/components/ui";
import {
  getUserCurrentCity,
  getLanguagesWords,
  getNationalistWords,
  getServicesWords,
} from "@/stores/slices/userSlice";

import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { hide, showFilter } from "@/stores/slices/showFilterSlice";
import {
  filterPlace,
  resetSelected,
  resetState,
  resetRange,
  rangeState,
  setSearchFilter,
  resetAll,
  resetStateAcc,
  resetToggleAcc,
  setAddressValue,
  toggleSelected,
  setRadiusCoords,
  resetRadiusValue,
  resetAddressValue,
  defaultRangeState,
  filterAddress,
} from "@/stores/slices/filterSlice";
import axios from "axios";

import "./filter.scss";
import {
  getBreastSizeCount,
  getBreastTypeCount,
  getEthnosCount,
  getEyeColorCount,
  getEyeTypeCount,
  getGenderCount,
  getHairColorCount,
  getHairIntimCount,
  getHairSizeCount,
  getLanguageCount,
  getLastVisitCount,
  getLipsCount,
  getNationCount,
  getPiercingCount,
  getPlaceCount,
  getRadiusCount,
  getServicesCount,
  getServicesForCount,
  getSexCount,
  getSmoKeCount,
  getStatusCount,
  getTariffsCount,
  getTattooCount,
  resetAllCounters,
  resetRadiusCount,
  resetStateCount,
  setRadiusCoordsCount,
  setResultString,
  getTagCount,
} from "@/stores/slices/counterFilterSlice";
import {
  cleanSorted,
  getNotDisabledCheckbox,
  setSearchValue,
  setSortedModelsByFilter,
} from "@/stores/slices/modelsState";
import { plural } from "../../helper/plural";
import {
  getFilterPopupStatus,
  setFilterMiniPopupStatus,
  setFilterPopupStatus,
} from "@/stores/slices/filterPopupSlice";
import { clearTitle } from "@/stores/slices/titleSlice";
import baseUrl from "@/services/apiConfig";
import {
  getAgeForModels,
  rangeChangeForModel,
} from "@/stores/slices/modelsState";
import useLadyService from "@/services/LadyService";
import { InputInLabel, SelectCountry } from "../ui";
import { getCountriesData } from "@/stores/slices/userSlice";
import { fetchRanges } from "@/stores/slices/filterSlice";
import { getPenisSizeCount } from "@/stores/slices/counterFilterSlice";
import { getTitle } from "@/stores/slices/titleSlice";
import { setCurrentCity } from "@/stores/slices/userSlice";
import GeoInput from "../ui/input/GeoInput";
import { getAllSorted, setSorted } from "@/stores/slices/modelsState";
import { getFilterEditId } from "@/stores/slices/filterSlice";
import { setFilterEditId } from "@/stores/slices/filterSlice";
import { makeRequest } from "@/services/makeRequest";
import showToast from "../toast/Toast";
import { getSortedModelsByFilter } from "@/stores/slices/modelsState";
import {  } from "../../stores/slices/filterSlice";
import {Popup} from "../popups";

const Filter = () => {
  const { dispatch, navigate, lang, userCity, t, country, token, windowWidth } =
    useLadyService();
  const currentCity = useSelector(getUserCurrentCity);

  const servicesCategory = useSelector(getServicesWords);

  const categorySex = servicesCategory.sex;
  const categoryAdditional = servicesCategory.additional;

  const categoryBdsm = servicesCategory.bdsm;
  const categoryExtreme = servicesCategory.extreme;
  const categoryMassage = servicesCategory.massage;
  const categoryStriptease = servicesCategory.striptease;

  const data = {
    place: [
      {
        id: "placeIncall",
        title: t("incall"),
        name: "place",
        value: "incall",
        count: "incall",
      },
      {
        id: "placeOutcall",
        title: t("outcall"),
        name: "place",
        value: "outcall",
        count: "outcall",
      },
      {
        id: "placeSalon",
        title: t("brothel"),
        name: "place",
        value: "salon",
        count: "salon",
      },
    ],

    tag: [
      {
        id: "tagVideochat",
        title: t("videochat"),
        name: "tag",
        value: "vchat",
        count: "vchat",
      },
      {
        id: "tagEro",
        title: t("erocontent"),
        name: "tag",
        value: "ero",
        count: "ero",
      },
      {
        id: "tagBooking",
        title: t("onlineorder"),
        name: "tag",
        value: "booking",
        count: "booking",
      },
    ],
    penis: {
      size: [
        {
          id: "penisSmall",
          title: t("penissmall"),
          name: "penis_size",
          value: "small",
          count: "small",
        },
        {
          id: "penisMedium",
          title: t("penismiddle"),
          name: "penis_size",
          value: "medium",
          count: "medium",
        },
        {
          id: "penisBig",
          title: t("penisbig"),
          name: "penis_size",
          value: "big",
          count: "big",
        },
      ],
    },
    breast: {
      size: [
        {
          id: "breastSmall",
          title: `${t("small")} (0-2)`,
          name: "breast_size",
          value: "breastSmall",
          count: "small",
        },
        {
          id: "breastMedium",
          title: `${t("medium")} (3-4)`,
          name: "breast_size",
          value: "breastMedium",
          count: "medium",
        },
        {
          id: "breastBig",
          title: `${t("big")} (5+)`,
          name: "breast_size",
          value: "breastBig",
          count: "big",
        },
      ],
      type: [
        {
          id: "breastNature",
          title: t("natural"),
          name: "breast_type",
          value: "nat",
          count: "nat",
        },
        {
          id: "breastSil",
          title: t("silicone"),
          name: "breast_type",
          value: "sil",
          count: "sil",
        },
      ],
    },

    ethnos: [
      {
        id: "ethnosWhite",
        title: t("white"),
        name: "ethnos",
        value: "white",
        count: "white",
      },
      {
        id: "ethnosAsian",
        title: t("asian"),
        name: "ethnos",
        value: "asian",
        count: "asian",
      },
      {
        id: "ethnosAfrican",
        title: t("african"),
        name: "ethnos",
        value: "african",
        count: "african",
      },
      {
        id: "ethnosArabic",
        title: t("arabic"),
        name: "ethnos",
        value: "arab",
        count: "arabic",
      },
      {
        id: "ethnosIndian",
        title: t("indian"),
        name: "ethnos",
        value: "indian",
        count: "indian",
      },
      {
        id: "ethnosMulatto",
        title: t("tanned"),
        name: "ethnos",
        value: "mulatto",
        count: "mulatto",
      },
      {
        id: "ethnosMixed",
        title: t("mixed"),
        name: "ethnos",
        value: "mixed",
        count: "mixed",
      },
      {
        id: "ethnosLatin",
        title: t("latin"),
        name: "ethnos",
        value: "latin",
        count: "latin",
      },
    ],

    hair: {
      color: [
        {
          id: "hairBlonde",
          title: t("blond"),
          name: "hair_color",
          value: "blonde",
          count: "blonde",
        },
        {
          id: "hairBrown",
          title: t("brown"),
          name: "hair_color",
          value: "brown",
          count: "brown",
        },
        {
          id: "hairBrunette",
          title: t("brunette"),
          name: "hair_color",
          value: "brunette",
          count: "brunette",
        },
        {
          id: "hairRed",
          title: t("ginger"),
          name: "hair_color",
          value: "red",
          count: "red",
        },
      ],
      size: [
        {
          id: "hairSizeShort",
          title: t("short"),
          name: "hair_size",
          value: "short",
          count: "short",
        },
        {
          id: "hairSizeMedium",
          title: t("mediuma"),
          name: "hair_size",
          value: "medium",
          count: "medium",
        },
        {
          id: "hairSizeLong",
          title: t("long"),
          name: "hair_size",
          value: "long",
          count: "long",
        },
      ],
      intim: [
        {
          id: "hairIntimShaved",
          title: t("shaved"),
          name: "hair_intim",
          value: "shaved",
          count: "shaved",
        },
        {
          id: "hairIntimHaircut",
          title: t("cut"),
          name: "hair_intim",
          value: "haircut",
          count: "haircut",
        },
        {
          id: "hairIntimNature",
          title: t("natural"),
          name: "hair_intim",
          value: "natural",
          count: "natural",
        },
      ],
    },

    eye: {
      color: [
        {
          id: "eyeColorBlue",
          title: t("blue"),
          name: "eye_color",
          value: "blue",
          count: "blue",
        },
        {
          id: "eyeColorGreen",
          title: t("green"),
          name: "eye_color",
          value: "green",
          count: "green",
        },
        {
          id: "eyeColorBrown",
          title: t("brownyies"),
          name: "eye_color",
          value: "browns",
          count: "brown",
        },
        {
          id: "eyeColorGray",
          title: t("gray"),
          name: "eye_color",
          value: "gray",
          count: "gray",
        },
      ],
      type: [
        {
          id: "eyeTypeLenses",
          title: t("lenses"),
          name: "eye_type",
          value: "lenses",
          count: "lens",
        },
        {
          id: "eyeTypeGlasses",
          title: t("glasses"),
          name: "eye_type",
          value: "glasses",
          count: "glass",
        },
      ],
    },

    special: {
      lips: [
        {
          id: "specialLipsNatural",
          title: t("natural"),
          name: "special_lips",
          value: "naturale",
          count: "natural",
        },
        {
          id: "specialLipsPumped",
          title: t("enhanced"),
          name: "special_lips",
          value: "pumped_up",
          count: "pumped_up",
        },
      ],
      tattoo: [
        {
          id: "specialTattooNo",
          title: t("none"),
          name: "special_tattoo",
          value: "no-t",
          count: "no",
        },
        {
          id: "specialTattooFew",
          title: t("few"),
          name: "special_tattoo",
          value: "few-t",
          count: "few",
        },
        {
          id: "specialTattooMany",
          title: t("many"),
          name: "special_tattoo",
          value: "many-t",
          count: "many",
        },
      ],
      piercing: [
        {
          id: "specialPiercingNo",
          title: t("none"),
          name: "special_piercing",
          value: "no-p",
          count: "no",
        },
        {
          id: "specialPiercingFew",
          title: t("few"),
          name: "special_piercing",
          value: "few-p",
          count: "few",
        },
        {
          id: "specialPiercingMany",
          title: t("many"),
          name: "special_piercing",
          value: "many-p",
          count: "many",
        },
      ],
      smoke: [
        {
          id: "specialSmokeNo",
          title: t("none"),
          name: "special_smoke",
          value: "no-s",
          count: "no",
        },
        {
          id: "specialSmokeSometimes",
          title: t("rarely"),
          name: "special_smoke",
          value: "sometimes-s",
          count: "sometimes",
        },
        {
          id: "specialSmokeOften",
          title: t("often"),
          name: "special_smoke",
          value: "regularly-s",
          count: "regularly",
        },
      ],
    },

    status: [
      {
        id: "statusIndividual",
        title: t("indi"),
        name: "status",
        value: "indi",
        count: "indi",
      },
      {
        id: "statusVerification",
        title: t("verification"),
        name: "status",
        value: "verified",
        count: "verified",
      },
      {
        id: "statusCouples",
        forDisabled: "genderCouple",
        title: t("couples"),
        name: "gender",
        value: "couple",
        count: "couple",
      },
      {
        id: "statusElite",
        title: t("elite"),
        name: "status",
        value: "elitelady",
        count: "elitelady",
      },
      {
        id: "statusHealthControl",
        title: t("healthy"),
        name: "status",
        value: "healthy",
        count: "healthy",
      },
      {
        id: "statusBDSM",
        title: t("bdsm"),
        name: "status",
        value: "bdsm",
        count: "bdsm",
      },
      {
        id: "statusPornstar",
        title: t("star"),
        name: "status",
        value: "pornstar",
        count: "pornstar",
      },
      {
        id: "statusFriends",
        title: t("girlfriend"),
        name: "status",
        value: "gf",
        count: "gf",
      },
      {
        id: "statusTrance",
        forDisabled: "genderTrans",
        title: t("trans"),
        name: "gender",
        value: "trans",
        count: "trans",
      },
      {
        id: "statusOnline",
        title: t("online"),
        name: "status",
        value: "online",
        count: "online",
      },
      {
        id: "statusEscort",
        title: t("accompaniment"),
        name: "status",
        value: "escort",
        count: "escort",
      },
      {
        id: "statusGuys",
        forDisabled: "genderMale",
        title: t("guys"),
        name: "gender",
        value: "male",
        count: "male",
      },
      {
        id: "statusNew",
        title: t("newmodels"),
        name: "status",
        value: "new",
        count: "new",
      },
      {
        id: "statusTravel",
        title: t("travel"),
        name: "status",
        value: "travel",
        count: "travel",
      },
      {
        id: "statusVideo",
        title: t("video"),
        name: "status",
        value: "video",
        count: "video",
      },
    ],

    gender: [
      {
        id: "genderFemale",
        title: t("woman"),
        name: "gender",
        value: "female",
        count: "female",
      },
      {
        id: "genderMale",
        title: t("guys"),
        name: "gender",
        value: "male",
        count: "male",
      },
      {
        id: "genderTrans",
        title: t("trans"),
        name: "gender",
        value: "trans",
        count: "trans",
      },
      {
        id: "genderCouple",
        title: t("couples"),
        name: "gender",
        value: "couple",
        count: "couple",
      },
    ],

    sex: [
      {
        id: "sexStraight",
        title: t("hetero"),
        name: "sex",
        value: "straight",
        button_checkboxes: true,
        count: "straight",
      },
      {
        id: "sexHomosexual",
        title: t("homo"),
        name: "sex",
        value: "homosexual",
        button_checkboxes: true,
        count: "homosexual",
      },
      {
        id: "sexLesbian",
        title: t("lesbi"),
        name: "sex",
        value: "lesbian",
        button_checkboxes: true,
        count: "lesbian",
      },
      {
        id: "sexBisexual",
        title: t("bi"),
        name: "sex",
        value: "bisexual",
        button_checkboxes: true,
        count: "bisexual",
      },
    ],

    services: {
      for: [
        {
          id: "forMen",
          title: t("men"),
          name: "for",
          value: "forMan",
          count: "men",
        },
        {
          id: "forWomen",
          title: t("women"),
          name: "for",
          value: "forWomen",
          count: "women",
        },
        {
          id: "forCouples",
          title: t("couples"),
          name: "for",
          value: "forCouples",
          count: "couples",
        },
        {
          id: "forGroups",
          title: t("groups"),
          name: "for",
          value: "forGroups",
          count: "groups",
        },
      ],

      for_mobile: [
        {
          id: "forMen",
          title: t("men"),
          name: "for",
          value: "forMan",
          count: "men",
        },
        {
          id: "forCouples",
          title: t("couples"),
          name: "for",
          value: "forCouples",
          count: "couples",
        },
        {
          id: "forWomen",
          title: t("women"),
          name: "for",
          value: "forWomen",
          count: "women",
        },
        // {
        //   id: "servicesFor1",
        //   title: "ММЖ",
        //   name: "for",
        //   value: "servicesFor2",
        // },
        {
          id: "forGroups",
          title: t("groups"),
          name: "for",
          value: "forGroups",
          count: "groups",
        },
      ],

      sex: [
        {
          id: "servicesSexClassic",
          title: categorySex?.services[1].slug,
          name: "services",
          value: "1",
          count: "1",
        },
        {
          id: "servicesSexBlowjobDeep",
          title: categorySex?.services[8].slug,
          name: "services",
          value: "8",
          count: "8",
        },
        {
          id: "servicesSexAnal",
          title: categorySex?.services[2].slug,
          name: "services",
          value: "2",
          count: "2",
        },
        {
          id: "servicesSexLickingBalls",
          title: categorySex?.services[9].slug,
          name: "services",
          value: "9",
          count: "9",
        },
        {
          id: "servicesSexSixtyNine",
          title: categorySex?.services[3].slug,
          name: "services",
          value: "3",
          count: "3",
        },
        {
          id: "servicesSexCunnilingus",
          title: categorySex?.services[10].slug,
          name: "services",
          value: "10",
          count: "10",
        },
        {
          id: "servicesSexKissing",
          title: categorySex?.services[4].slug,
          name: "services",
          value: "4",
          count: "4",
        },
        {
          id: "servicesSexToys",
          title: categorySex?.services[11].slug,
          name: "services",
          value: "11",
          count: "11",
        },
        {
          id: "servicesSexMasturbation",
          title: categorySex?.services[5].slug,
          name: "services",
          value: "5",
          count: "5",
        },
        {
          id: "servicesSexFinishOnBreast",
          title: categorySex?.services[12].slug,
          name: "services",
          value: "12",
          count: "12",
        },
        {
          id: "servicesSexBlowjobInCondom",
          title: categorySex?.services[6].slug,
          name: "services",
          value: "6",
          count: "6",
        },
        {
          id: "servicesSexFinishOnFace",
          title: categorySex?.services[13].slug,
          name: "services",
          value: "13",
          count: "13",
        },
        {
          id: "servicesSexBlowjob",
          title: categorySex?.services[7].slug,
          name: "services",
          value: "7",
          count: "7",
        },
        {
          id: "servicesSexFinishInMouth",
          title: categorySex?.services[14].slug,
          name: "services",
          value: "14",
          count: "14",
        },
      ],

      sex_mobile: [
        {
          id: "servicesSexClassic",
          title: categorySex?.services[1].slug,
          name: "services",
          value: "1",
        },
        {
          id: "servicesSexAnal",
          title: categorySex?.services[2].slug,
          name: "services",
          value: "2",
        },
        {
          id: "servicesSexBlowjob",
          title: categorySex?.services[6].slug,
          name: "services",
          value: "blowjob",
        },
        {
          id: "servicesSexBlowjobDeep",
          title: categorySex?.services[8].slug,
          name: "services",
          value: "blowjob_deep",
        },
        {
          id: "servicesSexToys",
          title: categorySex?.services[11].slug,
          name: "services",
          value: "sex_toys",
        },
        {
          id: "servicesSexFinishOnBreast",
          title: categorySex?.services[12].slug,
          name: "services",
          value: "finish_on_breast",
        },
        {
          id: "servicesSexFinishOnFace",
          title: categorySex?.services[13].slug,
          name: "services",
          value: "finish_on_face",
        },
        {
          id: "servicesSexFinishInMouth",
          title: categorySex?.services[14].slug,
          name: "services",
          value: "finish_in_mouth",
        },
        {
          id: "servicesSexBlowjobInCondom",
          title: categorySex?.services[6].slug,
          name: "services",
          value: "blowjob_in_condom",
        },
        {
          id: "servicesSexCunnilingus",
          title: categorySex?.services[10].slug,
          name: "services",
          value: "cunnilingus",
        },
        {
          id: "servicesSexKissing",
          title: categorySex?.services[4].slug,
          name: "services",
          value: "kissing",
        },
        {
          id: "servicesSexSixtyNine",
          title: categorySex?.services[3].slug,
          name: "services",
          value: "sixty_nine",
        },
      ],

      strip: [
        {
          id: "servicesStrip1",
          title: categoryStriptease?.services[15].slug,
          name: "services",
          value: "15",
          count: "15",
        },
        {
          id: "servicesStrip2",
          title: categoryStriptease?.services[17].slug,
          name: "services",
          value: "17",
          count: "17",
        },
        {
          id: "servicesStrip3",
          title: categoryStriptease?.services[16].slug,
          name: "services",
          value: "16",
          count: "16",
        },
        {
          id: "servicesStrip4",
          title: categoryStriptease?.services[18].slug,
          name: "services",
          value: "strip4",
          count: "18",
        },
      ],

      massage: [
        {
          id: "servicesMassage1",
          title: categoryMassage?.services[19].slug,
          name: "services",
          value: "19",
          count: "19",
        },
        {
          id: "servicesMassage2",
          title: categoryMassage?.services[23].slug,
          name: "services",
          value: "23",
          count: "23",
        },
        {
          id: "servicesMassage3",
          title: categoryMassage?.services[20].slug,
          name: "services",
          value: "20",
          count: "20",
        },
        {
          id: "servicesMassage4",
          title: categoryMassage?.services[24].slug,
          name: "services",
          value: "24",
          count: "24",
        },
        {
          id: "servicesMassage5",
          title: categoryMassage?.services[21].slug,
          name: "services",
          value: "21",
          count: "21",
        },
        {
          id: "servicesMassage6",
          title: categoryMassage?.services[25].slug,
          name: "services",
          value: "25",
          count: "25",
        },
        {
          id: "servicesMassage7",
          title: categoryMassage?.services[22].slug,
          name: "services",
          value: "22",
          count: "22",
        },
        {
          id: "servicesMassage8",
          title: categoryMassage?.services[27].slug,
          name: "services",
          value: "27",
          count: "27",
        },
        {
          id: "servicesMassage9",
          title: categoryMassage?.services[26].slug,
          name: "services",
          value: "26",
          count: "26",
        },
      ],

      bdsm: [
        {
          id: "servicesBdsm1",
          title: categoryBdsm?.services[28].slug,
          name: "services",
          value: "28",
          count: "28",
        },
        {
          id: "servicesBdsm3",
          title: categoryBdsm?.services[29].slug,
          name: "services",
          value: "29",
          count: "29",
        },
        {
          id: "servicesBdsm5",
          title: categoryBdsm?.services[30].slug,
          name: "services",
          value: "30",
          count: "30",
        },
        // {
        //   id: "servicesBdsm7",
        //   title: categoryBdsm?.services[31].slug,
        //   name: "services",
        //   value: "31",
        //   count: "31",
        // },
        {
          id: "servicesBdsm9",
          title: categoryBdsm?.services[32].slug,
          name: "services",
          value: "32",
          count: "32",
        },
        {
          id: "servicesBdsm11",
          title: categoryBdsm?.services[34].slug,
          name: "services",
          value: "34",
          count: "34",
        },
        {
          id: "servicesBdsm13",
          title: categoryBdsm?.services[35].slug,
          name: "services",
          value: "35",
          count: "35",
        },
        {
          id: "servicesBdsm18",
          title: categoryBdsm?.services[33].slug,
          name: "services",
          value: "33",
          count: "33",
        },
      ],

      extrim: [
        {
          id: "servicesBdsm2",
          title: categoryExtreme?.services[39].slug,
          name: "services",
          value: "39",
          count: "39",
        },
        {
          id: "servicesBdsm4",
          title: categoryExtreme?.services[40].slug,
          name: "services",
          value: "40",
          count: "40",
        },
        {
          id: "servicesBdsm6",
          title: categoryExtreme?.services[41].slug,
          name: "services",
          value: "41",
          count: "41",
        },
        {
          id: "servicesBdsm8",
          title: categoryExtreme?.services[42].slug,
          name: "services",
          value: "42",
          count: "42",
        },
        {
          id: "servicesBdsm10",
          title: categoryExtreme?.services[43].slug,
          name: "services",
          value: "43",
          count: "43",
        },
        // {
        //   id: "servicesBdsm12",
        //   title: categoryExtreme?.services[44].slug,
        //   name: "services",
        //   value: "44",
        //   count: "44",
        // },
        {
          id: "servicesBdsm14",
          title: categoryExtreme?.services[45].slug,
          name: "services",
          value: "45",
          count: "45",
        },
        {
          id: "servicesBdsm16",
          title: categoryExtreme?.services[46].slug,
          name: "services",
          value: "46",
          count: "46",
        },
        {
          id: "servicesBdsm17",
          title: categoryExtreme?.services[37].slug,
          name: "services",
          value: "37",
          count: "37",
        },
        {
          id: "servicesBdsm19",
          title: categoryExtreme?.services[38].slug,
          name: "services",
          value: "38",
          count: "38",
        },
        {
          id: "servicesBdsm20",
          title: categoryExtreme?.services[47].slug,
          name: "services",
          value: "47",
          count: "47",
        },
      ],

      extra: [
        {
          id: "servicesExtra1",
          title: categoryAdditional?.services[48].slug,
          name: "services",
          value: "48",
          count: "48",
        },
        {
          id: "servicesExtra2",
          title: categoryAdditional?.services[49].slug,
          name: "services",
          value: "49",
          count: "49",
        },
        {
          id: "servicesExtra3",
          title: categoryAdditional?.services[50].slug,
          name: "services",
          value: "50",
          count: "50",
        },
        {
          id: "servicesExtra4",
          title: categoryAdditional?.services[51].slug,
          name: "services",
          value: "51",
          count: "51",
        },
        {
          id: "servicesExtra5",
          title: categoryAdditional?.services[52].slug,
          name: "services",
          value: "52",
          count: "52",
        },
        {
          id: "servicesExtra6",
          title: categoryAdditional?.services[53].slug,
          name: "services",
          value: "53",
          count: "53",
        },
        {
          id: "servicesExtra7",
          title: categoryAdditional?.services[54].slug,
          name: "services",
          value: "54",
          count: "54",
        },
        {
          id: "servicesExtra8",
          title: categoryAdditional?.services[55].slug,
          name: "services",
          value: "55",
          count: "55",
        },
        {
          id: "servicesExtra9",
          title: categoryAdditional?.services[56].slug,
          name: "services",
          value: "56",
          count: "56",
        },
        {
          id: "servicesExtra10",
          title: categoryAdditional?.services[57].slug,
          name: "services",
          value: "57",
          count: "57",
        },
        {
          id: "servicesExtra11",
          title: categoryAdditional?.services[58].slug,
          name: "services",
          value: "58",
          count: "58",
        },
      ],
    },

    min_tariffs: [
      {
        id: "minTarifs1",
        title: `15 ${t("min")}`,
        name: "min_tariffs",
        value: "min-15",
        count: "15",
        button_checkboxes: true,
      },
      {
        id: "minTarifs2",
        title: `30 ${t("min")}`,
        name: "min_tariffs",
        value: "min-30",
        count: "30",
        button_checkboxes: true,
      },
      {
        id: "minTarifs3",
        title: t("hour"),
        name: "min_tariffs",
        value: "min-60",
        count: "60",
        button_checkboxes: true,
      },
    ],

    last_visit: [
      {
        id: "lastVisitToday",
        title: t("today"),
        name: "last_visit",
        value: "visitToday",
        count: "today",
        radio: true,
      },
      {
        id: "lastVisitYesterday",
        title: t("yesterday"),
        name: "last_visit",
        value: "visitSubDay",
        count: "subDay",
        radio: true,
      },
      {
        id: "lastVisitOnWeek",
        title: t("thisweek"),
        name: "last_visit",
        value: "visitSubWeek",
        count: "subWeek",
        radio: true,
      },
      {
        id: "lastVisitInMonth",
        title: t("thismonth"),
        name: "last_visit",
        value: "visitSubMonth",
        count: "subMonth",
        radio: true,
      },
    ],

    radius: [
      {
        id: "radius1",
        title: `1 ${t("km")}`,
        name: "radius",
        value: "1",
        count: "1",
        radio: true,
      },
      {
        id: "radius3",
        title: `3 ${t("km")}`,
        name: "radius",
        value: "3",
        count: "3",
        radio: true,
      },
      {
        id: "radius5",
        title: `5 ${t("km")}`,
        name: "radius",
        value: "5",
        count: "5",
        radio: true,
      },
      {
        id: "radius10",
        title: `10 ${t("km")}`,
        name: "radius",
        value: "10",
        count: "10",
        radio: true,
      },
      {
        id: "radius20Plus",
        title: `20+ ${t("km")}`,
        name: "radius",
        value: "20",
        count: "20",
        radio: true,
      },
    ],
  };

  const filterValue = useSelector(showFilter);
  const address = useSelector(filterAddress);
  useEffect(() => {
    document.addEventListener("keyup", (event) => {
      if (event.isComposing || event.key === "Escape") {
        dispatch(hide());
        if (isFilterEdit) {
          dispatch(setFilterEditId(false));
        }
      }
    });
  }, []);

  const isFilterEdit = useSelector(getFilterEditId);
  const EditFilterWrapper = ({ children }) => {
    if (isFilterEdit) {
      return <div className="d-flex gap-16 w-100">{children}</div>;
    } else {
      return children;
    }
  };
  const [editFilterName, setEditFilterName] = useState(
    isFilterEdit ? isFilterEdit.title : ""
  );
  const filterParams = useSelector(getSortedModelsByFilter);

  const [editFilterError, setEditFilterError] = useState(false);
  const handleEditFilter = async (id) => {
    try {
      const route = `user/filter/${id}`;
      const method = "PUT";
      const payload = {
        title: editFilterName,
        filter: filterParams
      }

      const result = await makeRequest({ route, method, payload });
      if (result) {
        showToast({
          message: t("success"),
          variant: "success",
        });
      }
    } catch (error) {
      console.log(error);

      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };
  const [searchCount, setSearchCount] = useState("");

  const popupRef = useRef(null);
  const childrenRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      popupRef.current &&
      popupRef.current.contains(event.target) &&
      childrenRef.current &&
      !childrenRef.current.contains(event.target)
    ) {
      dispatch(hide());
      if (isFilterEdit) {
        dispatch(setFilterEditId(false));
      }
      event.stopPropagation();
    }
  };

  const defaultRangeValue = useSelector(defaultRangeState);
  const [isActive, setIsActive] = useState(false);

  const { languages, nationalities } = useSelector(getNotDisabledCheckbox);
  const translateLanguages = useSelector(getLanguagesWords);
  const translateNationalist = useSelector(getNationalistWords);

  const nationData =
    translateNationalist?.length > 0 &&
    nationalities?.length > 0 &&
    translateNationalist.filter((item2) =>
      nationalities.some((item1) => item1 === item2.code)
    );
  const langData =
    translateLanguages?.length > 0 &&
    languages?.length > 0 &&
    translateLanguages.filter((item2) =>
      languages.some((item1) => item1 === item2.short_name)
    );
  const handleLocationClick = () => {
    setIsActive(true);
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(async function (position) {
        const { latitude, longitude } = position.coords;

        try {
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1&accept-language=${lang}`
          );

          const { city, road, house_number } = response.data.address;

          let uncorrectName = false;

          country.map((item) => {
            if (
              item.title?.toLowerCase() !== city.toLowerCase() &&
              !uncorrectName
            ) {
              uncorrectName = true;
              console.log("check");
              dispatch(
                setCurrentCity(country.filter((item) => item.id === 1)[0])
              );
              return null;
            }
          });
          dispatch(
            setAddressValue(
              `${road} ${house_number ? ", " + house_number : ""}`
            )
          );
          dispatch(
            toggleSelected({
              value: `${house_number ? ", " + house_number : ""}`,
              name: "radius",
            })
          );
          dispatch(setRadiusCoords({ latitude, longitude }));
          dispatch(setRadiusCoordsCount({ latitude, longitude }));
        } catch (error) {
          console.error("Error fetching address:", error);
        }
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filterPlaceSlice = useSelector(filterPlace);
  const isStatusFullPopup = useSelector(getFilterPopupStatus);

  function concatArray(arr) {
    if (arr.length === 0) {
      return "";
    }
    return arr.map((item) => item + "=1").join("&");
  }

  function concatenateArrayWithTitle(arr, title) {
    const concatenatedString = title + "=" + arr.join(",");
    return [concatenatedString];
  }
  const [bounds, setBounds] = useState("");

  function formatRange(obj, title) {
    const { min, max } = obj;
    return `${title}=${min},${max}`;
  }

  const [filterdSting, setFiltredString] = useState([]);

  const placeCount = useSelector(getPlaceCount);
  const languageCount = useSelector(getLanguageCount);
  const genderCount = useSelector(getGenderCount);
  const sexCount = useSelector(getSexCount);
  const nationCount = useSelector(getNationCount);
  const lastVisitCount = useSelector(getLastVisitCount);
  const minTarifCount = useSelector(getTariffsCount);
  const servicesForCount = useSelector(getServicesForCount);
  const breastSizeCount = useSelector(getBreastSizeCount);
  const breastTypeCount = useSelector(getBreastTypeCount);
  const ethnosCount = useSelector(getEthnosCount);
  const hairSizeCount = useSelector(getHairSizeCount);
  const hairColorCount = useSelector(getHairColorCount);
  const hairIntimCount = useSelector(getHairIntimCount);
  const eyesColorCount = useSelector(getEyeColorCount);
  const eyesTypeCount = useSelector(getEyeTypeCount);
  const lipsCount = useSelector(getLipsCount);
  const tattooCount = useSelector(getTattooCount);
  const piercingCount = useSelector(getPiercingCount);
  const smokeCount = useSelector(getSmoKeCount);
  const statusCount = useSelector(getStatusCount);
  const rangeValue = useSelector(rangeState);
  const servicesValue = useSelector(getServicesCount);
  const radiusState = useSelector(getRadiusCount);
  const tagState = useSelector(getTagCount);
  const penisSize = useSelector(getPenisSizeCount);

  useEffect(() => {
    if (filterValue && !isFilterEdit) {
      let result = [];
      let resultForModels = [];
      if (
        (rangeValue.age.min !== 0 &&
          rangeValue.age.min > defaultRangeValue.age.min) ||
        (rangeValue.age.max !== 0 &&
          rangeValue.age.max < defaultRangeValue.age.max)
      ) {
        result.push(formatRange(rangeValue.age, "age"));
        resultForModels.push(formatRange(rangeValue.weight, "weight"));
      }
      if (
        rangeValue.weight.min > defaultRangeValue.weight.min ||
        rangeValue.weight.max < defaultRangeValue.weight.max
      ) {
        result.push(formatRange(rangeValue.weight, "weight"));
        resultForModels.push(formatRange(rangeValue.weight, "weight"));
      }
      if (
        rangeValue.height.min > defaultRangeValue.height.min ||
        rangeValue.height.max < defaultRangeValue.height.max
      ) {
        result.push(formatRange(rangeValue.height, "height"));
        resultForModels.push(formatRange(rangeValue.height, "height"));
      }
      if (
        rangeValue.price.min > defaultRangeValue.price.min ||
        rangeValue.price.max < defaultRangeValue.price.max
      ) {
        result.push(formatRange(rangeValue.price, "price"));
        resultForModels.push(formatRange(rangeValue.price, "price"));
      }
      if (!!statusCount.length) {
        result.push(concatArray(statusCount));
      }
      if (!!penisSize.length) {
        result.push(concatenateArrayWithTitle(penisSize, "penis"));
        resultForModels.push(concatenateArrayWithTitle(penisSize, "penis"));
      }
      if (!!breastTypeCount.length) {
        result.push(concatArray(breastTypeCount));
        resultForModels.push(concatArray(breastTypeCount));
      }
      if (!!eyesTypeCount.length) {
        result.push(concatArray(eyesTypeCount));
        resultForModels.push(concatArray(eyesTypeCount));
      }
      if (!!radiusState.value.length) {
        result.push(
          `radius=${radiusState.latitude},${
            radiusState.longitude
          },${radiusState.value.join(",")}`
        );
        resultForModels.push([
          `radius=${radiusState.latitude},${
            radiusState.longitude
          },${radiusState.value.join(",")}`,
        ]);
      }
      if (placeCount.filter((item) => item !== "incall").length > 0) {
        result.push(
          concatenateArrayWithTitle(
            placeCount.filter((item) => item !== "incall"),
            "place"
          )
        );
      }
      if (servicesValue.length > 0) {
        result.push(concatenateArrayWithTitle(servicesValue, "services"));
        resultForModels.push(
          concatenateArrayWithTitle(servicesValue, "services")
        );
      }
      if (smokeCount.length > 0) {
        result.push(concatenateArrayWithTitle(smokeCount, "smoking"));
        resultForModels.push(concatenateArrayWithTitle(smokeCount, "smoking"));
      }
      if (piercingCount.length > 0) {
        result.push(concatenateArrayWithTitle(piercingCount, "piercing"));
        resultForModels.push(
          concatenateArrayWithTitle(piercingCount, "piercing")
        );
      }
      if (tattooCount.length > 0) {
        result.push(concatenateArrayWithTitle(tattooCount, "tattoo"));
        resultForModels.push(concatenateArrayWithTitle(tattooCount, "tattoo"));
      }
      if (lipsCount.length > 0) {
        result.push(concatenateArrayWithTitle(lipsCount, "lips"));
        resultForModels.push(concatenateArrayWithTitle(lipsCount, "lips"));
      }
      if (eyesColorCount.length > 0) {
        result.push(concatenateArrayWithTitle(eyesColorCount, "eyes"));
        resultForModels.push(concatenateArrayWithTitle(eyesColorCount, "eyes"));
      }
      if (hairIntimCount.length > 0) {
        result.push(concatenateArrayWithTitle(hairIntimCount, "intim_hs"));
        resultForModels.push(
          concatenateArrayWithTitle(hairIntimCount, "intim_hs")
        );
      }
      if (hairSizeCount.length > 0) {
        result.push(concatenateArrayWithTitle(hairSizeCount, "hair_len"));
        resultForModels.push(
          concatenateArrayWithTitle(hairSizeCount, "hair_len")
        );
      }
      if (hairColorCount.length > 0) {
        result.push(concatenateArrayWithTitle(hairColorCount, "hair"));
        resultForModels.push(concatenateArrayWithTitle(hairColorCount, "hair"));
      }
      if (breastSizeCount.length > 0) {
        result.push(concatenateArrayWithTitle(breastSizeCount, "breast"));
        resultForModels.push(
          concatenateArrayWithTitle(breastSizeCount, "breast")
        );
      }
      if (ethnosCount.length > 0) {
        result.push(concatenateArrayWithTitle(ethnosCount, "ethnos"));
        resultForModels.push(concatenateArrayWithTitle(ethnosCount, "ethnos"));
      }
      if (genderCount.length > 0) {
        result.push(concatenateArrayWithTitle(genderCount, "gender"));
        // resultForModels.push(concatenateArrayWithTitle(genderCount, "gender"));
      }
      if (sexCount.length > 0) {
        result.push(concatenateArrayWithTitle(sexCount, "orient"));
        resultForModels.push(concatenateArrayWithTitle(sexCount, "orient"));
      }
      if (nationCount.length > 0) {
        result.push(concatenateArrayWithTitle(nationCount, "nationality"));
        resultForModels.push(
          concatenateArrayWithTitle(nationCount, "nationality")
        );
      }
      if (lastVisitCount.length > 0) {
        result.push(concatenateArrayWithTitle(lastVisitCount, "last_visit"));
        resultForModels.push(
          concatenateArrayWithTitle(lastVisitCount, "last_visit")
        );
      }
      if (minTarifCount.length > 0) {
        result.push(concatenateArrayWithTitle(minTarifCount, "min_time"));
        resultForModels.push(
          concatenateArrayWithTitle(minTarifCount, "min_time")
        );
      }
      if (servicesForCount.length > 0) {
        result.push(concatenateArrayWithTitle(servicesForCount, "for"));
        resultForModels.push(
          concatenateArrayWithTitle(servicesForCount, "for")
        );
      }
      if (languageCount.length > 0) {
        result.push(concatenateArrayWithTitle(languageCount, "languages"));
        resultForModels.push(
          concatenateArrayWithTitle(languageCount, "languages")
        );
      }
      setFiltredString(resultForModels.join("&"));
      const resultUrl = `${baseUrl}profiles/count?city=${userCity.id}`;
      const params = result.length ? `&${result.join("&")}` : "";
      axios
        .request({
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
          method: "GET",
          url: resultUrl + params,
        })
        .then((response) => {
          setSearchCount(response.data.count);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    penisSize,
    tagState,
    userCity,
    radiusState.value,
    servicesValue,
    rangeValue,
    filterValue,
    statusCount,
    smokeCount,
    piercingCount,
    tattooCount,
    lipsCount,
    eyesTypeCount,
    eyesColorCount,
    hairIntimCount,
    hairColorCount,
    ethnosCount,
    hairSizeCount,
    breastTypeCount,
    breastSizeCount,
    servicesForCount,
    placeCount,
    languageCount,
    genderCount,
    sexCount,
    nationCount,
    lastVisitCount,
    minTarifCount,
  ]);
  const sortedValue = useSelector(getAllSorted);

  const [placeTitleSelect, setPlaceTitleSelect] = useState(false);
  useEffect(() => {
    const boolean =
      sortedValue.includes("incall") || filterPlaceSlice.length > 0;
    setPlaceTitleSelect(boolean);
  }, [filterPlaceSlice, statusCount]);

  const countriesData = useSelector(getCountriesData);
  const [currentActive, setCurrentActive] = useState("");

  const urlParams = useSelector(getTitle)
    .map((word) => {
      switch (word) {
        case "videochat":
          return "vchat";
        case "erocontent":
          return "ero";
        case "onlineorder":
          return "booking";
        case "verification":
          return "verified";
        case "elite":
          return "elitelady";
        case "trans":
          return "trance";
        case "couples":
          return "couple";
        default:
          return word;
      }
    })
    .join("-");

  useEffect(() => {
    if (filterValue) {
      dispatch(fetchRanges(userCity.id));
    }
  }, [filterValue]);

  useEffect(() => {
    if(isFilterEdit) {
      console.log('filterParams', filterParams);
    }
  }, [])

  const handleClose = () => {
    dispatch(hide());
    if (isFilterEdit) {
      dispatch(setFilterEditId(false));
    }
  }

  return (
    <Popup
      ref={popupRef}
      open={filterValue}
      setOpen={handleClose}
      id={"filter"}
      wrapperClazz={"filter"}
      clazz={'filter__body'}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >

      {!!countriesData?.length &&
        <div className="filter__top">
          <SelectCountry
            data={countriesData}
            name={"city-create-filter"}
            clazz={`button button-xs select-city`}
            arrowSize={"m"}
            type={"flag"}
            defaultTitle={currentCity.title}
            currentActiveIndex={currentActive}
            isMain={true}
            activeCountry={currentCity.icon}
            setActive={setCurrentActive}
          />
        </div>
      }

        <div className="filter__box filter--place">
          <span className={"filter__title"}>
            {t("place")}
            {placeTitleSelect && <span className={"filter__selected"}>!</span>}
            <button
              type="button"
              className={"range__default"}
              onClick={() => {
                dispatch(resetState("place"));
                dispatch(resetStateCount("place"));
                if (sortedValue.includes("incall")) {
                  dispatch(
                    setSorted({
                      stateName: "sorted",
                      payload: "incall",
                    })
                  );
                }
              }}
            >
              {placeTitleSelect && t("reset")}
            </button>
          </span>
          <div className="filter__checkbox-buttons">
            {data.place.map((i) => {
              return (
                <CheckboxFilter
                  key={i.id}
                  name={i.name}
                  id={i.id}
                  value={i.value}
                  title={i.title}
                  checked={i.checked}
                  buttonCheckbox={true}
                  count={i.count}
                />
              );
            })}
          </div>
        </div>

        <div className="filter__box filter--price">
          <InputRangeFilter
            min={defaultRangeValue.price.min}
            max={defaultRangeValue.price.max}
            step={10}
            clazz={"range--price"}
            inputIdLeft={"minPriceFilter"}
            inputIdRight={"maxPriceFilter"}
            title={`${t("price")},`}
            name={"price"}
            subtitle={` €/${t("hour")}`}
            price
          />

          <InputRangeFilter
            min={defaultRangeValue.age.min}
            max={defaultRangeValue.age.max}
            step={1}
            clazz={"range--age"}
            inputIdLeft={"minAgeFilter"}
            inputIdRight={"maxAgeFilter"}
            title={`${t("age")},`}
            name={"age"}
            subtitle={` ${t("years")}`}
          />
        </div>

        <Accordion
          name={"gender"}
          title={t("gender")}
          onClick={() => {
            dispatch(resetState("gender"));
            dispatch(resetSelected("gender"));
            dispatch(resetStateCount("gender"));
            dispatch(resetStateCount("sex"));
            dispatch(resetStateAcc("status"));
            dispatch(resetToggleAcc("status"));
            dispatch(cleanSorted());
          }}
        >
          <div className="filter__box filter--gender">
            <div className="filter__checkboxes-grid">
              {data.gender.map((i) => {
                return (
                  <CheckboxFilter
                    key={i.id}
                    name={i.name}
                    id={i.id}
                    value={i.value}
                    title={i.title}
                    border={false}
                    count={i.count}
                  />
                );
              })}
            </div>

            <div className="filter__checkbox-buttons">
              {data.sex.map((i) => {
                return (
                  <CheckboxFilter
                    key={i.id}
                    name={i.name}
                    id={i.id}
                    value={i.value}
                    title={i.title}
                    buttonCheckbox={true}
                    count={i.count}
                  />
                );
              })}
            </div>
          </div>
        </Accordion>
        <Accordion
          name={"breast"}
          title={t("appearance")}
          onClick={(e) => {
            dispatch(rangeChangeForModel({ name: "weight", min: 0, max: 0 }));
            dispatch(rangeChangeForModel({ name: "height", min: 0, max: 0 }));
            dispatch(resetState("breast"));
            dispatch(resetState("breast_size"));
            dispatch(resetState("penis_size"));
            dispatch(resetState("breast_type"));
            dispatch(resetSelected("breast"));
            dispatch(resetStateCount("breast_size"));
            dispatch(resetStateCount("breast_type"));
            dispatch(resetStateCount("penis_size"));
            dispatch(resetStateCount("ethnos"));
            dispatch(resetStateCount("hair_color"));
            dispatch(resetStateCount("hair_size"));
            dispatch(resetStateCount("hair_intim"));
            dispatch(resetStateCount("eye_color"));
            dispatch(resetStateCount("eye_type"));
            dispatch(resetStateCount("special_lips"));
            dispatch(resetStateCount("special_tattoo"));
            dispatch(resetStateCount("special_piercing"));
            dispatch(resetStateCount("special_smoke"));
            dispatch(resetStateCount("penis_size"));
            dispatch(
              resetRange({
                name: "weight",
                min: defaultRangeValue.weight.min,
                max: defaultRangeValue.weight.max,
              })
            );
            dispatch(
              resetRange({
                name: "height",
                min: defaultRangeValue.height.min,
                max: defaultRangeValue.height.max,
              })
            );
          }}
        >
          <div className="filter__box">
            <InputRangeFilter
              min={defaultRangeValue.weight.min}
              max={defaultRangeValue.weight.max}
              step={1}
              clazz={"range--weight"}
              inputIdLeft={"minWeight"}
              inputIdRight={"maxWeight"}
              resetButton={true}
              name={"weight"}
              title={`${t("weight")},`}
              subtitle={` ${t("kg")}`}
            />

            <InputRangeFilter
              min={defaultRangeValue.height.min}
              max={defaultRangeValue.height.max}
              step={1}
              inputIdLeft={"minHeight"}
              inputIdRight={"maxHeight"}
              resetButton={true}
              name={"height"}
              title={`${t("height")},`}
              subtitle={` ${t("cm")}`}
            />
          </div>
          {(genderCount.includes("trans") || genderCount.includes("male")) && (
            <div className="filter__box filter--breast">
              <span className={"filter__title"}>{t("penissize")}</span>
              {data.penis.size.map((i) => {
                return (
                  <CheckboxFilter
                    border={false}
                    key={i.id}
                    name={i.name}
                    id={i.id}
                    value={i.value}
                    title={i.title}
                    count={i.count}
                  />
                );
              })}
            </div>
          )}

          {(!genderCount.includes("male") ||
            genderCount.includes("trans") ||
            genderCount.includes("female")) && (
            <div className="filter__box filter--breast">
              <span className={"filter__title"}>{t("breastsize")}</span>
              {data.breast.size.map((i) => {
                return (
                  <CheckboxFilter
                    border={false}
                    key={i.id}
                    name={i.name}
                    id={i.id}
                    value={i.value}
                    title={i.title}
                    count={i.count}
                  />
                );
              })}

              <div className="filter__checkbox-buttons">
                {data.breast.type.map((i) => {
                  return (
                    <CheckboxFilter
                      key={i.id}
                      name={i.name}
                      id={i.id}
                      value={i.value}
                      title={i.title}
                      buttonCheckbox={true}
                      count={i.count}
                    />
                  );
                })}
              </div>
            </div>
          )}

          <div className="filter__box filter--ethnos">
            <span className={"filter__title"}>{t("ethnicity")}</span>

            <div className="filter__checkboxes-grid">
              {data.ethnos.map((i) => {
                return (
                  <CheckboxFilter
                    border={false}
                    key={i.id}
                    name={i.name}
                    id={i.id}
                    value={i.value}
                    title={i.title}
                    count={i.count}
                  />
                );
              })}
            </div>
          </div>

          <div className="filter__box filter--hair">
            <span className={"filter__title"}>{t("hair")}</span>

            <div className="filter__checkboxes-grid">
              {data.hair.color.map((i) => {
                return (
                  <CheckboxFilter
                    border={false}
                    key={i.id}
                    name={i.name}
                    id={i.id}
                    value={i.value}
                    title={i.title}
                    count={i.count}
                  />
                );
              })}
            </div>

            <div className="filter__checkbox-buttons">
              {data.hair.size.map((i) => {
                return (
                  <CheckboxFilter
                    border={false}
                    key={i.id}
                    name={i.name}
                    id={i.id}
                    value={i.value}
                    title={i.title}
                    buttonCheckbox={true}
                    count={i.count}
                  />
                );
              })}
            </div>

            <span className={"filter__title"}>{t("pubichair")}</span>
            <div className="filter__checkbox-buttons">
              {data.hair.intim.map((i) => {
                return (
                  <CheckboxFilter
                    border={false}
                    key={i.id}
                    name={i.name}
                    id={i.id}
                    value={i.value}
                    title={i.title}
                    buttonCheckbox={true}
                    count={i.count}
                  />
                );
              })}
            </div>
          </div>

          <div className="filter__box filter--eye">
            <span className={"filter__title"}>{t("eyes")}</span>

            <div className="filter__checkboxes-grid">
              {data.eye.color.map((i) => {
                return (
                  <CheckboxFilter
                    border={false}
                    key={i.id}
                    name={i.name}
                    id={i.id}
                    value={i.value}
                    title={i.title}
                    count={i.count}
                  />
                );
              })}
            </div>

            <div className="filter__checkbox-buttons">
              {data.eye.type.map((i) => {
                return (
                  <CheckboxFilter
                    key={i.id}
                    name={i.name}
                    id={i.id}
                    value={i.value}
                    title={i.title}
                    buttonCheckbox={true}
                    count={i.count}
                  />
                );
              })}
            </div>
          </div>

          <div className="filter__box filter--special">
            <span className={"filter__title"}>{t("features")}</span>

            <div className="filter__row">
              <span className={"filter__title"}>{t("lips")}</span>
              <div className="filter__checkbox-buttons">
                {data.special.lips.map((i) => {
                  return (
                    <CheckboxFilter
                      border={false}
                      key={i.id}
                      name={i.name}
                      id={i.id}
                      value={i.value}
                      title={i.title}
                      buttonCheckbox={true}
                      count={i.count}
                    />
                  );
                })}
              </div>
            </div>
            <div className="filter__row">
              <span className={"filter__title"}>{t("tattoos")}</span>
              <div className="filter__checkbox-buttons">
                {data.special.tattoo.map((i) => {
                  return (
                    <CheckboxFilter
                      border={false}
                      key={i.id}
                      name={i.name}
                      id={i.id}
                      value={i.value}
                      title={i.title}
                      buttonCheckbox={true}
                      count={i.count}
                    />
                  );
                })}
              </div>
            </div>
            <div className="filter__row">
              <span className={"filter__title"}>{t("piercing")}</span>
              <div className="filter__checkbox-buttons">
                {data.special.piercing.map((i) => {
                  return (
                    <CheckboxFilter
                      border={false}
                      key={i.id}
                      name={i.name}
                      id={i.id}
                      value={i.value}
                      title={i.title}
                      buttonCheckbox={true}
                      count={i.count}
                    />
                  );
                })}
              </div>
            </div>
            <div className="filter__row">
              <span className={"filter__title"}>{t("smoking")}</span>
              <div className="filter__checkbox-buttons">
                {data.special.smoke.map((i) => {
                  return (
                    <CheckboxFilter
                      border={false}
                      key={i.id}
                      name={i.name}
                      id={i.id}
                      value={i.value}
                      title={i.title}
                      buttonCheckbox={true}
                      count={i.count}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </Accordion>

        <Accordion
          name={"status"}
          title={t("Status")}
          onClick={() => {
            dispatch(resetState("status"));
            dispatch(resetState("last_visit"));
            dispatch(resetSelected("status"));
            dispatch(resetStateCount("status"));
            dispatch(resetStateCount("gender"));
            dispatch(resetStateCount("last_visit"));
            dispatch(resetStateAcc("gender"));
            dispatch(resetToggleAcc("gender"));
            dispatch(cleanSorted());
          }}
        >
          <div className="filter__box filter--tag">
            {data.tag.map((i) => {
              return (
                <CheckboxFilter
                  key={i.id}
                  name={i.name}
                  id={i.id}
                  value={i.value}
                  title={i.title}
                  buttonCheckbox={true}
                  count={i.count}
                />
              );
            })}
          </div>
          <div className="filter__box filter--status">
            {data.status.map((i) => {
              return (
                <CheckboxFilter
                  border={false}
                  key={i.id}
                  name={i.name}
                  id={i.id}
                  value={i.value}
                  title={i.title}
                  count={i.count}
                  forDisabled={i.forDisabled}
                />
              );
            })}
          </div>
          <div className="filter__box">
            <span className={"filter__title"}>{t("lastvisit")}</span>
            <div className="filter__checkbox-buttons filter--last-visit">
              {data.last_visit.map((i) => {
                return (
                  <CheckboxFilter
                    key={i.id}
                    name={i.name}
                    id={i.id}
                    value={i.value}
                    title={i.title}
                    buttonCheckbox={true}
                    flag={i.value}
                    count={i.count}
                    radio={i.radio}
                  />
                );
              })}
            </div>
          </div>
        </Accordion>

        <Accordion
          name={"services"}
          title={t("services")}
          onClick={() => {
            dispatch(resetState("services"));
            dispatch(resetState("for"));
            dispatch(resetState("min_tariffs"));
            dispatch(resetSelected("services"));
            dispatch(resetStateCount("for"));
            dispatch(resetStateCount("services"));
            dispatch(resetStateCount("min_tariffs"));
          }}
        >
          <div className="filter__box filter--services">
            <>
              <span className={"filter__title"}>{t("servicesfor")}</span>
              <div className="filter__checkboxes-grid">
                {data.services.for.map((i) => {
                  return (
                    <CheckboxFilter
                      border={false}
                      key={i.id}
                      name={i.name}
                      id={i.id}
                      value={i.value}
                      title={t(i.title)}
                      count={i.count}
                    />
                  );
                })}
              </div>

              <span className={"filter__title"}>{categorySex?.title}</span>
              <div className="filter__checkboxes-grid">
                {data.services.sex.map((i) => {
                  return (
                    <CheckboxFilter
                      border={false}
                      key={i.id}
                      name={i.name}
                      id={i.id}
                      value={i.value}
                      title={t(i.title)}
                      count={i.count}
                    />
                  );
                })}
              </div>

              <span className={"filter__title"}>
                {categoryStriptease?.title}
              </span>
              <div className="filter__checkboxes-grid">
                {data.services.strip.map((i) => {
                  return (
                    <CheckboxFilter
                      border={false}
                      key={i.id}
                      name={i.name}
                      id={i.id}
                      value={i.value}
                      title={t(i.title)}
                      count={i.count}
                    />
                  );
                })}
              </div>

              <span className={"filter__title"}>{categoryMassage?.title}</span>
              <div className="filter__checkboxes-grid">
                {data.services.massage.map((i) => {
                  return (
                    <CheckboxFilter
                      border={false}
                      key={i.id}
                      name={i.name}
                      id={i.id}
                      value={i.value}
                      title={t(i.title)}
                      count={i.count}
                    />
                  );
                })}
              </div>

              <span className={"filter__title"}>{categoryBdsm?.title}</span>
              <div className="filter__checkboxes-grid">
                {data.services.bdsm.map((i) => {
                  return (
                    <CheckboxFilter
                      border={false}
                      key={i.id}
                      name={i.name}
                      id={i.id}
                      value={i.value}
                      title={t(i.title)}
                      count={i.count}
                    />
                  );
                })}
              </div>
              <span className={"filter__title"}>{categoryExtreme?.title}</span>
              <div className="filter__checkboxes-grid">
                {data.services.extrim.map((i) => {
                  return (
                    <CheckboxFilter
                      border={false}
                      key={i.id}
                      name={i.name}
                      id={i.id}
                      value={i.value}
                      title={t(i.title)}
                      count={i.count}
                    />
                  );
                })}
              </div>
              <span className={"filter__title"}>
                {categoryAdditional?.title}
              </span>
              <div className="filter__checkboxes-grid">
                {data.services.extra.map((i) => {
                  return (
                    <CheckboxFilter
                      border={false}
                      key={i.id}
                      name={i.name}
                      id={i.id}
                      value={i.value}
                      title={t(i.title)}
                      count={i.count}
                    />
                  );
                })}
              </div>
            </>
          </div>
          <div className="filter__box filter--min-tariffs">
            <span className={"filter__title"}>{t("mintime")}</span>
            <div className="filter__checkbox-buttons">
              {data.min_tariffs.map((i) => {
                return (
                  <CheckboxFilter
                    border={false}
                    key={i.id}
                    name={i.name}
                    id={i.id}
                    value={i.value}
                    title={i.title}
                    buttonCheckbox={true}
                    count={i.count}
                  />
                );
              })}
            </div>
          </div>
        </Accordion>

        <Accordion
          name={"language"}
          title={t("language")}
          onClick={() => {
            dispatch(resetState("language"));
            dispatch(resetSelected("language"));
            dispatch(resetStateCount("language"));
          }}
        >
          <div className="filter__box filter__checkbox-buttons filter--language">
            {langData.length > 0 &&
              langData.map((i) => {
                return (
                  <CheckboxFilter
                    border={false}
                    key={`languages-${i.id}`}
                    name={"language"}
                    id={`languages-${i.id}`}
                    value={i.short_name.toUpperCase()}
                    title={i.name}
                    flag={i.short_name.toUpperCase()}
                    count={i.short_name}
                    type={"flag"}
                  />
                );
              })}
          </div>
        </Accordion>

        <Accordion
          name={"nation"}
          title={t("nationality")}
          onClick={() => {
            dispatch(resetState("nation"));
            dispatch(resetSelected("nation"));
            dispatch(resetStateCount("nation"));
          }}
        >
          <div className="filter__box filter__checkbox-buttons filter--national">
            {nationData.length > 0 &&
              nationData.map((i) => {
                return (
                  <CheckboxFilter
                    border={false}
                    key={`nation-${i.id}`}
                    name={"nation"}
                    id={`nation-${i.id}`}
                    value={i.code.toUpperCase()}
                    title={i.name}
                    type={"flag"}
                    flag={i.code.toUpperCase()}
                    count={i.code}
                  />
                );
              })}
          </div>
        </Accordion>

        <Accordion
          name={"search"}
          title={t("search")}
          onClick={() => {
            dispatch(resetRadiusValue());
            dispatch(resetRadiusCount());
            dispatch(resetAddressValue());
            dispatch(resetSelected("radius"));
          }}
        >
          <div className="filter__box filter--radius">
            <div className="search filter__map-input">
              <span className={"filter__map-title"}>{t("youraddress")}</span>
              <label
                htmlFor="geosuggest__input"
                className="search__label header__search"
              >
                {/* <Input
                  type="text"
                  placeholder={`${t("adressplaceholder")}`}
                  name="location"
                  id="geosuggest__input"
                  clazz={"header__search-input"}
                  valueFromSearch={address}
                /> */}
                <GeoInput
                  value={address}
                  name={"location-create"}
                  id="geosuggest__input-create"
                  clazz={`header__search-input`}
                  placeholder={t("adressplaceholder")}
                  bounds={bounds}
                />
              </label>
              <Button
                title={t("chekmyplace")}
                onClick={() => handleLocationClick()}
                name={"location"}
                square={true}
                buttonType={"submit"}
                size={"l"}
                clazz={
                  isActive
                    ? "search__icon button--tetriary active"
                    : "search__icon button--tetriary"
                }
                type="submit"
              >
                <Icon size={"xl"} spritePath={"location"} />
              </Button>
            </div>

            <div className="filter__checkbox-buttons">
              {data.radius.map((i) => {
                return (
                  <CheckboxFilter
                    key={i.id}
                    name={i.name}
                    id={i.id}
                    value={i.value}
                    title={i.title}
                    buttonCheckbox={true}
                    flag={i.value}
                    count={i.count}
                    radio={i.radio}
                  />
                );
              })}
            </div>
          </div>
        </Accordion>

        <div className={`filter__bottom ${isFilterEdit ? "fd-column" : ""}`}>
          {!isFilterEdit && (
            <Button
              onClick={() => {
                dispatch(hide());
                dispatch(setSortedModelsByFilter(filterdSting));
                dispatch(setSearchFilter(true));
                if (!isStatusFullPopup) {
                  dispatch(setFilterMiniPopupStatus(true));
                  dispatch(setFilterPopupStatus(true));
                }
                if (isStatusFullPopup) {
                  dispatch(setFilterMiniPopupStatus(true));
                }
                dispatch(setResultString(filterdSting));
                navigate(
                  `/${lang}/${userCity.slug}${urlParams ? "/" + urlParams : ""}`
                );
              }}
              buttonType={"submit"}
              size={"m"}
              clazz={"button--green filter__submit"}
            >
              {`${t("show")} ${searchCount} ${plural(lang, searchCount)}`}
            </Button>
          )}
          {!isFilterEdit && (
            <Button
              onClick={() => {
                dispatch(resetAll());
                dispatch(resetAllCounters());
                dispatch(setSearchFilter(false));
                dispatch(cleanSorted());
                dispatch(setSortedModelsByFilter(""));
                dispatch(clearTitle());
                dispatch(setSearchValue(""));
                dispatch(fetchRanges(currentCity.id));
                dispatch(
                  rangeChangeForModel({
                    name: "price",
                    min: defaultRangeValue.price.min,
                    max: defaultRangeValue.price.max,
                  })
                );
                dispatch(
                  rangeChangeForModel({
                    name: "age",
                    min: defaultRangeValue.age.min,
                    max: defaultRangeValue.age.max,
                  })
                );
                dispatch(setFilterMiniPopupStatus(false));
              }}
              size={"s"}
              clazz={"button--secondary filter__reset"}
            >
              {t("reset")}
            </Button>
          )}
          {isFilterEdit && (
            <InputInLabel
              clazz={`${editFilterError ? " _error" : "w-100"}`}
              id={"loginRegistration"}
              type={"text"}
              placeholder={t("")}
              value={editFilterName}
              onChange={(e) => {
                setEditFilterName(e.taret.value);
                if (editFilterError) {
                  setEditFilterError(false);
                }
              }}
              autocomplete={"off"}
            >
              {(editFilterError && (
                <span className={"input-label__error"}>{t("required")}</span>
              )) || <span>{t("filtername")}</span>}
            </InputInLabel>
          )}
          <EditFilterWrapper>
            {isFilterEdit && (
              <Button
                onClick={() => {
                  dispatch(hide());
                  dispatch(setFilterEditId(false));

                  handleEditFilter(isFilterEdit.id);
                }}
                buttonType={"button"}
                size={"m"}
                clazz={"button--green filter__submit"}
              >
                {t("savefilters")}
              </Button>
            )}
            <Button
              size={"s"}
              clazz={"button_outline--green"}
              onClick={handleClose}
            >
              {t("toclose")}
              <Icon size={"l"} spritePath={"close"} />
            </Button>
          </EditFilterWrapper>
        </div>
    </Popup>
  );
};

export default Filter;
