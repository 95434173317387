import { Link } from "react-router-dom";
import { Checkbox, Icon } from "@/components/ui";
import img1 from "../../../../../../assets/img/pick-profile/img1.webp";
import img2 from "../../../../../../assets/img/pick-profile/img2.webp";
import img3 from "../../../../../../assets/img/pick-profile/img3.webp";
import { useSelector } from "react-redux";
import { getShortProfileData } from "../../../../../../stores/slices/oneProfileSlice";
import { makeRequest } from "../../../../../../services/makeRequest";
import useLadyService from "../../../../../../services/LadyService";
import EliteStatusCards from "../../../../../eliteStatusCards/EliteStatusCards";

const StatusElite = ({ eliteGlobal, setEliteGlobal }) => {
  const { t } = useLadyService();

  const data = useSelector(getShortProfileData);

  const { id } = data;

  const onEliteRemove = async () => {
    try {
      const route = `user/elite/remove`;

      const method = "DELETE";
      const payload = {
        profiles: [id],
      };
      setEliteGlobal(false);
      await makeRequest({ route, method, payload });
    } catch (error) {}
  };

  const onEliteSet = async () => {
    try {
      const route = `user/elite/set`;

      const method = "POST";
      const payload = {
        profiles: [id],
      };

      setEliteGlobal(true);

      await makeRequest({ route, method, payload });
    } catch (error) {}
  };

  return (
    <div className="guide__profile-root">
      <h3 id="statusElite">{t("Status")} Elite Lady</h3>
      <p>
        {t("highlightelite")}{" "}
        <span className="green">
          (+50% {t("toviews")} {t("and")} + 25% {t("toorders")})
        </span>{" "}
        {t("welathyorders")}
      </p>
      <EliteStatusCards />
      <div className="profile-page__bottom">
        <div
          className={"profile-page__box"}
          onClick={eliteGlobal ? onEliteRemove : null}
        >
          <div className="profile-page__box-grey">
            <Checkbox
              size={"l"}
              checked={!eliteGlobal}
              clazz={"button--secondary"}
              title={`${t("keep")} ${t("standart")}`}
            />
          </div>
          <span>{t("free")}</span>
        </div>
        <div
          className={"profile-page__box"}
          onClick={eliteGlobal ? null : onEliteSet}
        >
          <div className="profile-page__box-green">
            <Checkbox
              size={"l"}
              checked={eliteGlobal}
              clazz={"button--green"}
              title={`${t("select")} Elite Lady`}
            />
          </div>
          <div className="profile-page__wrapper">
            <span className={"profile-page__old-price"}>50€</span>
            <span>25€</span>
            <p className={"p1"}>
              {t("first")} {t("month")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusElite;
