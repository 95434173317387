import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  isFullPopup: Cookies.get('isFullPopup') === 'true' ? true : false,
  isMiniPopupShow: false,
  savedFiltersIds: false
};

export const filterPopup = createSlice({
  name: "filterPopup",
  initialState,
  reducers: {
    setSavedFiltersIds: (state, action) => {
      state.savedFiltersIds = action.payload
    },
    setFilterPopupStatus: (state, action) => {
      state.isFullPopup = action.payload
      Cookies.set('isFullPopup', action.payload);
    },
    setFilterMiniPopupStatus: (state, action) => {
      state.isMiniPopupShow = action.payload
    }
  },
});

export const { setSavedFiltersIds, setFilterPopupStatus, setFilterMiniPopupStatus } = filterPopup.actions;

export const getFilterPopupStatus = (state) => state.filterPopup.isFullPopup;
export const getFilterMiniPopupStatus = (state) => state.filterPopup.isMiniPopupShow;
export const getSavedFiltersIds = (state) => state.filterPopup.savedFiltersIds;

export default filterPopup.reducer;