import { useEffect, useRef, useState } from "react";
import { Icon } from "@/components/ui";
import { useDrag, useDrop } from "react-dnd";
import { Loader } from "@/components/ui";
import useLadyService from "@/services/LadyService";

export const Image = ({
  photo: oldPhoto,
  index,
  movePhoto,
  isVideo = false,
  video = false,
  moderated,
  status,
  type,
}) => {
  const { token, t, setMediaSize } = useLadyService();

  const [photo, setPhoto] = useState("");

  useEffect(() => {
    if(oldPhoto) {
      if(!isVideo || !video) {
        const result = setMediaSize(oldPhoto, "s");
        setPhoto(result)
      } else {
        setPhoto(oldPhoto)
      }
    }
  }, [oldPhoto])

  const [, drag] = useDrag({
    type: isVideo ? "VIDEO" : "PHOTO",
    item: { type: isVideo ? "VIDEO" : "PHOTO", index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(type === "ero" ? true : false);

  const fetchMedia = async (url) => {
    setIsLoading(true);
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache",
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching media: ${response.statusText}`);
      }

      const contentType = response.headers.get("content-type");
      if (contentType.includes("image") || contentType.includes("video")) {
        const blob = await response.blob();
        return URL.createObjectURL(blob);
      } else {
        throw new Error("Unsupported media type");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  function getPlayUrl(str) {
    const match = str.match(
      /https:\/\/api\.lady4love\.com\/api\/storage\/(\d+)\.mp4(?:\?key=(\d+))?/
    );
    if (match) {
      const id = match[1];
      const key = match[2];
      if (key) {
        return `https://api.lady4love.com/api/storage/${id}/play.mp4?key=${key}`;
      } else {
        return `https://api.lady4love.com/api/storage/${id}/play.mp4`;
      }
    }

    return null;
  }

  const handleImageError = () => {
    setPhoto(oldPhoto)
  };

  // const displayImage = (url) => {
  //   fetchMedia(url).then((res) => {
  //     const newImageURL = URL.createObjectURL(res);
  //     setFile(newImageURL);
  //   });
  // };

  const [, drop] = useDrop({
    accept: isVideo ? "VIDEO" : "PHOTO",
    hover(item, monitor) {
      if (!dragRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      movePhoto(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const dragRef = useRef(null);
  drag(drop(dragRef));

  useEffect(() => {
    if (type === "ero") {
      if (isVideo || video) {
        fetchMedia(getPlayUrl(photo)).then((mediaUrl) => {
          setFile(mediaUrl);
        });
      } else {
        fetchMedia(photo).then((mediaUrl) => {
          setFile(mediaUrl);
        });
      }
    }
  }, [photo, type]);

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      ref={dragRef}
      className={`${
        isVideo || video
          ? "create-page__upload-video status"
          : "create-page__upload-image status"
      } ${moderated === 1 ? "confirmed" : moderated === 2 ? "decline" : ""}`}
    >
      {status === "load" || isLoading ? (
        <Loader />
      ) : photo ? (
        <>
          {isVideo || video ? (
            <video
              src={type === "ero" ? file || photo : photo}
              width="340px"
              height="190px"
              controls
            ></video>
          ) : (
            <img src={type === "ero" ? file || photo : photo} onError={handleImageError} alt={""} />
          )}
          {moderated === 2 && (
            <span className="ero-status color-red-700">
              {t("declinedbymoderator")}
            </span>
          )}
          {moderated === 1 && (
            <span className="ero-status color-green">{t("publishedono")}</span>
          )}
          {moderated === 0 && (
            <span className="ero-status color-main">
              {t("moderated24hour")}
            </span>
          )}
        </>
      ) : (
        <div className={`input--upload`}>
          <input type="file" multiple="multiple"></input>
          <Icon clazz={"_green"} size={"l"} spritePath={"plus"} />
        </div>
      )}
    </div>
  );
};
