import { Icon } from "@/components/ui";
import { useEffect, useState } from "react";

import "./exampleImage.scss";
import { DeletePhotoPopup } from "@/components/popups";
import useLadyService from "@/services/LadyService";
import axios from "axios";
import { makeRequest } from "@/services/makeRequest";

const ExampleImage = ({
  image,
  setImage,
  moderated,
  removeLink,
  name,
  setDataInfo = false,
  type="superId"
}) => {
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const { t, token } = useLadyService();

  const handleOpenDelete = () => {
    setIsOpenDelete(true);
  };
  const fetchData = async () => {
    try {
      const route = `user/info`;
      const method = "GET";

      const data = await makeRequest({ route, method });

      if (data) {
        const result = data.data;
        if(setDataInfo) {
          setDataInfo(result);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const removeImg = () => {
    
    axios
      .delete(`${removeLink}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(
        setImage(null),
        setIsOpenDelete,
        fetchData()
      );
  };

  return (
    <>
      <div className={`example__photo`}>
        {!image.includes(".pdf") && image !== "pdf" && (
          <img src={image} alt={""} />
        )}
        {(image.includes(".pdf") || image === "pdf") && (
          <a
            target="_blank"
            href={image}
            className="pdf-template d-flex fd-column align-center justify-se"
          >
            <p className="color-green">Health control</p>
            <Icon spritePath={"health-fill"} size={"xxl"} clazz={"_green"} />
            <div className="d-flex fd-column gap-4 align-center">
              <span className="color-green">PDF</span>
              <span className="color-green">{name}</span>
            </div>
          </a>
        )}
        <span className="icon-remove" onClick={() => handleOpenDelete()}>
          <Icon size={"s"} spritePath={"close"} />
        </span>
        {moderated === 2 && (
          <span className="example__photo__ero-status text-center color-red-700">
            {t("declinedbymoderator")}
          </span>
        )}
        {moderated === 1 && (
          <span className="example__photo__ero-status text-center color-green">
            {t("publishedono")}
          </span>
        )}
        {moderated === 0 && (
          <span className="example__photo__ero-status text-center color-main">
            {t("moderated24hour")}
          </span>
        )}
      </div>

      {isOpenDelete &&
        <DeletePhotoPopup
          type={type}
          open={isOpenDelete}
          setOpen={setIsOpenDelete}
          removeImg={removeImg}
        />
      }
    </>
  );
};

export default ExampleImage;
