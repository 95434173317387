import { useDispatch, useSelector } from "react-redux";
import {
  getAppealStatus,
  setAddReviewStatus,
  setAppealStatus,
  setSuccessAddReviewStatus,
  setSuccessAppealStatus,
} from "@/stores/slices/popupSlice";
import { Helmet } from "react-helmet";
import { Button, Icon, InputInLabel } from "@/components/ui";
import { useTranslation } from "react-i18next";
import SuccessAppealMassage from "./SuccessAppealMassage";
import { useForm } from "react-hook-form";
import { makeRequest } from "@/services/makeRequest";
import showToast from "../../toast/Toast";
import {Popup} from "../index";

const Appeal = (props) => {
  const dispatch = useDispatch();
  const AppealValue = useSelector(getAppealStatus);

  const { t } = useTranslation("translation");

  const { register, handleSubmit, reset } = useForm({
    mode: "onBlur",
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    const fetchData = async () => {
      try {
        const route = `reviews`;
        const method = "PUT";
        const payload = {
          ...data,
        };

        await makeRequest({ route, method, payload });
        showToast({
          message: t("successreview"),
          subMessage: t("willpublish"),
          variant: "success",
        });
      } catch (error) {
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };

    // fetchData();
    reset();
    dispatch(setSuccessAppealStatus(true));
    dispatch(setAppealStatus(false));
  };

  return (
      <Popup
        open={!!AppealValue}
        setOpen={() => dispatch(setAppealStatus(false))}
        id={"addAppeal"}
        method={"POST"}
        onSubmit={handleSubmit(onSubmit)}
      >
          <h3 className="m-12-24">{t("complain")}</h3>
          <p className={"color-600 p2 mb-0"}>{t("complaintreview")}</p>

          <div className="popup-form__inner">
            <div className={"popup-form__inner text-center gap-4"}>
              <p className={"color-600 fz-13 mb-0"}>{t("modelname")}</p>
              <span className={"color-green p1"}>{props.name}</span>
            </div>

            <InputInLabel
              register={{ ...register("body") }}
              type={"textarea"}
              id={"appealText"}
              placeholder={t("incidentoccur")}
            >
              {t("complaintreason")}
            </InputInLabel>

            <Button
              size={"l-forever"}
              buttonType={"submit"}
              clazz={"button--green"}
            >
              {t("send")}
            </Button>
          </div>
      </Popup>
  );
};

export default Appeal;
