import { Select, Checkbox } from "@/components/ui";
import { ReviewContainer } from "./pageComponents";
import { useState, useEffect } from "react";
import { makeRequest } from "@/services/makeRequest";
import useLadyService from "@/services/LadyService";

import "./reviewsPage.scss";
import Pagination from "../../../../pagination/Pagination";
import { CustomSkeleton, Dropdown } from "../../../../ui";
import { useLocation, useNavigate } from "react-router-dom";
import showToast from "../../../../toast/Toast";
import ShortFilterData from "../../../../shortFilter/ShortFilterData";
import useTitle from "@/hooks/useTitle";

const skeletons = [...Array(12)].map((_, index) => {
  return <CustomSkeleton key={index} height={150} />;
});

const Review = ({ isLoading, data, setData }) => {
  const { t } = useLadyService();
  switch (true) {
    case isLoading: {
      return skeletons;
    }

    case !!data.length: {
      return data?.map((i, index) => {
        return <ReviewContainer key={index} data={i} setData={setData} />;
      });
    }

    default: {
      return <h3 className="text-center mt-12">{t("emptyrecord")}</h3>;
    }
  }
};

const ReviewsPage = () => {
  const { t, token, lang, scrollToTop } = useLadyService();
  const navigate = useNavigate();

  const { hash } = useLocation();

  const [data, setData] = useState([]);

  const [pagesList, setPagesList] = useState([]);
  const [page, setPage] = useState(1);

  const [isLoading, setIsLoading] = useState(true);

  const [filterValue, setFilterValue] = useState([]);
  const [sortValue, setSortValue] = useState("date");

  const filterNavigation = [
    { id: 1, link: "#", title: t("bynew"), value: "date" },
    { id: 2, link: "#", title: t("byrating"), value: "rating" },
  ];

  const filterNavigationMore = [
    {
      id: 1,
      title: t("published"),
      value: "published",
    },
    {
      id: 2,
      title: t("rejected"),
      value: "rejected",
    },
    {
      id: 3,
      title: t("withoutanswer"),
      value: "withoutanswer",
    },
  ];

  const handleCheckbox = (value) => {
    setPage(1);
    navigate(`/${lang}/lk/indi/reviews#1`);
    setFilterValue((prev) => {
      const index = prev.indexOf(value);
      if (index === -1) {
        return [...prev, value];
      } else {
        return prev.filter((item) => item !== value);
      }
    });
  };

  const handleSort = (value) => {
    setSortValue(value);
  };

  const handlePage = (e, value) => {
    e.preventDefault();
    navigate(`/${lang}/lk/indi/reviews#${value}`);
    setPage(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        scrollToTop()
        const route = `user/reviews`;
        const method = "GET";
        const payload = {
          per_page: 12,
          page,
          sort: sortValue,
          published: filterValue.includes("published") ? 1 : 0,
          rejected: filterValue.includes("rejected") ? 1 : 0,
          without_answer: filterValue.includes("withoutanswer") ? 1 : 0,
        };

        const { data = [], meta } = await makeRequest({
          route,
          method,
          payload,
        });

        const { per_page, total } = meta;
        const result = Math.ceil(total / per_page);
        const arrayRes = Array.from({ length: result }, (_, i) => i + 1);
        setPagesList(arrayRes);

        setData(data);
        setIsLoading(false);
      } catch (error) {
        showToast({
          message: t("oops"),
          variant: "error",
        });
        setIsLoading(false);
      }
    };

    page !== 0 && token && fetchData();
  }, [token, sortValue, page, filterValue]);

  useEffect(() => {
    let str = hash;
    str = str.replace("#", "");
    setPage(Number(str) === 0 ? 1 : Number(str));
  }, [hash]);

  useTitle(t("profuser") + " - Lady4Love");

  return (
    <main>
      <section className={"container"}>
        <h1>{t("myreviews")}</h1>
        <div className="reviews-page__header">
          <div className="reviews-page__filter d-none-tablet">
            {filterNavigationMore.map((i) => {
              return (
                <Checkbox
                  key={i.id}
                  id={i.id}
                  checked={filterValue.includes(i.value)}
                  title={i.title}
                  path={i.path}
                  value={i.value}
                  name={i.name}
                  onChange={() => handleCheckbox(i.value)}
                />
              );
            })}
          </div>

          <Dropdown
            title={ShortFilterData().main_title}
            size={"m"}
            clazzWrapper={"display-none-mobile-reverse"}
          >
            {filterNavigationMore.map((i) => {
              return (
                <Checkbox
                  key={i.id}
                  id={i.id}
                  checked={filterValue.includes(i.value)}
                  title={i.title}
                  path={i.path}
                  value={i.value}
                  name={i.name}
                  onChange={() => handleCheckbox(i.value)}
                />
              );
            })}
          </Dropdown>

          <Select
            options={filterNavigation}
            leftIcon={"sort"}
            clazzSvg={"icon-m"}
            clazz={"button button--secondary"}
            arrowSize={"s"}
            sorted={true}
            name={"sort"}
            onChange={handleSort}
            size={"xs"}
          />
        </div>

        <Review data={data} isLoading={isLoading} />
        {pagesList.length > 1 && (
          <Pagination
            pages={pagesList}
            activePage={page}
            onPageChange={handlePage}
          />
        )}
      </section>
    </main>
  );
};

export default ReviewsPage;
