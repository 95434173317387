export const rulesEn = {
  h1: 'General Terms and Conditions',

  one: {
    title: 'TERMS AND DEFINITIONS',
    p1: 'In accordance with these general terms and conditions (hereinafter also referred to as "General Terms"), operator of the Internet server www.lady4love.com IT4L LLC, OGRN 19470711, registered at Podebradska 1026/52, Vysochany, 19000, Prague 9, is a provider of services presented on the server. The main services are provision of space for advertising adult services with possibility of online communication between the customer and advertiser and mediation in the sale of additional services.',
    p2: 'In accordance with the General Terms, an advertiser (hereinafter referred to as Advertiser) is an individual over the age of 18 or a legal entity that has ordered publication of its advertisement or other forms of service presentation from the server operator.',
    p3: 'In accordance with the General Terms, an advertisement originator is a legal entity or an individual who processes advertisements for themselves or for another legal entity or individual published by the server operator in accordance with advertiser\'s order.',
    p4: 'In accordance with the General Terms, a customer is an individual over the age of 18 who uses information published in the advertisement for his/her personal needs or purchases at least one of additional services offered. To purchase additional services offered, pre-registration is required, which is associated with opening a virtual client’s account and making a deposit to this virtual account.',
    p5: 'In accordance with the General Terms, advertisement is a notification, demonstration or other presentation of services aimed at supporting profitable activities of an advertiser, published by the server operator on the Internet portal www.lady4love.com. Textual and graphic information provided in each individual advertisement must comply with relevant provisions of Czech law.',
    p6: 'Graphic materials used for presentation of services are primarily photographs and videos of persons offering erotic or other intimate services. The server operator reserves the right to decide on quality of photos and videos, and also reserves the right to refuse publishing them; all graphic materials are checked by a moderator.',
    p7: 'Each advertisement may offer services of only one individual; materials related to another person are not allowed.',
    p8: 'Sale of additional services is primarily a category of services related to communication between an advertiser and a customer (online video-calls, booking meetings, etc.) and provision of paid access to erotic videos. Sale of services is allowed only as an additional activity of the advertiser, therefore, a seller of services (hereinafter also referred to as a Seller) is subject to all obligations of the advertiser.',
  },
  two: {
    title: 'TERMS OF AGREEMENT',
    p1: 'All contractual business relations between an advertiser and the server operator are governed by the relevant provisions of Czech legislation, in particular Law No. 89/12012, Civil Code as amended, Law No. 90/2012, on commercial corporations as amended, Law No. 40/1995, on regulation of advertising as amended, and Law No. 480/2004, on certain services of the information society.',
    p2: "The server operator provides an advertiser with an opportunity to publish advertisements offering an information space on the server www.lady4love.com in the scope and format determined by a written or oral instruction, contract or other form of will expression by parties. In the absence of other agreements, advertising means publication of textual and graphic materials used to promote offered services as a presentation of one company or one person with one contact phone number or e-mail. Advertisement is valid until the advertiser cancels it in a provable way or until the advertiser's virtual account does not have enough funds to pay for the next week period.",
    p3: "If the advertiser has created an advertisement himself, he is fully responsible for its content. If the advertisement was compiled for the needs of another legal entity or individual, the advertiser and the originator are jointly and severally responsible for its compliance with legal requirements. The same applies to service providers and sellers. The server operator is not responsible for content of such advertisements or the nature of services.",
    p4: "The advertiser acknowledges that the server operator is not responsible for content of an advertisement or accuracy of data contained in the advertisement, and declares that this data is reliable, and the advertisement content complies with relevant regulations of Czech legislation, in particular, Law No. 40/1995, on regulation of advertising as amended. This agreement applies accordingly to the seller and services offered by him/her. The advertiser and seller understand that they are fully responsible for any damage that content of the advertisement or additional services may cause to the server operator or the customer.",
    p5: "The advertiser expressly declares possession of all the necessary rights to use trademarks and/or copyrights for all graphic and/or textual materials used in the ordered advertisement. This agreement applies accordingly to the seller and the services offered by him/her.",
    p6: "The advertiser declares awareness that, in accordance with paragraph 2, §3 of Law No. 40/1995, advertisement should not contradict moral norms, in particular, it should not contain any discrimination based on race, gender or nationality, offend religious or national feelings, pose a threat to morality, humiliate human dignity, contain elements of pornography, violence or elements using motive of fear, as well as advertisement should not affect political beliefs. This agreement applies accordingly to the seller and the services offered by him/her. If this obligation is violated the server operator must act in accordance with the law, in particular, remove the advertisement and notify competent authorities of law violation. Associated costs are charged to the advertiser.",
    p7: "The advertiser explicitly declares that all persons depicted or featured in the advertisement are over the age of 18. This agreement applies accordingly to the seller and the services offered by him/her.",
    p8: "At the request of the server operator, the advertiser must confirm his/her identity, and advertiser's representative must also confirm his/her authority to act on behalf of the advertiser. The server operator is obliged to provide data about the advertiser, originator or seller at the request of financial supervisory authorities for purposes of administrative proceedings and law enforcement agencies for purposes of criminal proceedings.",
    p9: "The advertiser undertakes to compensate the server operator for any damage caused by publication of an advertisement that does not meet general requirements. This agreement applies accordingly to the seller and the services offered by him/her.",
    p10: "The server operator has the right to refuse publishing advertisements and selling services without justification.",
    p11: "The server operator has the right to use textual and graphic materials of the advertiser or seller in other marketing channels, if it does not significantly damage legitimate interests of the advertiser or seller.",
    p12: "A customer has the right to use paid services provided by the server operator, in particular, possibility of an online video call with a selected service provider, possibility of booking a personal meeting with a selected service provider or possibility of organizing meetings with several service providers in order to select one of them for subsequent provision of services, receive invitations to private parties organized by the advertiser, or view adult video content. When ordering paid services, customer has the right to evaluate service provision in a review, and service provider is obliged to publish such a review.",
    p13: "The server operator offers advertisers, sellers and customers other services, in particular those that increase comfort of using the server and security when providing and using advertised services. Those include, for example, publishing data on recent medical tests or protecting advertisers by restriction of access to materials from individual countries; the server operator will gradually develop additional services and offer them as needed, if technically or economically feasible.",
    p14: "The server operator guarantees an extremely high degree of anonymity for advertisers, sellers and customers. The server operator receives personal data only to the extent necessary to ensure quality of the services provided, but not on any account discloses such data (except for data necessary for mutual communication between advertisers and customers, for example, entered phone number or email address) and stores them in a secure repository. All received data will be deleted as soon as its storage is no longer required. Access to data may be allowed only to authorized state bodies and persons concerned with this data (data subjects).",
  },
  three: {
    title: "PAYMENTS",
    p1: "To fix problems with different currencies, differences in exchange rates over time, etc., the server operator created Erocoin virtual currency. All payments are made in this virtual currency by debiting funds from payer's virtual account and crediting them to recipient's virtual account. All payments to the server operator must be made before provision of ordered service; if there are not enough Erocoins on payer's virtual account, the advertisement will not be published and requested service will not be provided.",
    p2: "Payments received to virtual account of an advertiser, seller or customer are stored as a deposit for future payments to the server operator and will be limited only to these payments. Each advertiser, seller, or customer also has an overview of the virtual account turnover over the past three years.",
    p3: "Sales of additional services credited to the advertiser's (seller's) virtual account will be used as a deposit top-up for future payments.",
    p4: "Payments to and withdrawals from a virtual account are not carried out in Erocoins, but are made in euros, US dollars or in currency of the country where or to which the payment is made (hereinafter referred to as actual currencies), by bank transfer, credit card or other legal means. Exchange of Erocoins for actual currencies and vice versa will be automatically carried out in accordance with published exchange rate at the exact moment when the payment in actual currency arrives at or leaves a virtual account. Payment in the currency of the country in which the payment is carried out may be limited by law or by the terms of the participating payment institution; in this case, the payment must be made in a currency that a payment recipient can accept. Payments in actual currency to someone else's virtual account are not possible.",
    p5: "If the server operator refuses to publish an already paid advertisement, the payment will be refunded in full to the advertiser’s virtual account. If the advertiser cancels an already paid advertisement during advertising period, the payment will not be refunded.",
    p6: "If the advertiser or customer does not use paid deposit, the server operator transfers the unused deposit back to the payer, reduced by the payment cost, based on a provable request from the advertiser or customer. A request for withdrawal from a virtual account may be submitted once a week.",
    p7: "Use of the server for any criminal activity is strictly prohibited. Therefore, for preventive purposes, the operator conducts checks to confirm that certain payments do not result from criminal activity or are not used to commit criminal acts, for example, trading in prostitution. In case of suspicion that any of the payments may be related to criminal activity, the server operator will act in accordance with generally accepted anti-money laundering legislation.",
    p8: 'Replenishment purchases of Erocoins (subscription to auto-replenishment of the balance) using a payment card can be made automatically, for the same amount once a month. An order for subscription can be placed when replenishing the balance, and the subscription can be disabled at any time and for free in the "My Balance" section.'
  },
  four: {
    title: "PROTECTION OF THIRD PARTY RIGHTS",
    p1: "All users of server services have the right to protect their identity and property. These users are understood to be, but not limited to, individuals and legal entities advertising their services on the server, persons depicted in photographs or videos posted on the server for any reason, authors of any texts published on the server, as well as server clients. The same protection applies to persons who do not use server services, but whose personal, copyright or other rights have been violated by any user of server services.",
    p2: "The server operator takes all measures that can reasonably be required of him, in particular, independently conducts regular and random checks for violations of third party rights, receives notifications of suspected violations of  third party rights and, if violations of third party rights are detected, immediately takes effective measures to prevent (a) misuse of personal data (b) sexual violence against advertisers and persons depicted in photographs or videos with erotic content, while even a \"simple\" possibility of showing such photographs or videos is considered sexual violence for persons under the age of 18, (c) violation of intellectual property rights, in particular, copyrights to photographs, videos or texts, trademark rights, etc.",
    p3: 'When detecting violations of third party rights, the server operator cooperates with relevant government agencies (police, child and adolescent protective services) and non-governmental organizations.',
    p4: 'Anyone who suspects that, in terms of paragraph 4.1, a violation of personal or property rights has occurred can report this suspicion in writing by mail to IT4L s.r.o., Poděbradská 1026/52, 19000 Praha 9, Czech Republic, by e-mail to info@lady4love.com or by phone +420774231617 in Czech or English. The report must contain: (a) identification of the right that is allegedly violated, for example, identification of a protected copyrighted work, identification of protected personal data, etc., (b) sufficient identification of the materials that should be deleted or access to which should be restricted, including information that allows the server operator to find the materials, (c) information about ways of communication with the complainant, in particular his/her name, address, telephone number and e-mail, (d) a statement that the complainant honestly believes that violation of personal or property rights that he/she complains about is not permitted by the owner, his/her representative or the law, (e) a statement that information in the report is as accurate as could reasonably be required from the complainant, and (f) the complainant\'s physical or electronic signature. If the report is about violation of copyright or similar proprietary right, the report must also contain a statement that the complainant has the authority to act on behalf of the owner of the right that is allegedly violated, if he/she him/herself is not its rightful owner.',
    p5: 'If the server operator does not inform the complainant about the measures taken within a reasonable time, usually 30 days, or if the complainant considers the measures insufficient, he/she may petition the appropriate court.',

  },
  five: {
    title: "FINAL PROVISIONS",
    p1: "Communication with the server operator may be carried out primarily through its \"datová schranka\" skvp3cu or other communication channels created for this purpose directly on the site www.lady4love.com . Contact information for receiving and resolving complaints, receiving notifications of suspected illegal actions of the server operator, advertisers, sellers or customers, as well as for receiving and processing other notifications: phone: +420 774231617, e-mail: info@lady4love.com .",
    p2: "Thus, the advertiser, seller or customer declares that he/she has read these General Terms and fully understands their content, and agrees to comply with them throughout the business relationship with the server operator.",
  },
}