import React from "react";
import useLadyService from "@/services/LadyService";
import {ButtonLink, Icon} from "@/components/ui";
import {OrderAndVidChatToggles} from "./index";

let TogglesOrLinks = () => {
  const { t, userType } = useLadyService();
  return userType === 'agency' ? (
    <>
      <ButtonLink
        href={"#"}
        title={t("videochat")}
        size={"xs"}
        clazz={"button_outline--black _no-underline"}
        counter={3}
      >
        <Icon size={"m"} spritePath={"video"} />
        {t("videochat")}
      </ButtonLink>

      <ButtonLink
        href={"#"}
        title={t("myorder")}
        size={"xs"}
        clazz={"button_outline--black _no-underline"}
        counter={2}
      >
        <Icon size={"m"} spritePath={"pay-content"} />
        {t("myorder")}
      </ButtonLink>

      <ButtonLink
        href={"#"}
        title={t("demos")}
        size={"xs"}
        clazz={"button_outline--black _no-underline"}
        counter={2}
      >
        <Icon size={"m"} spritePath={"casting"} />
        {t("demos")}
      </ButtonLink>
    </>
  ) : (
    <OrderAndVidChatToggles />
  )
};

export default TogglesOrLinks