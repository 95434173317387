import Footer from "../../footer/Footer";
import Header from "../../header/Header";

import Helmet from "react-helmet";

import { Outlet } from "react-router-dom";
import { Favicon } from "@/components/ui";
import { useSelector } from "react-redux";
import { getMapStatus } from "@/stores/slices/popupSlice";
import MapFilter from "../../mapFilter/MapFilter";
import { useEffect } from "react";
import useLadyService from "@/services/LadyService";
import { fetchRanges } from "@/stores/slices/filterSlice";

const IndiLayoutLk = ({ titleParams, postDesc }) => {
  const mapPopupStatus = useSelector(getMapStatus);
  const {dispatch, userCity, userFilterStatus} = useLadyService();

  useEffect(() => {
    if (userCity.id && !userFilterStatus) {
      dispatch(fetchRanges(userCity.id));
    }
  }, [userCity.id, userFilterStatus]);

  return (
    <>
      <Favicon />
      <Helmet>
        <title>{titleParams}</title>
        <meta name="description" content={postDesc} />
      </Helmet>

      <Header lk={true}/>

      <Outlet />

      <Footer />
      {!!mapPopupStatus && <MapFilter />}
    </>
  );
};

export default IndiLayoutLk;
