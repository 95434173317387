import React, {Fragment, useEffect, useState} from "react";
import { Icon, ShowMoreText } from "@/components/ui";
import useLadyService from "@/services/LadyService";
import ZeroZone from "@/components/zeroZone/ZeroZone";

const ModelServices = ({ props, setOpen , preferences}) => {
  const {
    services,
    for_couples,
    for_men,
    for_women,
    for_groups,
    gender,
    orientation,
  } = props;

  const { gendersTitle, orientations, t} = useLadyService();

  const [sortedCategories, setSortedCategories] = useState([]);

  useEffect(() => {
    const sorted = [...services].sort((a, b) => a.category_id - b.category_id);
    setSortedCategories(sorted);
  }, [services]);

  let gendersText = `${gendersTitle[gender]}${
    orientation ? ` — ${orientations[orientation]}` : ""
  }`;

  const Main = () => {
    return (
      <>
        {!!gender ? (
          <h2
            className={
              "title color-main fz-22 fz-18-mob d-flex align-center gap-8"
            }
          >
            <Icon
              clazz={"model__icon"}
              title={gendersText}
              spritePath={`gender-${gender ? gender : "female"}`}
              size={"m"}
            />
            {gendersText}
          </h2>
        ) : null}

        {!!for_couples || !!for_men || !!for_women || !!for_groups ? (
          <h3>
            <span className={"color-600"}>{t("servicesfor")}:</span>{" "}
            {!!for_men && `${t("men")}, `}
            {!!for_women && `${t("women")}, `}
            {!!for_couples && `${t("couples")}, `}
            {!!for_groups && t("groups")}
          </h3>
        ) : null}

        {!!services.length
          ? sortedCategories.map((service, index) => {
              return (
                <Fragment key={index}>
                  <p className={"fz-18 fz-16-mob d-block color-main"}>
                    {service.category_name}
                  </p>

                  <ul className="model__serfices-box fz-15 fz-13-mob color-main">
                    {service.services.map((item, index) => {
                      let clazz = item.tag
                        ? { className: `${item.tag ? " _active" : ""}` }
                        : null;

                      let price = String(item?.additional_cost);

                      return (
                        <li {...clazz} key={index}>
                          {item.name}


                            <span className={"color-600"}>
                              {!!item.additional_cost &&
                                !!price &&
                                ` +${price?.substring(0, price.length - 3)} €`}
                              {!!item.note && (
                                <>
                                  {" "}
                                  <br /> {item.note}
                                </>
                              )}
                            </span>
                          
                        </li>
                      );
                    })}
                  </ul>
                </Fragment>
              );
            })
          : null}

        {!!preferences && <ShowMoreText param={'preferences'}>{preferences}</ShowMoreText>}

      </>
    );
  };

  if (!setOpen) {
    return <Main />;
  }

  return !!gender ||
    !!for_couples ||
    !!for_men ||
    !!for_women ||
    !!for_groups ||
    !!services?.length ||
    !!preferences?.length  ? (
    <Main />
  ) : (
    <ZeroZone
      mainText={t("addad") + " " + t("services").toLowerCase()}
      setOpen={setOpen}
      disc={25}
    />
  );
};
export default ModelServices;
