import { object, ref, string } from "yup";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setRegistrationStatus,
  getRegistrationStatus,
  setAuthorizationStatus,
} from "@/stores/slices/popupSlice";
import { Button, Icon, InputInLabel } from "@/components/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { setUserId, setUserType } from "@/stores/slices/userSlice";
import axios from "axios";
import { useState } from "react";
import baseUrl from "@/services/apiConfig";
import { Checkbox } from "../../ui";
import useLadyService from "@/services/LadyService";
import {Popup} from "../index";

const RegistrationPopup = (props) => {
  const { dataName } = props;

  const { dispatch, navigate, lang, t } = useLadyService();

  const [formErrors, setFormErrors] = useState({});
  const [phoneValue, setPhoneValue] = useState("");

  const formSchema = object().shape({
    email: string().email().required(t("emailisnes")),
    phone: string().test(
      "phone-is-empty",
      t("phonenumerrequired"),
      function () {
        return !!phoneValue.replace("+", "");
      }
    ),
    password: string().min(8, t("min8")).required(),
    password_confirmation: string()
      .min(8, t("min8"))
      .required()
      .oneOf([ref("password")], t("passdonot")),
  });

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
    clearErrors,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const onClose = () => {
    reset();
    setPhoneValue("");
    dispatch(setRegistrationStatus(false));
    setFormErrors({});
  };
  const registrationState = useSelector(getRegistrationStatus);

  const changeText = () => {
    switch (dataName){
      case 'indi':
        return t("indireg")

      case 'agency':
        return t("agenci1")

      case 'photograph':
        return t("photographer")

       default :
        return t("client");
    }
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    if (!phoneValue) {
      return;
    }

    axios
      .post(`${baseUrl}auth/register`, {
        user_type: dataName,
        phone: phoneValue.replace(/[" "+]/g, ""),
        email: data.email,
        password: data.password,
      })
      .then((response) => {
        if (response.status === 201) {
          axios
            .post(`${baseUrl}auth/login`, {
              identity: data.email,
              password: data.password,
            })
            .then((response) => {
              if (response.status === 200) {
                const token = response.data.token;
                localStorage.setItem("user", token);
                axios
                  .request({
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                    method: "GET",
                    url: `${baseUrl}user/me`,
                  })
                  .then((response) => {
                    if (response.status === 200) {
                      const userType = response.data.data.user_type;
                      const userId = response.data.data.id;
                      dispatch(setUserId(userId));
                      if (userType) {
                        dispatch(setUserType(userType));

                        if (userType === "indi") {
                          navigate(`/${lang}/lk/${userType}/profiles`);
                        } else if (userType === "customer") {
                          navigate(`/${lang}/lk/${userType}#tariffs`);
                        } else {
                          navigate(`/${lang}/lk/${userType}`);
                        }
                      }

                      try {
                        const currentToken = localStorage.getItem("user");

                        const arrFavorites = JSON.parse(
                          localStorage.getItem("favorites")
                        );

                        if (!!arrFavorites.length) {
                          axios
                            .request({
                              headers: {
                                Authorization: `Bearer ${currentToken}`,
                              },
                              method: "POST",
                              url: `${baseUrl}user/favorites/import`,
                              data: {
                                profiles: arrFavorites,
                              },
                            })
                            .then((response) => {
                              if (response.status === 200) {
                                localStorage.removeItem("favorites");
                              }
                            });
                        }
                      } catch (error) {}
                    }
                  })
                  .catch((error) => {
                    console.error(error);
                  });
                onClose();
              }
            })
            .catch((error) => {});
        }
      })
      .catch((error) => {
        const errorStatus = error.response.status;
        if (errorStatus === 400) {
          setFormErrors({
            login: {
              type: "is-email-or-phone",
              message: t("userexist"),
            },
          });
        }
      });
  };

  const navigateToAuthorisation = () => {
    reset();
    dispatch(setAuthorizationStatus(true));
    dispatch(setRegistrationStatus(false));
  };

  const [checked, setChecked] = useState(false);
  const onChangeTherms = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <Popup
      id={"registration"}
      open={!!registrationState}
      setOpen={onClose}
      onSubmit={handleSubmit(onSubmit)}
      clazz={'gap-12'}
    >
        <div className="popup-form__inner text-center gap-0">
          <h2 className="popup-form__title">{t("registration")}</h2>
          <span className="title_h3">{changeText()}</span>
        </div>
        <div className="popup-form__inner">
          <InputInLabel
            clazz={`${errors.email ? " error" : ""}`}
            id={"loginRegistration"}
            type={"email"}
            placeholder={"example@google.com"}
            register={{ ...register("email")}}
            autocomplete={"email"}
            onChange={() => {
              if (errors.email) {
                clearErrors("email");
              }
              if (formErrors?.login?.message) {
                setFormErrors({});
              }
            }}
          >
            {(errors.email && (
              <span className={"input-label__error"}>
                {errors.email.message}
              </span>
            )) || <span>Email</span>}
          </InputInLabel>

          <InputInLabel
            clazz={`${errors.phone ? " error" : ""}`}
            id={"phoneRegistration"}
            title={t("pass")}
            type={"tel"}
            register={{ ...register("phone") }}
            autocomplete={"tel"}
            maskValue={phoneValue}
            setMaskValue={setPhoneValue}
            onChange={() => {
              if (errors.phone) {
                clearErrors("phone");
              }
              if (formErrors?.login?.message) {
                setFormErrors({});
              }
            }}
          >
            {(errors.phone && (
              <span className={"input-label__error"}>
                {errors.phone.message}
              </span>
            )) || <span>{t("phonenumber")}</span>}
          </InputInLabel>

          <InputInLabel
            clazz={`${errors.password ? " error" : ""}`}
            id={"passRegistration"}
            title={t("pass")}
            type={"password"}
            placeholder={"**********"}
            register={{ ...register("password") }}
            autocomplete={"new-password"}
            onChange={() => {
              if (errors.password) {
                clearErrors("password");
              }
              if (formErrors?.login?.message) {
                setFormErrors({});
              }
            }}
          />

          <InputInLabel
            clazz={`${errors.password_confirmation ? " error" : ""}`}
            id={"passConfirmRegistration"}
            title={t("repeatpassword")}
            type={"password"}
            placeholder={"**********"}
            register={{ ...register("password_confirmation") }}
            autocomplete={"new-password"}
            onChange={() => {
              if (errors.password_confirmation) {
                clearErrors("password_confirmation");
              }
              if (formErrors?.login?.message) {
                setFormErrors({});
              }
            }}
          />

          {(formErrors.login || errors.password_confirmation) && (
            <span className={"popup-form__error"}>
              {formErrors.login?.message ||
                errors.password_confirmation.message}
            </span>
          )}

          <p className={"m-0 p3"}>
            <Checkbox
              id={"agreeWithTherms"}
              clazz={"width-max"}
              checked={checked}
              onChange={onChangeTherms}
              title={t("iagree")}
            />{" "}
            <Link
              target={"_blank"}
              className={"d-inline"}
              to={`/${lang}/rules`}
            >
              {t("termsofserviceagree")}
            </Link>{" "}
            {t("and")}{" "}
            <Link target={"_blank"} className={"d-inline"} to={`/${lang}/gdpr`}>
              {t("privacypolicy")}
            </Link>
          </p>

          <Button
            disabled={!checked}
            size={"l-forever"}
            buttonType={"submit"}
            clazz={"button--green"}
          >
            {t("register")}
          </Button>
        </div>

        <hr />
        <div className="popup-form__box p-0 popup-form__bottom-link">
          <span className={"text-center fz-12 color-500"}>
            {t("haveaccount")}
          </span>
          <button type={"button"} onClick={() => navigateToAuthorisation()}>
            {t("login")}
          </button>
        </div>
    </Popup>
  );
};
export default RegistrationPopup;
