import {useSelector} from "react-redux";
import {getCountriesData, getUserCurrentCity} from "@/stores/slices/userSlice";
import React, {useState} from "react";
import {SelectCountry} from "@/components/ui";

const SelectCity = () => {
  const countriesData = useSelector(getCountriesData);
  const [currentActive, setCurrentActive] = useState("");
  const currentCity = useSelector(getUserCurrentCity);

  return(
    <SelectCountry
      data={countriesData}
      name={"city-create"}
      clazz={`button button-xs select-city`}
      arrowSize={"m"}
      type={"flag"}
      cutTitle
      defaultTitle={currentCity.title}
      currentActiveIndex={currentActive}
      isMain={true}
      activeCountry={currentCity.icon}
      setActive={setCurrentActive}
    />
  )
};

export default SelectCity