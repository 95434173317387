import {
  setAuthorisationAccessType,
  setAuthorizationStatus,
  setStoriesMap,
} from "@/stores/slices/popupSlice";
import { Burger, Button, ButtonLink, Icon } from "@/components/ui";
import { useSelector } from "react-redux";
import {
  getFavoriteCounterStatus,
  getUserTickets,
} from "@/stores/slices/userSlice";
import { show, showShort } from "@/stores/slices/showFilterSlice";
import useLadyService from "@/services/LadyService";
import { getProfilesCount } from "@/stores/slices/mapModelSlice";
import {
  getBurgerMenu,
  setBurgerMenu,
} from "@/stores/slices/headerSlice";
import {LogOutButton, TopLinksTabsObj} from "./index";

const BurgerMenu = ({ lk }) => {
  const { dispatch, userType, t, lang, navigate, userBalance } = useLadyService();

  const favoriteCount = useSelector(getFavoriteCounterStatus);
  const profilesCountValue = useSelector(getProfilesCount);
  const burgerMenuState = useSelector(getBurgerMenu);
  const userTickets = useSelector(getUserTickets);

  const closeMenu = () => {
    dispatch(setBurgerMenu(false));
  };

  const toLk = (key) => {
    navigate(`/lk/${key}`);
    closeMenu()
  };

  const showFilter = () => {
    closeMenu();
    dispatch(show());
  };

  const showShortFilter = () => {
    closeMenu();
    dispatch(showShort());
  };

  let defaultIcons = [
      {
        icon: "home-main",
        name: t("mainpage"),
        link: "/",
      },
      {
        icon: "catalog",
        name: t("catalogue"),
        onClick: () => showShortFilter(),
      },
      {
        icon: "filter",
        name: t("filter"),
        onClick: () => showFilter(),
      },
      {
        icon: "map",
        name: t("onmap"),
        onClick: () => profilesCountValue && dispatch(setStoriesMap(true)),
        disabled: profilesCountValue === 0,
      },
      {
        icon: "favorite",
        clazz: `user__favorite${favoriteCount <= 0 ? ' empty' : ''}`,
        name: t("favorites"),
        counter: favoriteCount,
        link: "/history",
      },
      {
        icon: "person",
        name: t("profuser"),
        onClick:
          userType === "default"
            ? () => dispatch(setAuthorizationStatus(true))
            : () => toLk(userType),
      },
    ]

  let wallet = {
    name: t("balance"),
    link: "/lk/balance",
    wallet: userBalance,
  }

  let support = {
    icon: "operator",
    name: t("support"),
    link: "/lk/support",
    counter: userTickets > 0 ? userTickets : null,
  }

  let instruction = {
    icon: "instruction-cont",
    name: t("guide"),
    link: `/guide`,
  }

  let photographer = {
    icon: "camera",
    name: t("photographer"),
    link: "/lk/photograph",
  }

  let videochat = {
    icon: "video",
    name: t("videochat"),
    link: `/lk/${userType}/videochats`,
  }

  let statistic = {
    icon: "stat",
    name: t("mystat"),
    link: `/lk/${userType}/statistic`,
    counter: userTickets > 0 ? userTickets : null,
  }

  let reviews = {
    icon: "star",
    name: t("reviews"),
    link: `/lk/${userType}/reviews`,
  }

  let profiles = {
    icon: "user-id",
    name: t("myads"),
    link: `/lk/${userType}/profiles`,
  }

  let orders = {
    icon: "pay-content",
    name: t("myorder"),
    link: `/lk/${userType}/reservations`,
  }

  const headerIcons = {
    indi: [
        profiles,
        videochat,
        orders,
        reviews,
        statistic,
        wallet,
        support,
        photographer,
        instruction,
      ],
    agency: [
      videochat,
      orders,
      {
        icon: "casting",
        name: t("demos"),
        link: "#",
      },

      profiles,
      reviews,
      support,
      {
        icon: "party",
        name: t("myparty"),
        link: "#",
      },
      statistic,
      wallet,
      photographer,
      instruction,
    ],
    customer_photographer: [
        {
          icon: "erocoin-leg",
          name: t("about"),
          link: `/${lang}/about`,
          onClick: () => closeMenu(),
        },
        {
          icon: "filter2",
          name: t("myfilters"),
          link: `/lk/${userType}/subscriptions`,
        },
        {
          icon: "time",
          name: t("history"),
          link: `/history`,
        },

        wallet,

        instruction,

        support,
      ],
  };

  const iconsByUserType = () => {
    switch (userType){
      case 'indi':
        return headerIcons.indi

       case 'agency':
        return headerIcons.agency

      default:
        return headerIcons.customer_photographer
    }
  };

  let linksByType = () => {
    switch (userType) {
      case "customer":
        return TopLinksTabsObj().customer;
      case "photograph":
        return TopLinksTabsObj().photograph;
      default:
        return TopLinksTabsObj().default;
    }
  };

  let buttonTemplate = ({ i, index }) => {
    let propses = {
      key: index + 1,
      title: i.name,
      size: "l",
      href: i.link,
      onClick: i.onClick ? i.onClick : () => closeMenu(),
      disabled: i.disabled,
      clazz: `menu__link${i.clazz ? ` ${i.clazz}` : ""}`,
    };

    let propsesIcon = {
      counter: i.wallet ? null : i.counter,
      size: "xl",
      clazz: i.clazz,
      spritePath: i.icon,
    };

    if (i.link) {
      return (
        <ButtonLink {...propses}>
          {i.wallet ? (
            <span className={"menu__wallet currency--erocoin"}>{i.wallet}</span>
          ) : (
            <Icon {...propsesIcon} />
          )}
          <span>{i.name}</span>
        </ButtonLink>
      );
    } else {
      return (
        <Button {...propses}>
          <Icon {...propsesIcon} />
          <span>{i.name}</span>
        </Button>
      );
    }
  };

  return (
    <div className={`users${!!burgerMenuState ? " _active" : ""}`}>
      <Burger />

      <div className={"users__nav"}>
        {defaultIcons.map((i, index) => buttonTemplate({ i, index }))}
      </div>

      {userType !== "default" && (
        <>
          <hr/>

          <div className={"users__nav"}>
            {iconsByUserType().map((i, index) => buttonTemplate({i, index}))}
          </div>
        </>
      )}

      {userType === "default" ? (
        <div className="users__links">
          {linksByType().map((i, index) => {
            if (i.name === t("support") && userType === "default") {
              return (
                <ButtonLink
                  onClick={() => {
                    dispatch(setAuthorisationAccessType("support"));
                    dispatch(setAuthorizationStatus(true));
                  }}
                  href={i.link}
                  title={i.name}
                  key={index + 1}
                  children={i.name}
                  clazz={"hover-line"}
                >
                  {i.name}
                </ButtonLink>
              );
            }

            return (
              <ButtonLink
                onClick={() => closeMenu()}
                href={i.href}
                title={i.name}
                key={index + 1}
                children={i.name}
                clazz={"hover-line"}
              />
            );
          })}
        </div>
      ) : null}

      <div className="users__bottom">
        {lk && (
          <ButtonLink
            title={t("backtoweb")}
            href={"/"}
            onClick={closeMenu}
            size={"m"}
            clazz={"button--tetriary td-none width-100"}
          >
            {t("backtoweb")}
          </ButtonLink>
        )}

        {userType !== "default" && <LogOutButton size={"m"}/>}
      </div>
    </div>
  );
};

export default BurgerMenu;
