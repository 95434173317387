import GuideIndi from "./indi/GuideIndi";
import { useEffect, useState } from "react";
import useTitle from "../../../hooks/useTitle";
import GuideDefault from "./default/GuideDefault";
import useLadyService from "@/services/LadyService";
import GuideCustomer from "./customer/GuideCustomer";
import { useLocation } from "react-router-dom";
import {Loader} from "../../ui";
import "./guidePage.scss";

const Guide = () => {
  const { userType, windowWidth, t, scrollToTop, scrollToHash } = useLadyService();
  const { hash } = useLocation();

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [scrolledClass, setScrolledClass] = useState("");
  useEffect(() => {
    if (windowWidth < 767.98) {
      window.addEventListener("scroll", () => {
        if (window.scrollY < 140) {
          setScrolledClass("");
        } else {
          setScrolledClass(" _fixed");
        }
      });
    }
  }, [windowWidth, window]);

  useEffect(() => {
    if(!hash) {
      scrollToTop()
    } else {
      scrollToHash()
    }
  }, [hash]);

  useEffect(() => {
    setIsLoading(true)
  }, []);

  useTitle(t("guide"));

  let propses = {
    scrolledClass: scrolledClass,
    show: show,
    setShow: setShow,
  };

  switch (userType) {
    case "indi":
      return isLoading ? <GuideIndi {...propses} /> : <Loader/>;
      break;

    case "customer":
      return isLoading ? <GuideCustomer {...propses} /> : <Loader/>;
      break;

    default:
      return isLoading ? <GuideDefault {...propses} /> : <Loader/>;
  }
};

export default Guide;
