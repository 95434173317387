import { useTranslation } from "react-i18next";

import { Button, InputInLabel } from "@/components/ui";

import { object, ref, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { makeRequest } from "@/services/makeRequest";

import showToast from "../../toast/Toast";

import { useState } from "react";

import Popup from "../Popup";

const ChangePasswordPopup = ({open, setOpen}) => {

  const { t } = useTranslation("translation");

  const [isLoading, setIsLoading] = useState(false);

  const [errorCurrent, setErrorCurrent] = useState("");

  const formSchema = object().shape({
    old_password: string().min(8, t("min8")).required(),
    new_password: string().min(8, t("min8")).required(),
    password_confirmation: string()
      .min(8, t("min8"))
      .required()
      .oneOf([ref("new_password")], t("passdonot")),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    setIsLoading(true);

    const { old_password, new_password } = data;

    const fetchData = async () => {
      try {
        const route = `user/change-password`;
        const method = "PUT";
        const payload = { current_password: old_password, new_password };

        await makeRequest({ route, method, payload });
        reset();
        setOpen(false);
        showToast({
          message: t("pasupdate"),
          variant: "success",
        });
      } catch (error) {
        const res = error.response;

        switch (res.status) {
          case 401: {
            showToast({
              message: t("noauth"),
              variant: "error",
            });
            break;
          }
          default:
          case 422: {
            setErrorCurrent(t("currentpasdecline"));
            break;
          }
        }
      }
    };

    fetchData();
  };

  const handleCloseDelete = () => {
    reset();
    setOpen(false);
  };

  return (
    <Popup
      open={open}
      setOpen={handleCloseDelete}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
      id="changePassword"
    >
      <div className="popup-form__inner">
        <h3 className="m-12-24">{t("changepass")}</h3>

        <InputInLabel
          clazz={`${!!errorCurrent ? " error" : ""}`}
          id={"oldPassword"}
          title={t("oldpass")}
          type={"password"}
          placeholder={"**********"}
          register={{...register("old_password")}}
        />
        {!!errorCurrent && (
          <span className={"popup-form__error"}>{errorCurrent}</span>
        )}
        <InputInLabel
          clazz={`${errors.password_confirmation ? " error" : ""}`}
          id={"newPassword"}
          title={t("newpas")}
          type={"password"}
          placeholder={"**********"}
          register={{...register("new_password")}}
          autocomplete={"new-password"}
        />
        <InputInLabel
          clazz={`${errors.password_confirmation ? " error" : ""}`}
          id={"confirmationNewPassword"}
          title={t("repeatpas")}
          type={"password"}
          placeholder={"**********"}
          register={{...register("password_confirmation")}}
          autocomplete={"new-password"}
        />
        {errors.password_confirmation && (
          <span className={"popup-form__error"}>
          {errors.password_confirmation.message}
        </span>
        )}

        <Button
          size={"l-forever"}
          buttonType={"submit"}
          clazz={"button--green justify-center"}
        >
          {t("save")}
        </Button>
      </div>
    </Popup>
  );
};

export default ChangePasswordPopup;
