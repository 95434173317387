import useLadyService from "@/services/LadyService";

const TopLinksTabsObj = () => {
  const { t, userBalance} = useLadyService();
  return{
    default: [
      { id: 1, name: t("about"), href: `/about`, count: 0 },
      { id: 2, name: t("guide"), href: `/guide`, count: 0 },
      // { id: 3, name: t("faq"), href: `/faq`, count: 0 },
      { id: 4, name: t("support"), href: `/lk/support`, count: 0 },
    ],
    photograph: [
      { id: 1, name: t("profuser"), href: "#", count: 0 },
      { id: 2, name: t("photo"), href: "#", count: 0 },
      { id: 3, name: t("videofotograph"), href: "#", count: 0 },
      { id: 4, name: t("interior"), href: "#", count: 0 },
      { id: 5, name: t("reviews"), href: "#", count: 0 },
    ],
    customer: [
      { id: 1, name: t("profuser"), href: `/lk/customer` },
      {
        id: 2,
        name: t("subandfilters"),
        href: `/lk/customer/subscriptions`,
        count: 0,
      },
      { id: 3, name: t("history"), href: `/history`, count: 0 },
      {
        id: 4,
        name: t("balance"),
        href: `/lk/balance`,
        count: 0,
        wallet: userBalance,
      },
    ],
    agency: [
      { id: 1, name: t("profuser"), href: `#` },
      { id: 2, name: t("myads"), href: `#`, count: 0 },
      { id: 3, name: t("reviews"), href: `#`, count: 0 },
      { id: 3, name: t("myoperator"), href: `/lk/support` },
      { id: 3, name: t("myparty"), href: `#` },
      { id: 3, name: t("mystat"), href: `#` },
      { id: 4, name: t("balance"), href: `#`, wallet: userBalance },
    ],
    agency_lk: [
      { id: 1, name: t("profuser"), href: "#" },
      { id: 2, name: t("myads"), href: "#", count: 0 },
      { id: 3, name: t("reviews"), href: "#", count: 0 },
      { id: 3, name: t("myoperator"), href: "#" },
      { id: 3, name: t("myparty"), href: "#" },
      { id: 3, name: t("mystat"), href: "#" },
      { id: 4, name: t("balance"), href: "#", wallet: 20 },
    ],
    indi: [
      { id: 1, name: t("profuser"), href: "/lk/indi" },
      { id: 2, name: t("myads"), href: "/lk/indi/profiles", count: 0 },
      { id: 3, name: t("reviews"), href: "/lk/indi/reviews", count: 0 },
      { id: 4, name: t("mystat"), href: "/lk/indi/statistic" },
      { id: 5, name: t("balance"), href: "/lk/balance", wallet: userBalance },
    ],
    indi_lk: [
      { id: 1, name: t("profuser"), href: "/lk/indi" },
      { id: 2, name: t("myads"), href: "/lk/indi/profiles" },
      { id: 3, name: t("reviews"), href: "/lk/indi/reviews" },
      { id: 4, name: t("mystat"), href: "/lk/indi/statistic" },
      { id: 5, name: t("balance"), href: "/lk/balance", wallet: userBalance },
    ]
  }
};

export default TopLinksTabsObj