import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import PhotoUpload from "../UploadZone";
import { getIdsFromUrls } from "../changeOrder/changeOrder";

export const Interior = ({ data, setData, show, formData, setFormData }) => {
  const movePhoto = (dragIndex, hoverIndex) => {
    const draggedPhoto = data.files[dragIndex];
    const newPhotos = [...data.files];
    newPhotos.splice(dragIndex, 1);
    newPhotos.splice(hoverIndex, 0, draggedPhoto);
    setData({ files: newPhotos });
    setFormData(prev => ({
      ...prev,
      media: ({
        ...prev.media,
        interior: newPhotos.map(item => item.preview)
      })
    }))
    getIdsFromUrls(newPhotos, formData.slug);
  };
  return (
    <DndProvider backend={HTML5Backend}>
      {show === "interior" && (
        <PhotoUpload
          movePhoto={movePhoto}
          data={data}
          setData={setData}
          accept={{
            "image/jpeg": [".jpg", ".jpeg", ".JPG", ".JPEG"],
            "image/png": [".png", ".PNG"],
            "image/webp": [".webp", ".WEBP"],
          }}
          type="photo"
          addText={false}
          mode={"interior"}
          maxSize={10 * 1024 * 1024}
          limit={20}
          formData={formData}
          setFormData={setFormData}
        />
      )}
    </DndProvider>
  );
};
