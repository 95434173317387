import Footer from "../../footer/Footer";
import Helmet from "react-helmet";
import { Favicon } from "@/components/ui";
import { Outlet } from "react-router-dom";
import Header from "../../header/Header";

const AgencyLayoutLk = ({ titleParams, postDesc }) => {
  return (
    <>
      <Favicon />
      <Helmet>
        <title>{titleParams}</title>
        <meta name="description" content={postDesc} />
      </Helmet>

      <Header lk />

      <Outlet />

      <Footer />
    </>
  );
};

export default AgencyLayoutLk;
