import React from "react";
import {Select} from "@/components/ui";
import useLadyService from "@/services/LadyService";

const DropdownLanguage = ({size = "xs"}) => {
  const { city } = useLadyService();

  const languages = [
    { id: 1, link: `/en/${city}`, title: "EN", value: "en" },
    { id: 2, link: `/ru/${city}`, title: "RU", value: "ru" },
    { id: 3, link: `/cz/${city}`, title: "CZ", value: "cz" },
  ]

  return(
    <Select
      options={languages}
      size={size}
      arrowSize={"s"}
      sorted={true}
      name={"language"}
    ></Select>
  )
};

export default DropdownLanguage