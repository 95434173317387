import { useCallback, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import {
  Button,
  Checkbox,
  Icon,
  InputInLabel,
  Loader,
  Select,
} from "@/components/ui";
import { useForm } from "react-hook-form";

import Helmet from "react-helmet";

import "./tarifs.scss";
import { makeRequest } from "@/services/makeRequest";
import useLadyService from "@/services/LadyService";
import { LoadingButton } from "../../../../../../ui";
import showToast from "../../../../../../toast/Toast";
import { number, object } from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import {Popup} from "../../../../../../popups";

const MainPart = ({
  setActiveCheckbox,
  activeCheckbox,
  addTarif,
  tarifsRow,
  ButtonTablaHead,
  TableHeadRow,
  disabledRowArr,
  chooseAll,
  dataDays,
  TimeRow,
  isActiveIndex,
  isActiveIndex24,
  setIsActiveIndex,
  setIsActiveIndex24,
  register,
  handleChangeDis,
  handleChangeDisUs,
  getValues,
  setValue,
  setOpen,
  errors,
  happyHours,
  otherDiscount,
}) => {
  const { t } = useLadyService();

  return (
    <>
      <div className={`d-flex fd-column`}>
        <h3>{t("tarifftypes")}</h3>
        <span className="mb-16">{t('tarifftdesc')}</span>
        <div className="d-flex fd-column gap-8">
          <div className="form-tarifs__table-head">
            <span>{t("time")}</span>
            <ButtonTablaHead
              setData={setActiveCheckbox}
              data={activeCheckbox}
              value={"incall"}
              subTitle={t("incall")}
            />
            <ButtonTablaHead
              setData={setActiveCheckbox}
              data={activeCheckbox}
              value={"outcall"}
              subTitle={t("outcall")}
            />
            <ButtonTablaHead
              setData={setActiveCheckbox}
              data={activeCheckbox}
              value={"out"}
              subTitle={
                <span className="form-tarifs____title">
                  <span>{t("acompany")}</span>
                  <span className="d-none-tablet">{`${
                    "(" + t("nosex").toLowerCase() + ")"
                  }`}</span>
                </span>
              }
            />
          </div>
          <TableHeadRow
            dataRow={tarifsRow}
            data={activeCheckbox}
            setData={setActiveCheckbox}
          />
          <Button
            size={"l"}
            clazz={"button_outline--green-accent width-fit"}
            onClick={() => addTarif()}
          >
            {t("addtariff")}
          </Button>
        </div>
        <div className={"form-tarifs__daysroot"}>
          <div className="d-flex gap-12 fd-column">
            <div className="d-flex gap-12 justify-sb align-baseline">
              <h3>{t("workhours")}</h3>
              <Checkbox
                title={t("selectall")}
                disabled={false}
                checked={disabledRowArr.length === 7}
                onClick={chooseAll}
              />
            </div>
            <div className="d-flex gap-16 fd-column">
              {dataDays.map((day, index) => {
                return (
                  <TimeRow
                    data={isActiveIndex}
                    setData={setIsActiveIndex}
                    data24={isActiveIndex24}
                    setData24={setIsActiveIndex24}
                    day={day}
                    index={index}
                    key={day.title}
                  />
                );
              })}
            </div>
          </div>

          <div className="d-flex fd-column gap-8">
            <h3>{t("discounts")}</h3>
            <div className="form-tarifs__discounts">
              <span>
                {t("discount")}
                <span className="green ml-8">Happy Hours</span>
              </span>
              <InputInLabel
                clazz={`${errors.discount ? " error" : ""}`}
                type={"number"}
                id={"create-discount"}
                // required={isReqDiscount}
                placeholder={"10%"}
                onChange={(e) => handleChangeDis(e)}
                register={{ ...register("discount") }}
                value={!!happyHours ? happyHours?.discount : ""}
              >
                {(errors.discount && (
                  <span className={"input-label__error"}>
                    {errors.discount.message}
                  </span>
                )) || <span>{t("discountamount")}</span>}
              </InputInLabel>
              <div className="form-tarifs__discounts-ofday">
                <span>{t("valid")}</span>
                <div className="d-flex gap-12">
                  <InputInLabel
                    id={`orderTimeFrom-discount`}
                    placeholder={"11:00"}
                    type={"time"}
                    register={{ ...register(`workTime_discount_from`) }}
                    // onChange={(e) => handleChangeTime(e, "from")}
                  >
                    {t("from")}
                  </InputInLabel>
                  <InputInLabel
                    id={`orderTimeTo-discount`}
                    placeholder={"18:00"}
                    type={"time"}
                    register={{ ...register(`workTime_discount_to`) }}
                    // onChange={(e) => handleChangeTime(e, "to")}
                  >
                    {t("until")}
                  </InputInLabel>
                </div>
              </div>
            </div>
            <div className="form-tarifs__discounts">
              <InputInLabel
                type={"text"}
                id={"create-regular"}
                placeholder={t("regulars")}
                // required={isReqDiscountUs}
                onChange={(e) => {
                  const oldValue = getValues("discount-us");
                  setValue("discount-us", {
                    discount: (oldValue || {}).discount || 0,
                    description: e.target.value,
                  });
                  // setIsReqDiscountName(!!e.target.value);
                }}
                register={{ ...register("regular") }}
              >
                {t("discountname")}
              </InputInLabel>
              <InputInLabel
                clazz={`${errors["discount-usual"] ? " error" : ""}`}
                type={"number"}
                id={"create-discount-usual"}
                placeholder={"10%"}
                // required={isReqDiscountName}
                onChange={(e) => handleChangeDisUs(e)}
                value={!!otherDiscount ? otherDiscount?.discount : ""}
                register={{ ...register("discount-usual") }}
              >
                {(errors["discount-usual"] && (
                  <span className={"input-label__error"}>
                    {errors["discount-usual"].message}
                  </span>
                )) || <span>{t("discountamount")}</span>}
              </InputInLabel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const PopupButtons = ({ setOpen, isLoading }) => {
  const { t } = useLadyService();
  return (
    <div className="create-form__buttons">
      <Button
        size={"l-forever"}
        square={false}
        clazz="button--secondary min-w-160"
        onClick={() => setOpen(false)}
      >
        {t("cancel")}
      </Button>
      <LoadingButton
        buttonType={"submit"}
        size={"l-forever"}
        square={false}
        clazz="button--green min-w-160"
        isLoading={isLoading}
      >
        {t("save")}
      </LoadingButton>
    </div>
  );
};

const DefaultButtons = ({ backStep, isLoading }) => {
  const { t } = useLadyService();

  return (
    <div className="create-form__buttons">
      <Button
        size={"l-forever"}
        square={false}
        clazz="button--secondary min-w-160"
        onClick={() => backStep()}
      >
        {t("back")}
      </Button>
      <LoadingButton
        buttonType={"submit"}
        size={"l-forever"}
        square={false}
        clazz="button--green min-w-160"
        isLoading={isLoading}
      >
        {t("continue")}
      </LoadingButton>
    </div>
  );
};

const fakeArray = [0, 1, 2, 3, 4, 4, 5, 6];

const TarifsGlobal = ({
  open,
  setOpen,
  formData,
  setFormData,
  setAvailableSteps,
  changeStep,
  rootElem,
  setFilled,
}) => {
  const { t, token } = useLadyService();

  const [isLoading, setIsLoading] = useState(false);

  let happyHours = null;
  let otherDiscount = null;

  for (let i = 0; i < formData?.discounts.length; i++) {
    if (formData?.discounts[i].description === "Happy Hours") {
      happyHours = formData?.discounts[i];
    } else {
      otherDiscount = formData?.discounts[i];
    }
  }

  const timeOption = [
    { title: `15 ${t("min")}`, duration: "15", value: "15" },
    { title: `30 ${t("min")}`, duration: "30", value: "30" },
    { title: `1 ${t("hour")}`, duration: "60", value: "1" },
    { title: `2 ${t("hourfew")}`, duration: "120", value: "2" },
    { title: `3 ${t("hourfew")}`, duration: "180", value: "3" },
    { title: `4 ${t("hourfew")}`, duration: "240", value: "4" },
    { title: `6 ${t("hourmany")}`, duration: "360", value: "6" },
    { title: `8 ${t("hourmany")}`, duration: "480", value: "8" },
    { title: `12 ${t("hourmany")}`, duration: "720", value: "12" },
    { title: `1 ${t("days")}`, duration: "1440", value: "1" },
    { title: `2 ${t("days")}`, duration: "2880", value: "2" },
    { title: `5 ${t("days")}`, duration: "7200", value: "5" },
    { title: `7 ${t("days")}`, duration: "10080", value: "7" },
    { title: `14 ${t("days")}`, duration: "20160", value: "14" },
  ];

  const dataDays = [
    { title: t("mon") },
    { title: t("tue") },
    { title: t("wed") },
    { title: t("thu") },
    { title: t("fri") },
    { title: t("sat") },
    { title: t("sun") },
  ];

  // const formSchema = object().shape({
  //   discount: number()
  //     .transform((value, originalValue) =>
  //       originalValue === "" ? undefined : value
  //     )
  //     .nullable()
  //     .required(t("required"))
  //     .max(100, `${t("maxval")} 100`)
  //     .nullable(true),
  //   "discount-usual": number()
  //     .transform((value, originalValue) =>
  //       originalValue === "" ? undefined : value
  //     )
  //     .nullable()
  //     .required(t("required"))
  //     .max(100, `${t("maxval")} 100`)
  //     .nullable(true),
  // });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    // resolver: yupResolver(formSchema),
  });

  const transformArrayWick = (arr) => {
    return arr.map((item) => ({
      weekday: item.week,
      start_time: item.start_time.substring(0, 5),
      end_time: item.end_time.substring(0, 5),
      is24:
        item.is24 === 1 ||
        (item.start_time.substring(0, 5) === "00:00" &&
          item.end_time.substring(0, 5) === "00:00")
          ? true
          : false,
    }));
  };

  const newWickData = !!formData?.work_time.length
    ? transformArrayWick(formData.work_time)
    : [];

  const convertObjectToArrayWithId = (obj) => {
    const result = [];

    for (const key in obj) {
      obj[key].forEach((item) => {
        result.push({
          ...(item?.id && { id: item.id }),
          duration: item.duration,
          type: key === "incall" ? 1 : key === "outcall" ? 2 : 3,
          price: item.price,
        });
      });
    }
    return result;
  };

  const newDataPrices = !!Object.values(formData?.prices).length
    ? convertObjectToArrayWithId(formData?.prices)
    : [];

  // const arrActiveIndex =
  //   !!newDataPrices?.length &&
  //   newDataPrices
  //     .map((i) => i.type)
  //     .map((item) => {
  //       if (Number(item) === 1) {
  //         return "incall";
  //       }
  //       if (Number(item) === 2) {
  //         return "outcall";
  //       }
  //       if (Number(item) === 3) {
  //         return "out";
  //       }
  //       return item;
  //     });

  // const formDataPrice = !!arrActiveIndex?.length
  //   ? arrActiveIndex.filter((item, index) => {
  //       return arrActiveIndex.indexOf(item) === index;
  //     })
  //   : [];


  // const [activeCheckbox, setActiveCheckbox] = useState(
  //   !!formDataPrice?.length ? formDataPrice : []
  // );
  const [activeCheckbox, setActiveCheckbox] = useState([]);
  useEffect(() => {
    if(!!Object?.values(formData)) {
      if(formData.incall) {
        setActiveCheckbox(prev => [...prev, 'incall'])
      }
      if(formData.outcall) {
        setActiveCheckbox(prev => [...prev, 'outcall'])
      }
      if(formData.escort) {
        setActiveCheckbox(prev => [...prev, 'out'])
      }
    }
    console.log(activeCheckbox);
  }, [formData])

  const backStep = () => {
    changeStep((prev) => prev - 1);
    rootElem.scrollIntoView({
      behavior: "smooth",
    });
  };

  const ButtonTablaHead = ({ title, subTitle, value, data, setData }) => {
    const toggleChange = (name) => {
      const langIndex = data.findIndex((item) => item === name);

      if (langIndex === -1) {
        setData([...data, name]);
      } else if (langIndex !== -1) {
        const updatedActive = [...data];
        updatedActive.splice(langIndex, 1);
        setData(updatedActive);
      }
    };

    return (
      <Checkbox
        title={title}
        clazz={""}
        subTitle={subTitle}
        value={value}
        checked={data.includes(value)}
        onClick={() => toggleChange(value)}
      />
    );
  };

  const arrForTarifs =
    !!newDataPrices?.length &&
    newDataPrices.reduce((acc, item) => {
      const existingItem = acc.find((i) => i.duration === item.duration);

      if (existingItem) {
        if (Number(item.type) === 1) {
          existingItem.price.incall = item.price;
        }
        if (Number(item.type) === 2) {
          existingItem.price.outcall = item.price;
        }
        if (Number(item.type) === 3) {
          existingItem.price.out = item.price;
        }
      } else {
        acc.push({
          id: item.id,
          duration: item.duration,
          price: {
            incall: Number(item.type) === 1 ? item.price : 0,
            outcall: Number(item.type) === 2 ? item.price : 0,
            out: Number(item.type) === 3 ? item.price : 0,
          },
        });
      }

      return acc;
    }, []);

  const [tarifsRow, setTarifsRow] = useState(
    !!arrForTarifs?.length
      ? arrForTarifs
      : [
          {
            duration: 60,
            price: {
              incall: 0,
              outcall: 0,
              out: 0,
            },
          },
        ]
  );

  const removeTarif = (value) => {
    const newRows = tarifsRow.filter((item) => item.duration !== value);
    setTarifsRow(newRows);
  };

  const addTarif = () => {
    const maxOptionLength = timeOption.length;
    if (tarifsRow.length < maxOptionLength) {
      setTarifsRow((prev) => [
        ...prev,
        {
          duration: timeOption.filter(
            (i) =>
              !tarifsRow
                .map((item) => String(item.duration))
                .includes(String(i.duration))
          )[0]["duration"],
          price: {
            incall: 0,
            outcall: 0,
            out: 0,
          },
        },
      ]);
    }
  };

  const arrForActiveIndex24 = !!newWickData?.length
    ? newWickData.filter((item) => item.is24).map((item) => item.weekday - 1)
    : [];

  const arrForActiveIndex = !!newWickData?.length
    ? newWickData
        .filter((item) => !!item.start_time && !!item.end_time)
        .map((item) => item.weekday - 1)
    : [];

  const [isActiveIndex24, setIsActiveIndex24] = useState(arrForActiveIndex24);
  const [isActiveIndex, setIsActiveIndex] = useState(arrForActiveIndex);

  const TimeRow = ({ day, index, data, setData, data24, setData24 }) => {
    const { t } = useLadyService();
    const isIndexActive = disabledRowArr.includes(index);

    const isChecked = !!data.includes(index);

    const is24Checked = !!data24.includes(index);

    const handleTime = (e, type) => {
      if (!!isChecked) {
        const timeFrom = getValues(`workTime-${index + 1}`)?.start_time;
        const timeTo = getValues(`workTime-${index + 1}`)?.end_time;
        const is24 = getValues(`workTime-${index + 1}`)?.is24;

        if (type === "from") {
          setValue(`workTime-${index + 1}`, {
            weekday: index + 1,
            start_time: e.target.value,
            end_time: timeTo,
            is24: is24,
          });
        } else {
          setValue(`workTime-${index + 1}`, {
            weekday: index + 1,
            start_time: timeFrom,
            end_time: e.target.value,
            is24: is24,
          });
        }
      }
    };

    const handleAllDay = (index, checked) => {
      disabledRow(index);
      let oldValue = [...data24];
      let oldIndex = [...data];

      setValue(`workTime-${index + 1}`, {
        is24: checked,
        weekday: index + 1,
        start_time: checked ? "00:00" : null,
        end_time: checked ? "00:00" : null,
      });

      if (oldValue.includes(index)) {
        const newArr = oldValue.filter((i) => {
          return i !== index;
        });
        setData24(newArr);
      } else {
        oldValue.push(index);
        setData24(oldValue);
      }

      if (!checked) {
        const newArr = oldIndex.filter((i) => i !== index);
        setData(newArr);
      } else {
        oldIndex.push(index);
        setData(oldIndex);
      }
    };

    const toggleActive = (index) => {
      const oldValue = [...data];
      const oldValue24 = [...data24];

      if (oldValue.includes(index)) {
        const newArr = oldValue.filter((i) => i !== index);
        const newArr24 = oldValue24.filter((i) => i !== index);
        setValue(`workTime-${index + 1}`, {
          start_time: null,
          end_time: null,
          is24: false,
        });
        setData(newArr);
        setData24(newArr24);
      } else {
        oldValue.push(index);
        setData(oldValue);
      }
    };

    return (
      <div className="form-tarifs__worktimes--days-item" key={index}>
        <Checkbox
          title={day.title}
          checked={isChecked}
          onClick={() => toggleActive(index)}
        />

        <InputInLabel
          id={`orderTimeFrom${index + 1}`}
          placeholder={"11:00"}
          type={"time"}
          disabled={!isChecked || is24Checked}
          register={{ ...register(`workTime-${index + 1}.start_time`) }}
          clazz={errors[`timeFrom${index + 1}`] ? "error" : ""}
          onChange={(e) => handleTime(e, "from")}
        >
          {t("from")}
        </InputInLabel>

        <InputInLabel
          id={`orderTimeTo${index + 1}`}
          placeholder={"18:00"}
          type={"time"}
          disabled={!isChecked || is24Checked}
          register={{ ...register(`workTime-${index + 1}.end_time`) }}
          clazz={errors[`timeTo${index + 1}`] ? "error" : ""}
          onChange={(e) => handleTime(e, "to")}
        >
          {t("until")}
        </InputInLabel>

        <Checkbox
          title={<span className="display-none-mobile">{t("nonstop")}</span>}
          disabled={false}
          checked={is24Checked}
          icon={"time-plan"}
          iconClazz={"_green"}
          iconSize={"xxxl"}
          onClick={() => handleAllDay(index, !isIndexActive)}
        />
      </div>
    );
  };

  const formDataArrFullDay = !!newWickData?.length
    ? newWickData.filter((i) => i.is24).map((item) => item.id - 1)
    : [];

  const [disabledRowArr, setDisabledRowArr] = useState(formDataArrFullDay);

  const chooseAll = () => {
    if (disabledRowArr.length === 7) {
      setDisabledRowArr([]);
      setIsActiveIndex24([]);
      const fakeArray = [0, 1, 2, 3, 4, 5, 6];
      fakeArray.forEach((_, index) => {
        setValue(`workTime-${index + 1}`, {
          is24: false,
          weekday: index + 1,
          start_time: null,
          end_time: null,
        });
      });
      setIsActiveIndex([]);
    } else {
      setDisabledRowArr([0, 1, 2, 3, 4, 5, 6]);
      setIsActiveIndex24([0, 1, 2, 3, 4, 5, 6]);
      const fakeArray = [0, 1, 2, 3, 4, 5, 6];
      fakeArray.forEach((i) => {
        setValue(`workTime-${i + 1}`, {
          is24: true,
          weekday: i + 1,
          start_time: "00:00",
          end_time: "00:00",
        });
      });
      setIsActiveIndex([0, 1, 2, 3, 4, 5, 6]);
    }
  };

  const disabledRow = (index) => {
    if (disabledRowArr.includes(index)) {
      setDisabledRowArr(disabledRowArr.filter((item) => item !== index));
    } else {
      setDisabledRowArr((prev) => [...prev, index]);
    }
  };

  const TableHeadRow = ({ data, setData, dataRow }) => {
    const [value, setValue] = useState(!!dataRow.length ? dataRow : []);

    const changeValue = (val, duration, typePrice) => {
      const updatedValue = [...value];

      const currentItem = updatedValue.filter(
        (item) => item.duration === duration
      );
      if (!!currentItem.length) {
        currentItem[0].price[typePrice] = Number(val);
        setValue(updatedValue);
      }
    };

    const toggleTime = (value, item) => {
      const { item: itemRes, index: indexRes } = item;
      const index = tarifsRow.findIndex((obj) => obj.id === itemRes.id);

      if (index !== -1) {
        const updatedTarifsRow = [...tarifsRow];

        updatedTarifsRow[indexRes] = {
          ...updatedTarifsRow[indexRes],
          duration: Number(value.duration),
        };

        setTarifsRow(updatedTarifsRow);
      }
    };

    const enablePrice = (type) => {
      if (type === "incall" && !data.includes("incall")) {
        setData((prev) => [...prev, "incall"]);
      }
      if (type === "outcall" && !data.includes("outcall")) {
        setData((prev) => [...prev, "outcall"]);
      }
      if (type === "out" && !data.includes("out")) {
        setData((prev) => [...prev, "out"]);
      }
    };

    return tarifsRow.map((item, index) => {
      return (
        <div className="tarifs-row" key={index}>
          <Select
            name={"tarifs-time"}
            options={timeOption.filter(
              (i) =>
                !tarifsRow
                  .map((item) => String(item.duration))
                  .includes(String(i.duration))
            )}
            defaultTitle={
              tarifsRow[index].duration
                ? timeOption.filter((i) => {
                    return Number(i.duration) === Number(item.duration);
                  })[0]?.title
                : null
            }
            clazz={`select-city`}
            arrowSize={"m"}
            withoutTitle={true}
            postApi={true}
            onChange={toggleTime}
            paramsOnChange={{ item, index }}
          />
          <InputInLabel
            type={"number"}
            price
            id={`create-tarifs-incall--${item.duration}`}
            placeholder={""}
            disabled={!data.includes("incall")}
            value={value[index]?.price.incall}
            onClick={() => enablePrice("incall")}
            onChange={(e) =>
              changeValue(e.target.value, item.duration, "incall")
            }
            max={9999}
          >
            {t("price")}
          </InputInLabel>
          <InputInLabel
            type={"number"}
            price
            id={`create-tarifs-outcall--${item.duration}`}
            placeholder={""}
            disabled={!data.includes("outcall")}
            onClick={() => enablePrice("outcall")}
            value={value[index]?.price.outcall}
            onChange={(e) =>
              changeValue(e.target.value, item.duration, "outcall")
            }
            max={9999}
          >
            {t("price")}
          </InputInLabel>
          <InputInLabel
            type={"number"}
            price
            id={`create-tarifs-out--${item.duration}`}
            placeholder={""}
            disabled={!data.includes("out")}
            onClick={() => enablePrice("out")}
            value={value[index]?.price.out}
            onChange={(e) => changeValue(e.target.value, item.duration, "out")}
            max={9999}
          >
            {t("price")}
          </InputInLabel>
          <div
            className="tarifs-row-trash"
            onClick={() => removeTarif(item.duration)}
          >
            <Icon size={"xl"} spritePath={"trash"} clazz={"_red"} />
            <span className="form-tarifs__closeTrash">Удалить</span>
          </div>
        </div>
      );
    });
  };

  const checkDiscMain = useCallback(
    debounce((value) => {
      if (value > 100) {
        setError("discount", {
          message: `${t("maxval") + " 100"}`,
        });
      }
    }, 1000)
  );

  const handleChangeDis = (e) => {
    const oldValue = getValues("discount-happy");
    const value = parseInt(e.target.value, 10);
    checkDiscMain(value);
    if (value <= 100) {
      clearErrors("discount");
    }
    if (!value) {
      clearErrors("discount");
    }
    setValue("discount-happy", {
      discount: value,
      from: (oldValue || {}).from || "",
      to: (oldValue || {}).to || "",
    });
  };

  const checkDisc = useCallback(
    debounce((value) => {
      if (value > 100) {
        setError("discount-usual", {
          message: `${t("maxval") + " 100"}`,
        });
      }
    }, 1000)
  );

  const handleChangeDisUs = (e) => {
    const oldValue = getValues("discount-us");

    const value = parseInt(e.target.value, 10);

    checkDisc(value);
    if (value <= 100) {
      clearErrors("discount-usual");
    }
    if (!value) {
      clearErrors("discount-usual");
    }
    setValue("discount-us", {
      discount: value,
      from: (oldValue || {}).from || "",
      to: (oldValue || {}).to || "",
    });
  };

  const onSubmit = (data) => {

    // console.log('data', data);
    const workTimeResult = Object.values(data).filter(
      (item) =>
        !!item?.weekday &&
        (item?.start_time !== null || item?.end_time !== null)
    );

    const happyDiscount = {
      discount: Number(data?.discount),
      from: `${data.workTime_discount_from}:00`,
      to: `${data.workTime_discount_to}:00`,
      description: "Happy Hours",
      ...(!!happyHours && !!happyHours?.id && { id: happyHours?.id }),
    };

    const usualDiscount = {
      discount: Number(data["discount-usual"]),
      description: data?.regular,
      ...(!!otherDiscount && !!otherDiscount?.id && { id: otherDiscount?.id }),
    };

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const result = tarifsRow.reduce((acc, curr) => {
          for (const [key, value] of Object.entries(curr.price)) {
            if (value !== "") {
              let type;
              switch (key) {
                case "incall":
                  type = "1";
                  break;
                case "outcall":
                  type = "2";
                  break;
                case "out":
                  type = "3";
                  break;
                default:
                  break;
              }
              acc.push({
                ...(!!curr.id && { id: Number(curr.id) }),
                duration: String(curr.duration),
                type,
                price: String(value),
              });
            }
          }
          return acc;
        }, []);
        const route = `user/profile/${formData.slug}`;

        const workTimeResultFinal = workTimeResult.map((i) => {
          return {
            ...i,
            is24: i.is24 || false,
            end_time: !i.end_time ? "00:00" : i.end_time,
            start_time: !i.start_time ? "00:00" : i.start_time,
          };
        });

        const finalResilt = result.filter((item, index, self) => {
          let sameDurationItems = self.filter(
            (el) => el.duration === item.duration
          );

          let allPricesZero = sameDurationItems.every((el) => el.price === "0");

          if (allPricesZero) {
            return sameDurationItems.length === 1;
          } else {
            return true;
          }
        });

        const method = "PUT";
        const payload = {
          isFull: true,
          prices: finalResilt,
          work_time: workTimeResultFinal,
          discounts: [],
          incall: activeCheckbox.includes('incall'),
          outcall: activeCheckbox.includes('outcall'),
          escort: activeCheckbox.includes('out')
        };

        if (happyDiscount.discount || usualDiscount.discount) {
          payload.discounts = [];

          if (happyDiscount.discount) {
            payload.discounts.push({
              ...happyDiscount,
            });
          }

          if (usualDiscount.discount) {
            payload.discounts.push({
              ...usualDiscount,
            });
          }
        }

        const dataResult = await makeRequest({ route, method, payload });
        if (dataResult.status) {
          setIsLoading(false);
          !!setAvailableSteps && setAvailableSteps((prev) => [...prev, 6]);
          !!changeStep && changeStep((prev) => prev + 1);
          setFormData(() => ({
            ...dataResult.profile,
          }));
          !!setOpen && setOpen(false);
          !!setFilled && setFilled(dataResult.profile.completion);

          !!rootElem &&
            rootElem.scrollIntoView({
              behavior: "smooth",
            });
        }
      } catch (error) {
        showToast({
          message: t("oops"),
          variant: "error",
        });
        setIsLoading(false);
      }
    };

    token && fetchData();
  };

  useEffect(() => {
    if (!!formData?.discounts?.length) {
      setValue("discount", happyHours?.discount);
      setValue("discount-happy", {
        description: happyHours?.description,
        discount: happyHours?.discount,
        from: !!happyHours?.from ? happyHours?.from.substring(0, 5) : "",
        to: !!happyHours?.to ? happyHours?.to.substring(0, 5) : "",
        id: happyHours?.id,
      });
      setValue(
        "workTime_discount_from",
        !!happyHours?.from ? happyHours?.from.substring(0, 5) : ""
      );

      setValue(
        "workTime_discount_to",
        !!happyHours?.to ? happyHours?.to.substring(0, 5) : ""
      );

      setValue("discount-usual", otherDiscount?.discount);
      setValue("discount-us", {
        description: otherDiscount?.description,
        discount: otherDiscount?.discount,
        id: otherDiscount?.id,
      });
      setValue("regular", otherDiscount?.description);
    }
  }, []);

  useEffect(() => {
    if (!!newWickData?.length) {
      fakeArray.forEach((count) => {
        const filtredNewWickData = newWickData.filter((item) => {
          return item.weekday === count + 1;
        })[0];

        setValue(`workTime-${count + 1}`, {
          weekday: filtredNewWickData?.weekday || null,
          start_time: filtredNewWickData?.start_time || null,
          end_time: filtredNewWickData?.end_time || null,
          is24: filtredNewWickData?.is24,
        });
      });
    }
  }, []);

  const handleClose = () => {
    setOpen(false)
  }

  if (!!setOpen) {
    return (
      <>
        {isLoading && (
          <div className="loader__overlay">
            <Loader height={100} />
          </div>
        )}

        <Popup
          open={open}
          setOpen={handleClose}
          onSubmit={handleSubmit(onSubmit)}
          clazz={'popup-form__body__edit'}
        >
            <h2>
              {t("tariffs") +
                ", " +
                t("workhours").toLowerCase() +
                " " +
                t("and") +
                " " +
                t("discounts").toLowerCase()}
            </h2>
            <MainPart
              setActiveCheckbox={setActiveCheckbox}
              activeCheckbox={activeCheckbox}
              addTarif={addTarif}
              tarifsRow={tarifsRow}
              ButtonTablaHead={ButtonTablaHead}
              TableHeadRow={TableHeadRow}
              disabledRowArr={disabledRowArr}
              chooseAll={chooseAll}
              dataDays={dataDays}
              TimeRow={TimeRow}
              isActiveIndex={isActiveIndex}
              isActiveIndex24={isActiveIndex24}
              setIsActiveIndex={setIsActiveIndex}
              setIsActiveIndex24={setIsActiveIndex24}
              happyHours={happyHours}
              otherDiscount={otherDiscount}
              register={register}
              handleChangeDis={handleChangeDis}
              getValues={getValues}
              setValue={setValue}
              setOpen={setOpen}
              handleChangeDisUs={handleChangeDisUs}
              errors={errors}
            />
            <PopupButtons setOpen={setOpen} isLoading={isLoading} />
        </Popup>
      </>
    );
  }

  return (
    <>
      <h2>
        {t("tariffs") +
          ", " +
          t("workhours").toLowerCase() +
          " " +
          t("and") +
          " " +
          t("discounts").toLowerCase()}
      </h2>
      <form onSubmit={handleSubmit(onSubmit)} className={`d-flex fd-column`}>
        <MainPart
          setActiveCheckbox={setActiveCheckbox}
          activeCheckbox={activeCheckbox}
          addTarif={addTarif}
          tarifsRow={tarifsRow}
          ButtonTablaHead={ButtonTablaHead}
          TableHeadRow={TableHeadRow}
          disabledRowArr={disabledRowArr}
          chooseAll={chooseAll}
          dataDays={dataDays}
          TimeRow={TimeRow}
          isActiveIndex={isActiveIndex}
          isActiveIndex24={isActiveIndex24}
          setIsActiveIndex={setIsActiveIndex}
          setIsActiveIndex24={setIsActiveIndex24}
          happyHours={happyHours}
          otherDiscount={otherDiscount}
          register={register}
          handleChangeDis={handleChangeDis}
          handleChangeDisUs={handleChangeDisUs}
          getValues={getValues}
          setValue={setValue}
          setOpen={setOpen}
          errors={errors}
        />
        <DefaultButtons backStep={backStep} isLoading={isLoading} />
      </form>
    </>
  );
};

export default TarifsGlobal;
