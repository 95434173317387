import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import PhotoUpload from "../UploadZone";
import { getIdsFromUrls } from "../changeOrder/changeOrder";

export const Video = ({ data, setData, show, formData, setFormData }) => {
  const movePhoto = (dragIndex, hoverIndex) => {
    const draggedPhoto = data.files[dragIndex];
    const newPhotos = [...data.files];
    newPhotos.splice(dragIndex, 1);
    newPhotos.splice(hoverIndex, 0, draggedPhoto);
    setData({ files: newPhotos });
    setFormData(prev => ({
      ...prev,
      media: ({
        ...prev.media,
        video: newPhotos.map(item => item.preview)
      })
    }))
    getIdsFromUrls(newPhotos, formData.slug);
  };
  return (
    <DndProvider backend={HTML5Backend}>
      {show === "video" && (
        <PhotoUpload
          data={data}
          setData={setData}
          accept={{
            "video/mp4": [".mp4", ".MP4"],
            "video/quicktime": [".mov", ".MOV"],
            "video/x-ms-wmv": [".wmv", ".WMV"],
            "video/x-msvideo": [".avi", ".AVI"],
            "video/x-matroska": [".mkv", ".MKV"],
          }}
          type="video"
          movePhoto={movePhoto}
          mode={"video"}
          maxSize={20 * 1024 * 1024}
          limit={10}
          video={true}
          formData={formData}
          setFormData={setFormData}
        />
      )}
    </DndProvider>
  );
};
