import GirlCard from "../cards/girlCard/GirlCard";
import { Button, Icon, Loader } from "@/components/ui";
import { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  InfoBox,
} from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import {
  getMapModel,
  getOneModelPosition,
  setMapModel,
  setOneModelPosition,
} from "@/stores/slices/mapModelSlice";
import "./mapFilter.scss";
import { Helmet } from "react-helmet";
import { getMapStatus, setStoriesMap } from "@/stores/slices/popupSlice";
import { useTranslation } from "react-i18next";
import { makeRequest } from "@/services/makeRequest";
import useLadyService from "@/services/LadyService";
import clusterIcon from "@/assets/img/icons/cluster.svg";
import axios from "axios";
import baseUrl from "../../services/apiConfig";
import {Popup} from "../popups";

const MyMap = ({ setClusterModels, dataCluster }) => {
  const { dispatch, userCity } = useLadyService();

  const mapStyles = {
    height: "100%",
    width: "100%",
  };

  // const defaultCenter = {
  //   lat: 50.086137,
  //   lng: 14.529199,
  // };

  const oneModel = useSelector(getOneModelPosition);

  const [data, setData] = useState([]);
  const [loading, isLoading] = useState(true);

  const { t } = useTranslation("translation");

  const toggleCurrentTippy = (e) => {
    const target = e.target;
    const tippyElems = document.querySelectorAll(".map-filter__tippy");
    tippyElems.forEach((item) => {
      item.classList.remove("current-tippy");
    });
    target.classList.add("current-tippy");
  };

  useEffect(() => {
    const fetchData = async () => {
      isLoading(true);

      try {
        const route = `profiles/simply/${userCity.id}`;
        const method = "GET";
        const payload = {
          archive: true,
        };

        const data = await makeRequest({ route, method, payload });
        if (data) {
          const result = data.data;
          setData(result);
        }
        isLoading(false);
      } catch (error) {}
    };
    fetchData();
  }, []);

  const [isLoad, setIsLoad] = useState(true);
  const [icon, setIcon] = useState(null);
  const [map, setMap] = useState(null);

  async function updateMarker() {
    setIsLoad(true);
    const iconPath = {
      path: "M0 0h24v24H0z",
      fillColor: "transparent",
      fillOpacity: 0,
      strokeWeight: 0,
      scale: 0,
    };
    setIcon(iconPath);
    setIsLoad(false);
  }
  useEffect(() => {
    if (!map || data.length === 0) return;

    if (!oneModel) {
      const bounds = new window.google.maps.LatLngBounds();
      data.forEach((model) => {
        if (model.latitude && model.longitude) {
          bounds.extend(
            new window.google.maps.LatLng(model.latitude, model.longitude)
          );
        }
      });

      map.fitBounds(bounds);
    } else {
      const bounds = new window.google.maps.LatLngBounds();
      const modelCoors = data.filter((item) => item.slug === oneModel);

      modelCoors.forEach((model) => {
        if (model.latitude && model.longitude) {
          bounds.extend(
            new window.google.maps.LatLng(model.latitude, model.longitude)
          );
        }
      });

      map.fitBounds(bounds);
    }

    updateMarker();

    const listener = map.addListener("idle", () => {
      if (map && map.getZoom() > 16) {
        map.setZoom(16);
      }
      window.google.maps.event.removeListener(listener);
    });
  }, [map, data]);
  

  // const [visibleBounds, setVisibleBounds] = useState();

  // useEffect(() => {
  //   if (map) {
  //     const updateBounds = () => {
  //       const bounds = map.getBounds();
  //       if (bounds) {
  //         console.log(bounds)
  //         const ne = bounds.getNorthEast();
  //         const sw = bounds.getSouthWest();
  //         setVisibleBounds({
  //           northeast: { lat: ne.lat(), lng: ne.lng() },
  //           southwest: { lat: sw.lat(), lng: sw.lng() }
  //         });
  //       }
  //     };

  //     // Добавляем слушатель события idle
  //     // map.addListener("idle", updateBounds);

  //     // Инициализируем границы
  //     updateBounds();
  //     // const listener = map.addListener("idle", updateBounds);
  //     // if (listener) {
  //     //   window.google.maps.event.clearListeners(listener);
  //     // }
  //   }
  // }, [map]);

  const clusterStyles = [
    {
      textSize: 22,
      textColor: "white",
      url: clusterIcon,
      height: 60,
      width: 60,
    },
    {
      textSize: 22,
      textColor: "white",
      url: clusterIcon,
      height: 60,
      width: 60,
    },
    {
      textSize: 22,
      textColor: "white",
      url: clusterIcon,
      height: 60,
      width: 60,
    },
  ];

  const handleClusterClick = (cluster) => {
    if (map.getZoom() >= 16 && !dataCluster.length) {
      const markers = cluster.getMarkers();
      dispatch(setMapModel(null));
      dispatch(setOneModelPosition(null));

      const allSlugs = markers.map((marker) => marker.model.slug);

      axios
        .get(`${baseUrl}profiles?slug=${allSlugs.join(",")}`)
        .then((response) => {
          if (response.data.profiles) {
            setClusterModels(response.data.profiles);
          }
        });
    }
  };

  const options = {
    maxZoom: 19,
  };

  return !loading ? (
    <GoogleMap
      mapContainerStyle={mapStyles}
      // center={defaultCenter}
      onLoad={(map) => setMap(map)}
      options={options}
    >
      {
        <MarkerClusterer
          gridSize={60}
          styles={clusterStyles}
          onClick={handleClusterClick}
        >
          {(clusterer) =>
            data.map((model) => (
              <>
                {model.latitude && model.longitude ? (
                  <Marker
                    icon={icon}
                    key={model.id}
                    position={{
                      lat: model.latitude,
                      lng: model.longitude,
                    }}
                    clusterer={clusterer}
                    options={{ model }}
                  >
                    {!isLoad && (
                      <InfoBox
                        key={model.lat}
                        defaultPosition={
                          new window.google.maps.LatLng(model.lat, model.lng)
                        }
                        options={{
                          enableEventPropagation: false,
                          boxStyle: {
                            translate: `-50% -127%`,
                            minWidth: `100px`,
                          },
                          closeBoxURL: "",
                        }}
                      >
                        <div
                          onClick={(e) => {
                            dispatch(setMapModel(model.slug));
                            toggleCurrentTippy(e);
                          }}
                          className={
                            model.is_elite && !model.is_top
                              ? "map-filter__tippy map-filter__tippy--elite"
                              : !model.is_elite && model.is_top
                              ? "map-filter__tippy map-filter__tippy--top"
                              : `map-filter__tippy`
                          }
                        >
                          {`${t("from")} `}
                          {
                            model.prices?.incall
                              ? model.prices?.incall
                              : model.prices?.outcall
                          }
                          {' '}€
                          {` / ${t("hour")}`}
                        </div>
                      </InfoBox>
                    )}
                  </Marker>
                ) : (
                  <></>
                )}
              </>
            ))
          }
        </MarkerClusterer>
      }
      {/* {renderClusterInfo()} */}
    </GoogleMap>
  ) : (
    <Loader />
  );
};

const CurrentModel = ({ mapOpen }) => {
  const { dispatch, lang } = useLadyService();
  const currentModelId = useSelector(getMapModel);
  const [load, setLoad] = useState(true);

  const [oneProfile, setOneProfile] = useState(null);

  useEffect(() => {
    if (!currentModelId) {
      setOneProfile(null);
    }
  }, [currentModelId]);

  useEffect(() => {
    if (!mapOpen) {
      return () => {
        setOneProfile(null);
      };
    }
  }, [mapOpen]);

  useEffect(() => {
    const fetchData = async () => {
      setLoad(false);

      try {
        const route = `profiles/short/${currentModelId}`;
        const method = "GET";

        const data = await makeRequest({ route, method });
        if (data) {
          const result = data.data;
          setOneProfile(result);
          setLoad(true);
        }
      } catch (error) {}
    };

    currentModelId && fetchData();
  }, [currentModelId]);

  const oneModel = useSelector(getOneModelPosition);
  useEffect(() => {
    const fetchData = async () => {
      setLoad(false);

      try {
        const route = `profiles/short/${oneModel}`;
        const method = "GET";

        const data = await makeRequest({ route, method });
        if (data) {
          const result = data.data;
          setOneProfile(result);
          setLoad(true);
        }
      } catch (error) {}
    };

    oneModel && fetchData();
  }, []);

  const toggleCLoseProfile = () => {
    setOneProfile(null);
    dispatch(setMapModel(null));
    const tippyElems = document.querySelectorAll(".map-filter__tippy");
    tippyElems.forEach((item) => {
      item.classList.remove("current-tippy");
    });
  };
  if (oneProfile) {
    return (
      <div className={"map-filter__wrapper"}>
        {!load ? (
          <div className="girl-card-loader">
            <Loader />
          </div>
        ) : (
          <>
            <Button
              size={"m"}
              square={true}
              clazz={"button--primary"}
              onClick={toggleCLoseProfile}
            >
              <Icon spritePath={"close"} size={"m"} />
            </Button>
            <GirlCard
              props={{ ...oneProfile }}
              key={oneProfile.id}
              mobile={true}
            />
          </>
        )}
      </div>
    );
  }
};
const CurrentModelCluster = ({ mapOpen, slugs, setClusterModels }) => {
  const { lang } = useLadyService();
  const [load, setLoad] = useState(true);

  const [oneProfile, setOneProfile] = useState([]);

  useEffect(() => {
    if (!mapOpen) {
      return () => {
        setOneProfile(null);
      };
    }
  }, [mapOpen]);

  useEffect(() => {
    const fetchData = async (slug) => {
      setLoad(false);

      try {
        const route = `profiles/short/${slug}`;
        const method = "GET";

        const data = await makeRequest({ route, method });
        if (data) {
          const result = data.data;
          setOneProfile((prev) => [...prev, result]);
          setLoad(true);
        }
      } catch (error) {}
    };

    !!slugs?.length &&
      slugs.map((item) => {
        fetchData(item.slug);
      });
  }, [slugs]);

  if (!!oneProfile.length) {
    return (
      <div className="map-filter__wrapper">
        <Button
          size={"m"}
          square={true}
          clazz={"button--primary"}
          onClick={() => {
            setClusterModels([]);
            setOneProfile([]);
          }}
        >
          <Icon spritePath={"close"} size={"m"} />
        </Button>
        <div className={"clusters"}>
          {!load ? (
            <div className="girl-card">
              <Loader />
            </div>
          ) : (
            oneProfile.map((item) => {
              return (
                <GirlCard props={{ ...item }} key={item.id} mobile={true} />
              );
            })
          )}
        </div>
      </div>
    );
  }
};

const MapFilter = () => {
  const dispatch = useDispatch();
  const mapPopupStatus = useSelector(getMapStatus);

  const onClose = () => {
    dispatch(setStoriesMap(false));
    dispatch(setMapModel(null));
    dispatch(setOneModelPosition(null));
  };
  const [clusterModelData, setClusterModelData] = useState([]);

  return (
    <Popup
      open={!!mapPopupStatus}
      setOpen={() => onClose()}
      id={"map"}
      clazz="map-filter__body"
      container={'div'}
    >

        {mapPopupStatus && (
          <MyMap
            setClusterModels={setClusterModelData}
            dataCluster={clusterModelData}
          />
        )}
        <CurrentModel mapOpen={mapPopupStatus} />
        <CurrentModelCluster
          mapOpen={mapPopupStatus}
          slugs={clusterModelData}
          setClusterModels={setClusterModelData}
        />

    </Popup>
  );
};

export default MapFilter;
