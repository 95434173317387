import { useEffect, useRef, useState } from "react";
import useLadyService from "../../../services/LadyService";
import showToast from "../../toast/Toast";
import { Button, Checkbox, Dropdown, Icon } from "../../ui";
import { InputInLabel } from "../../ui";
import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  clearComplainReviewsId,
  getComplainReviewsId,
} from "../../../stores/slices/reviewsSlice";
import { SearchModels } from "./SearchModels";
import { Link } from "react-router-dom";
import axios from "axios";
import baseUrl from "@/services/apiConfig";
import { useSelector } from "react-redux";
import Popup from "../Popup";

const ErrorMessage = ({ message }) => {
  return <span className="error create-error">{message}</span>;
};

const NewSupportPopup = (props) => {
  const { open, setOpen, defaultPopupTitle, handleFileChange, setData } = props;
  const { t, dispatch, userType, token } = useLadyService();

  const [files, setFilesPopup] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const formSchema = object().shape({
    subject: string().required(t("required")),
    message: string().min(8, t('min8')).required(t("required")),
  });

  const handleInput = (e) => {
    handleFileChange(e.target.files, setFilesPopup);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    setValue,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const handleCloseDelete = () => {
    reset();
    setOpen(false);
    dispatch(clearComplainReviewsId());
  };

  const [selectedModel, setSelectedModel] = useState(null);
  const isComplainReviewsId = useSelector(getComplainReviewsId);

  useEffect(() => {
    if (!!isComplainReviewsId) {
      setSelectedModel(isComplainReviewsId);
    }
  }, []);

  const onSubmit = (data, e) => {
    e.preventDefault();
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const resultFiles = [];
        const resultFilesState = [];

        if (files && !!files.length) {
          files.forEach((element) => {
            resultFiles.push(element);
            const fileUrl = URL.createObjectURL(element);

            resultFilesState.push({
              link: fileUrl,
              file_name: element.name,
            });
          });
        }

        const result = await axios.post(
          baseUrl + `tickets`,
          {
            subject: data.subject,
            message: data.message,
            ...(files && !!files.length && { files: resultFiles }),
            ...(selectedModel &&
              !!selectedModel.slug && { profile: selectedModel.slug }),
          },
          {
            headers: {
              Authorization: token ? `Bearer ${token}` : "",
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (result) {
          const newTicket = {
            created_at: Math.floor(Date.now() / 1000),
            new_count: 0,
            subject: data.subject,
            id: result.data.ticket,
            message: {
              data: {
                created_at: Math.floor(Date.now() / 1000),
                attach: resultFilesState,
                message: data.message,
                self: true,
              },
            },
            ...(selectedModel &&
              !!selectedModel.slug && {
                profile: { slug: selectedModel.slug, name: selectedModel.name },
              }),
          };

          setData((prev) => [newTicket, ...prev]);

          setIsLoading(false);
          reset();
          dispatch(clearComplainReviewsId());
          setOpen(false);
          showToast({
            message: t("success"),
            variant: "success",
          });
        }
      } catch (error) {
        console.log(error);

        showToast({
          message: t("oops"),
          variant: "error",
        });
        setIsLoading(false);
      }
    };

    fetchData();
  };

  const [dropDownOpen, setDropDownOpen] = useState(false);

  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const [dropDownTitle, setDropDownTitle] = useState(t("linktoad"))

  useEffect(() => {
    if (defaultPopupTitle) {
      setValue("subject", defaultPopupTitle);
    }
  }, []);

  return (
    <Popup
      open={open}
      setOpen={handleCloseDelete}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
      wrapperClazz={"support-popup"}
      {...props}
    >
      <h3 className="m-12-24">{t("newreq")}</h3>
      <div className="popup-form__inner">

        <InputInLabel
          type={"text"}
          id={"create-subject"}
          placeholder={""}
          register={{...register("subject")}}
          clazz={`_required ${errors.subject && "_error"}`}
          onChange={() => {
            if (errors.subject) {
              clearErrors("subject");
            }
          }}
        >
          {t("theme")}
          {errors.subject && <ErrorMessage message={t("required")}/>}
        </InputInLabel>
        {userType === "indi" && (
          <Dropdown
            title={dropDownTitle}
            name={"support"}
            clazz={"w-100 fz-14 color-main--600 text-nowrap justify-start"}
            size={"s"}
            contentClass={"w-100"}
            handleClose={dropDownOpen}
          >
            {" "}
            <SearchModels
              setDropDownTitle={setDropDownTitle}
              setSelectedModel={setSelectedModel}
              selectedModel={selectedModel}
              setDropDownOpen={setDropDownOpen}
            />
          </Dropdown>
        )}
        {/* {selectedModel && (
        <div className="agency-block">
          <img src={selectedModel.img} alt="girl" />
          <Link className="agency-block__link-to">{selectedModel.name}</Link>
          <Checkbox
            border={false}
            checked={true}
            onChange={() => {
              setSelectedModel(null);
            }}
          />
        </div>
      )} */}
        <InputInLabel
          type={"textarea"}
          id={"create-message"}
          placeholder={""}
          register={{...register("message")}}
          clazz={`_required ${errors.message && "_error"}`}
          onChange={() => {
            if (errors.message) {
              clearErrors("message");
            }
          }}
        >
          {t("message")}
          {errors.message && <ErrorMessage message={errors.message.message}/>}
        </InputInLabel>
        <Button
          buttonType={"button"}
          size={"s"}
          square={false}
          clazz="button--secondary"
          onClick={handleButtonClick}
        >
          <input
            ref={fileInputRef}
            multiple="multiple"
            type="file"
            style={{display: "none"}}
            onChange={handleInput}
          ></input>
          <Icon size={"s"} spritePath={"import"}/>
          {t("addfile")}
        </Button>
        {files && !!files.length && (
          <div className="d-flex gap-8 fd-column">
            {files.map((item) => {
              return <span className="color-main">{item.name}</span>;
            })}
          </div>
        )}
        <Button buttonType={"submit"} size={"l-forever"} clazz="button--green">
          {t("send")}
        </Button>
      </div>
    </Popup>
  );
};

export default NewSupportPopup;
