import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "../button/button.scss";

const ButtonLink = (props) => {
  const {
    children,
    href,
    size,
    clazz,
    square = false,
    title,
    state,
    onClick,
    counter,
    anchor = false,
    target,
    disabled,
    ref,
  } = props;

  let squareClass = `${square ? "button-square" : "button"}`
  let sizeClass = `${size ? square ? ` button-square-${size}` : ` button-${size}` : ""}`
  let classNames = `${squareClass}${sizeClass}${clazz ? ` ${clazz}` : ""}${disabled ? " _disabled" : ""}${counter < 1 ? " empty" : ""}`

  const defaultProps = {
    disabled: disabled,
    "data-counter": counter,
    title: title,
    onClick: onClick,
    ref: ref,
    state: { state },
    target: target,
    to: href,
    className: classNames,
  };

  if (anchor === true) {
    return (
      <HashLink
        {...defaultProps}
        smooth
        duration={500}
      >
        {children}
      </HashLink>
    );
  }

  return (
    <Link
      {...defaultProps}
    >
      {children}
    </Link>
  );
};

// ButtonLink.propTypes = {
//   square: PropTypes.bool,
//   children: PropTypes.node,
//   size: PropTypes.string,
//   buttonType: PropTypes.string,
//   clazz: PropTypes.string,
//   href: PropTypes.string,
// };
export default ButtonLink;
