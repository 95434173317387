import React from "react";
import PhoneInput from "react-phone-input-2";

const CustomPhoneInput = (props) => {
  const {
    onChange,
    id,
    clazz,
    register,
    name,
    children,
    placeholder,
    onClick,
    autocomplete,
    disabled,
    control,
    required = false,
    value,
  } = props;

  console.log("value", value);

  return (
    <label
      htmlFor={id}
      onClick={onClick}
      className={`input-label${clazz ? ` ${clazz}` : ""} ${
        disabled ? "disabled" : ""
      }`}
    >
      {children}
      <PhoneInput
        placeholder={placeholder}
        inputRef={register}
        {...register}
        inputProps={{
          name,
          required,
        }}
        value={value}
        id={id}
        name={name}
        control={control}
        autoComplete={autocomplete}
        onChange={onChange}
        disabled={disabled}
        className={disabled ? "disabled" : null}
      />
    </label>
  );
};

export default CustomPhoneInput;
