import {createPortal} from "react-dom";
import {Helmet} from "react-helmet";
import React, {useEffect, useRef, useState} from "react";
import {Button, Icon} from "@/components/ui";
import {Loader} from "../ui";

const Popup = ({
                 open,
                 setOpen,
                 onSubmit,
                 id,
                 clazz,
                 method,
                 container,
                 children,
                 wrapperClazz,
                 isLoading,
                 edit,
}) => {

  const [openAnimation, setOpenAnimation] = useState(false);

  const handleClose = () =>{
    setOpenAnimation(false);

    setTimeout(() =>{
      if(setOpen){
        setOpen()
      }
    },[200])
  }

  useEffect(()=>{
    if(!!open){
      setOpenAnimation(true);
    }
  }, [open])

  useEffect(() => {
    document.addEventListener("keyup", (event) => {
      if (event.isComposing || event.key === "Escape") {
        handleClose()
      }
    });
  }, []);

  let CloseButtonTemplate = () => setOpen && (
    <Button
      onClick={() => handleClose()}
      clazz={"button__close"}
    >
      <Icon size={"l"} spritePath={"close"}/>
    </Button>
  )

  const containerType = () => {
    switch (container){
      case 'div':
        return (
          <div
            onClick={(e) => e.stopPropagation()}
            className={`${edit ? 'popup-form__body__edit' : 'popup-form__body'}${clazz ? ` ${clazz}`: ''}`}
          >

            {CloseButtonTemplate()}

            {children}
          </div>
        )

      case 'none':
        return (
            <>
              {CloseButtonTemplate()}
              {children}
            </>
        )

      default:
        return (
          <form
            method={method}
            onClick={(e) => e.stopPropagation()}
            onSubmit={onSubmit}
            className={`popup-form__body${clazz ? ` ${clazz}`: ''}`}
          >

            {CloseButtonTemplate()}

            {children}
          </form>
        )
    }
  }

  return open && createPortal(
      <dialog
        id={id}
        open
        onClick={!container ? null : () => handleClose() }
        onCancel={() => handleClose()}
        className={`${wrapperClazz? wrapperClazz : ''}${openAnimation ? ' _open' : ''}`}
      >
        <Helmet>
          <html className={"lock"}></html>
        </Helmet>

        {!!isLoading && (
          <div className="loader__overlay">
            <Loader height={100} />
          </div>
        )}

        {containerType()}

      </dialog>
      , document.querySelector('#root')
    )
}
export default Popup