import { useCallback, useEffect, useRef, useState } from "react";
import "./media.scss";
import Tubs from "./Tubs";
import { object } from "yup";

import { Button, Icon, LoadingButton, Checkbox } from "@/components/ui";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Photo } from "./Photo";
import { Video } from "./Video";
import { Interior } from "./Interior";
import { Ero } from "./Ero";
import { makeRequest } from "@/services/makeRequest";
import _ from "lodash";
import AttentionBox from "../../../../../guide/components/AttentionBox/AttentionBox";
import { Link } from "react-router-dom";
import useLadyService from "@/services/LadyService";
import { Loader } from "@/components/ui";
import { Helmet } from "react-helmet";
import showToast from "@/components/toast/Toast";
import debounce from "lodash.debounce";
import { PornoStar } from "./pornoStar/PornoStar";
import { Popup } from "../../../../../../popups";

const MainPart = ({
  setVisualElem,
  visualElem,
  errorPrice,
  dataEroPhoto,
  dataEroVideo,
  priceForEro,
  setErrorPrice,
  eroPriceRef,
  userFormData,
  dataPhoto,
  setDataPhoto,
  imagesError,
  setImagesError,
  dataVideo,
  setDataVideo,
  dataInterior,
  setDataInterior,
  setDataEroPhoto,
  setDataEroVideo,
  setFormData,
  setPriceForEro,
  setDescForEro,
  descForEro,
  register,
  handleInputChange,
  inputValue,
  toggleTub,
  nextStepText,
  setAllSteps,
  setAvailableSteps,
  setValue,
  getValues,
  setInputValue,
  resultLangsAi,
  setResultLangsAi,
}) => {
  const { t } = useLadyService();

  const [pornoStarChecked, setPornoStarChecked] = useState(
    !!userFormData.porn_star?.length
  );

  return (
    <div className={`create-page__form photo`}>
      <div className="tabs__nav mb-16">
        <Tubs
          setVisualContent={setVisualElem}
          visualContent={visualElem}
          notToggle={errorPrice}
          // onChange={(e) => loadData(e)}
          notLetToggle={
            visualElem === "ero" &&
            (!!dataEroPhoto.files?.length ||
              !!dataEroPhoto?.length ||
              !!dataEroVideo.files?.length ||
              !!dataEroVideo?.length) &&
            !priceForEro
          }
          scrollToElem={() => {
            setErrorPrice(true);
            eroPriceRef.current.scrollIntoView({
              behavior: "smooth",
            });
          }}
          noLink
        />
      </div>
      {visualElem === "ero" &&
        !(
          userFormData?.ero?.data?.photo.link ||
          userFormData?.ero?.data?.photo.link
        ) && <span>{t("uploadero")}</span>}
      {visualElem === "ero" &&
        (userFormData?.ero?.data?.photo.link ||
          userFormData?.ero?.data?.photo.link) && (
          <b className="text-center">{t("moderated24hour")}</b>
        )}
      <Photo
        show={visualElem}
        data={dataPhoto}
        // uploadDataToServer={uploadDataToServer}
        setData={setDataPhoto}
        error={imagesError}
        setError={setImagesError}
        formData={userFormData}
        setFormData={setFormData}
        setAllSteps={setAllSteps}
        setAvailableSteps={setAvailableSteps}
      />
      <Video
        show={visualElem}
        data={dataVideo}
        setData={setDataVideo}
        formData={userFormData}
        setFormData={setFormData}
      />
      <Interior
        show={visualElem}
        data={dataInterior}
        setData={setDataInterior}
        formData={userFormData}
        setFormData={setFormData}
      />
      <Ero
        setResultLangsAi={setResultLangsAi}
        selectedLangsAi={resultLangsAi}
        show={visualElem}
        data={dataEroPhoto}
        setData={setDataEroPhoto}
        dataVideo={dataEroVideo}
        setDataVideo={setDataEroVideo}
        setPrice={setPriceForEro}
        eroPrice={priceForEro}
        eroPriceRef={eroPriceRef}
        setDesc={setDescForEro}
        eroDesc={descForEro}
        errorPrice={errorPrice}
        setError={setErrorPrice}
        formData={userFormData}
        setFormData={setFormData}
      />
      {visualElem === "photo" && (
        <div className="d-flex gap-16 align-center">
          <Checkbox
            title={t("pornstar")}
            clazz={"w-mc mt-16 mb-16"}
            disabled={false}
            checked={pornoStarChecked}
            onClick={() => setPornoStarChecked((prev) => !prev)}
          />
          <span>{t("i-stardescr")}</span>
        </div>
      )}
      {(visualElem === "video" || (visualElem === "photo" && pornoStarChecked)) &&
         (
          <>
            {visualElem === "video" && <h3>{t("pornstar")}</h3>}
            <PornoStar
              setInputValue={setInputValue}
              userFormData={userFormData}
              setFormData={setFormData}
              getValues={getValues}
              inputValue={inputValue}
              register={register}
              setValue={setValue}
            />
          </>
        )}
      {visualElem === "photo" && (
        <AttentionBox type={"important"}>
          {t("pesonalresp")}{" "}
          <Link to={"/rules"} target={"_blank"} className={"green"}>
            {t("portalules")}
          </Link>{" "}
          {t("notsanction")}.
          <br />
          <br />{" "}
          <span title={t("photosverified")} className={"model__icon"}>
            <Icon spritePath={"verify-fill"} size={"l"} />
            {t("verification")}
          </span>{" "}
          {t("and")}{" "}
          <span title={t("advantagehealth")} className={"model__icon"}>
            <Icon spritePath={"health-fill"} size={"l"} />
            {t("healthy")}
          </span>{" "}
          – {t("toget")}.
        </AttentionBox>
      )}

      {visualElem !== "ero" && (
        <Button
          buttonType={"button"}
          size={"s"}
          square={false}
          clazz={`button--primary icon ml-auto mt-16 icon--chevrone-right ${
            visualElem === "photo"
              ? "icon--film"
              : visualElem === "video"
              ? "icon--home-main"
              : visualElem === "interior"
              ? "icon--pants"
              : ""
          } create-page--cont-btn`}
          onClick={() => toggleTub("forward", visualElem)}
        >
          {t(
            nextStepText === "video"
              ? "videofotograph"
              : nextStepText === "ero"
              ? "erocontent"
              : nextStepText
          )}
        </Button>
      )}
    </div>
  );
};

const PopupButtons = ({ setOpen, isLoading }) => {
  const { t } = useLadyService();

  return (
    <div className="create-form__buttons">
      <Button
        size={"l-forever"}
        square={false}
        clazz="button--secondary min-w-160"
        onClick={() => setOpen(false)}
        disabled={isLoading}
      >
        {t("cancel")}
      </Button>

      <LoadingButton
        buttonType={"submit"}
        isLoading={isLoading}
        size={"l-forever"}
        square={false}
        clazz="button--green min-w-160"
      >
        {t("save")}
      </LoadingButton>
    </div>
  );
};

const DefaultButtons = ({
  backStep,
  isGlobalLoading,
  nextStep,
  visualElem,
}) => {
  const { t } = useLadyService();

  return (
    <div className="create-form__buttons">
      <Button
        size={"l-forever"}
        square={false}
        clazz="button--secondary min-w-160"
        onClick={() => backStep()}
      >
        {t("back")}
      </Button>

      <LoadingButton
        buttonType={"submit"}
        size={"l-forever"}
        square={false}
        isLoading={isGlobalLoading}
        clazz="button--green min-w-160"
        onClick={() => nextStep()}
      >
        {t("continue")}
      </LoadingButton>
    </div>
  );
};

const MediaGlobal = ({
  changeStep,
  rootElem,
  formData: userFormData,
  setFormData,
  setAvailableSteps,
  visualElem,
  setVisualElem,
  open,
  setOpen,
  setAllSteps,
}) => {
  const formSchema = object().shape({});
  const { register, getValues, setValue, handleSubmit } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const { lang, t, token } = useLadyService();

  const fakeArray = Array.from({ length: 3 }, (_, index) => index);
  const allowedValues = ["photo", "video", "interior", "ero"];
  const chunkSize = 5 * 1024 * 1024;
  const currentIndex = allowedValues.indexOf(visualElem);
  const nextIndex = (currentIndex + 1) % allowedValues.length;
  const nextStepText = allowedValues[nextIndex];

  const [isGlobalLoading, setIsGlobalLoading] = useState(false);
  const eroPriceRef = useRef(null);

  const [imagesError, setImagesError] = useState("");

  const toggleTub = (direction, name) => {
    setErrorPrice(false);

    if (name !== "ero") {
      const currentIndex = allowedValues.indexOf(visualElem);
      let nextIndex;
      if (direction === "forward") {
        nextIndex = (currentIndex + 1) % allowedValues.length;
      } else if (direction === "back") {
        nextIndex =
          (currentIndex - 1 + allowedValues.length) % allowedValues.length;
      } else {
        return;
      }
      setVisualElem(allowedValues[nextIndex]);
      rootElem.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const nextStep = () => {
    setImagesError("");
    setErrorPrice(false);

    if (!!!dataPhoto.files.length) {
      setVisualElem("photo");
      rootElem.scrollIntoView({
        behavior: "smooth",
      });
      setImagesError(t("req1photo"));
      return;
    }
    if (!!dataEroPhoto?.files?.length || !!dataEroVideo?.files?.length) {
      fetchEro();
    }
    setIsGlobalLoading(true);
    // setFormData((prevData) => ({
    //   ...prevData,
    //   // eroPhoto: dataEroPhoto,
    //   // eroVideo: dataEroVideo,
    //   // eroPrice: priceForEro,
    //   // eroDesc: descForEro,
    //   isMediaStepComplete: true,
    // }));
    rootElem.scrollIntoView({
      behavior: "smooth",
    });
    changeStep((prev) => prev + 1);
    setAvailableSteps((prev) => [...prev, 3]);
  };

  const [dataPhoto, setDataPhoto] = useState(
    !!userFormData.media?.photo.length
      ? {
          files: userFormData.media?.photo.map((item) => {
            return { preview: item, name: item };
          }),
        }
      : userFormData.photo || { files: [] }
  );

  const [dataVideo, setDataVideo] = useState(
    !!userFormData.media?.video.length
      ? {
          files: userFormData.media?.video.map((item) => {
            return { preview: item, name: item };
          }),
        }
      : userFormData.video || { files: [] }
  );
  const [dataInterior, setDataInterior] = useState(
    !!userFormData.media?.interior.length
      ? {
          files: userFormData.media?.interior.map((item) => {
            return { preview: item, name: item };
          }),
        }
      : userFormData.interior || { files: [] }
  );
  const [dataEroPhoto, setDataEroPhoto] = useState(
    !!userFormData?.ero?.data?.photo?.data?.length
      ? {
          files: userFormData?.ero?.data?.photo.data,
        }
      : userFormData.eroPhoto || { files: [] }
  );
  const [dataEroVideo, setDataEroVideo] = useState(
    !!userFormData?.ero?.data?.video?.data?.length
      ? {
          files: userFormData?.ero?.data?.video.data,
        }
      : userFormData.eroVideo || { files: [] }
  );

  const [priceForEro, setPriceForEro] = useState(
    userFormData?.ero?.data?.price || userFormData?.eroPrice || 5
  );
  const [descForEro, setDescForEro] = useState(
    !!userFormData?.ero?.data?.description
      ? userFormData?.ero?.data?.description
      : {
          en: { description: null },
          ru: { description: null },
          cz: { description: null },
        }
  );
  const [errorPrice, setErrorPrice] = useState(false);
  const [inputValue, setInputValue] = useState([
    (!!userFormData?.porn_star?.length && userFormData?.porn_star[0]) || {
      video_link: "",
      status: null,
    },
    (!!userFormData?.porn_star?.length && userFormData?.porn_star[1]) || {
      video_link: "",
      status: null,
    },
    (!!userFormData?.porn_star?.length && userFormData?.porn_star[2]) || {
      video_link: "",
      status: null,
    },
  ]);

  const fetchData = async (str, index) => {
    try {
      setValue(`erolink-${index}`, str);
      const formValue = getValues();

      const res = [
        { video_link: formValue[`erolink-0`] },
        { video_link: formValue[`erolink-1`] },
        { video_link: formValue[`erolink-2`] },
      ].filter((item) => !!item.video_link);

      const route = `user/profile/${userFormData?.slug}`;

      const method = "PUT";
      const payload = {
        porn: res,
      };

      const result = await makeRequest({ route, method, payload });
      if (result) {
        setFormData((prevData) => {
          return {
            ...prevData,
            porn_star: res,
          };
        });
      }
    } catch (error) {
      console.log(error);
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  const loadPorno = useCallback(
    debounce((value, index) => {
      fetchData(value, index);
    }, 1000),
    []
  );

  const handleInputChange = (event, index) => {
    let value = event.target.value;

    setInputValue((prevStates) => {
      const newState = [...prevStates];
      if (value) {
        newState[index] = {
          status: 0,
          video_link: value,
        };
      }
      if (!value) {
        newState[index] = {
          status: undefined,
          video_link: "",
        };
      }

      return newState;
    });

    token && loadPorno(value, index);
  };

  const backStep = () => {
    changeStep((prev) => prev - 1);
    rootElem.scrollIntoView({
      behavior: "smooth",
    });
  };

  const [resultLangsAi, setResultLangsAi] = useState([]);

  const fetchEro = async () => {
    try {
      const route = `user/profile/${userFormData.slug}/update-ero`;
      const method = "PUT";
      const payload = {
        en: {
          description: resultLangsAi.includes("en")
            ? ""
            : descForEro["en"]?.description,
        },
        cz: {
          description: resultLangsAi.includes("cz")
            ? ""
            : descForEro["cz"]?.description,
        },
        ru: {
          description: resultLangsAi.includes("ru")
            ? ""
            : descForEro["ru"]?.description,
        },
        price: priceForEro,
        description_translate_to: resultLangsAi.filter((item) => item),
      };
      const result = await makeRequest({ route, method, payload });
      if (result) {
        setFormData((prevData) => ({
          ...prevData,
          ero: {
            data: {
              description: descForEro ? descForEro : {},
              description_translate_to: descForEro ? resultLangsAi : [],
              price: priceForEro,
              hide: prevData.ero?.data?.hide,
              photo: prevData.ero?.data?.photo,
              video: prevData.ero?.data?.video,
              photo_count: prevData.ero?.data?.photo_count,
            },
          },
          // eroPrice: priceForEro,
          // eroDesc: descForEro,
        }));
        !!setOpen && setOpen(false);

        !!rootElem &&
          rootElem.scrollIntoView({
            behavior: "smooth",
          });
      }
    } catch (error) {
      console.log(error);
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  const onSubmit = () => {
    if (!!dataEroPhoto?.files?.length || !!dataEroVideo?.files?.length) {
      fetchEro();
    }
    !!setOpen && setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!!setOpen) {
    return (
      <>
        {isGlobalLoading && (
          <div className="loader__overlay">
            <Loader height={100} />
          </div>
        )}
        <Popup
          open={open}
          setOpen={handleClose}
          onSubmit={handleSubmit(onSubmit)}
          clazz={"popup-form__body__edit min-h-800"}
        >
          <h2>{t("photosandvid")}</h2>
          <MainPart
            setResultLangsAi={setResultLangsAi}
            selectedLangsAi={resultLangsAi}
            setAvailableSteps={setAvailableSteps}
            setVisualElem={setVisualElem}
            visualElem={visualElem}
            errorPrice={errorPrice}
            dataEroPhoto={dataEroPhoto}
            dataEroVideo={dataEroVideo}
            priceForEro={priceForEro}
            setErrorPrice={setErrorPrice}
            eroPriceRef={eroPriceRef}
            userFormData={userFormData}
            dataPhoto={dataPhoto}
            setDataPhoto={setDataPhoto}
            imagesError={imagesError}
            setImagesError={setImagesError}
            dataVideo={dataVideo}
            setDataVideo={setDataVideo}
            dataInterior={dataInterior}
            setDataInterior={setDataInterior}
            setDataEroPhoto={setDataEroPhoto}
            setDataEroVideo={setDataEroVideo}
            setFormData={setFormData}
            setPriceForEro={setPriceForEro}
            setDescForEro={setDescForEro}
            descForEro={descForEro}
            fakeArray={fakeArray}
            register={register}
            handleInputChange={handleInputChange}
            inputValue={inputValue}
            toggleTub={toggleTub}
            nextStepText={nextStepText}
            setAllSteps={setAllSteps}
            setValue={setValue}
            getValues={getValues}
            setInputValue={setInputValue}
          />
          <PopupButtons setOpen={setOpen} isLoading={isGlobalLoading} />
        </Popup>
      </>
    );
  }

  return (
    <>
      <h2>{t("photosandvid")}</h2>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onClick={(e) => e.stopPropagation()}
      >
        <MainPart
          setResultLangsAi={setResultLangsAi}
          selectedLangsAi={resultLangsAi}
          setAvailableSteps={setAvailableSteps}
          setVisualElem={setVisualElem}
          visualElem={visualElem}
          errorPrice={errorPrice}
          dataEroPhoto={dataEroPhoto}
          dataEroVideo={dataEroVideo}
          priceForEro={priceForEro}
          setErrorPrice={setErrorPrice}
          eroPriceRef={eroPriceRef}
          userFormData={userFormData}
          dataPhoto={dataPhoto}
          setDataPhoto={setDataPhoto}
          imagesError={imagesError}
          setImagesError={setImagesError}
          dataVideo={dataVideo}
          setDataVideo={setDataVideo}
          dataInterior={dataInterior}
          setDataInterior={setDataInterior}
          setDataEroPhoto={setDataEroPhoto}
          setDataEroVideo={setDataEroVideo}
          setFormData={setFormData}
          setPriceForEro={setPriceForEro}
          setDescForEro={setDescForEro}
          descForEro={descForEro}
          fakeArray={fakeArray}
          register={register}
          handleInputChange={handleInputChange}
          inputValue={inputValue}
          toggleTub={toggleTub}
          nextStepText={nextStepText}
          setAllSteps={setAllSteps}
          setValue={setValue}
          getValues={getValues}
          setInputValue={setInputValue}
        />
        <DefaultButtons
          backStep={backStep}
          isGlobalLoading={isGlobalLoading}
          nextStep={nextStep}
          visualElem={visualElem}
        />
      </form>
    </>
  );
};

export default MediaGlobal;
