import { InputInLabel, Button } from "@/components/ui";
import { useState, useEffect } from "react";

import showToast from "../../../../../toast/Toast";

import { makeRequest } from "@/services/makeRequest";

import _ from "lodash";

import "./infoForm.scss";

import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { useForm } from "react-hook-form";
import useLadyService from "../../../../../../services/LadyService";
import {
  getOneProfileAddress,
  setOneProfileAddress,
  setOneProfileCoords,
} from "../../../../../../stores/slices/oneProfileSlice";
import { ErrorMessage, Icon, SelectCountry } from "../../../../../ui";
import GeoInput from "../../../../../ui/input/GeoInput";
import { Map } from "../../../createProfile/pageComponent";
import axios from "axios";
import { useSelector } from "react-redux";

const EmailLabel = () => {
  const { t, emailVerifyAt } = useLadyService();

  return !emailVerifyAt ? (
    <span className="error">Email - {t("notconfirmed")}</span>
  ) : (
    <span>Email</span>
  );
};

const InfoForm = ({ data, cities, type }) => {
  const {
    email,
    telegram,
    phone,
    whatsapp,
    latitude,
    longitude,
    address: resAdress,
    city_id,
    city,
    name,
  } = data;

  const address = useSelector(getOneProfileAddress);

  const { t, dispatch, lang } = useLadyService();

  const resData = {
    email,
    whatsapp,
    telegram,
    ...(type === "customer" && { name }),
    address: resAdress,
  };

  const [valueLoad, setValueLoad] = useState(false);

  const [whatsappData, setWhatsappData] = useState(whatsapp);

  const [isActive, setIsActive] = useState(false);

  function convertData(data) {
    const result = [];
    Object.keys(data).forEach((key) => {
      const res = {};
      res[key] = {
        name: data[key]?.name,
        code: key,
        cities: data[key].cities,
      };
      result.push(res);
    });
    return result;
  }
  const citiesOptions = convertData(cities);

  const [cityError, setCityError] = useState("");
  const [formCity, setFormCity] = useState({
    id: "",
    title: "",
    icon: "",
  });

  const [geoError, setGeoError] = useState(false);

  const [bounds, setBounds] = useState("");

  const throwError = (e) => {
    if (
      !e.target.classList.contains("icon--location") &&
      !e.target.classList.contains("button--tetriary")
    ) {
      if (!selectedCity) {
        setGeoError(true);

        setTimeout(() => {
          setGeoError(false);
        }, 2000);
      }
    }
  };

  const escapeRegExp = (str) => {
    return str?.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };

  function removeSubstrings(str, substrings) {
    const escapedSubstrings = substrings.map(escapeRegExp);
    const regex = new RegExp(escapedSubstrings.join("|"), "g");
    const cleanedString = str?.replace(regex, "").replace(/\s+/g, " ").trim();
    return cleanedString;
  }

  const [currentActive, setCurrentActive] = useState("");
  const [activeCountry, setActiveCountry] = useState("cz");

  const [selectedCity, setSelectedCity] = useState(
    !!city && !!citiesOptions?.length
      ? citiesOptions
          .find((item) => activeCountry in item)
          [activeCountry].cities.filter((item) => {
            return item?.name === city || item.id === city;
          })[0]?.name
      : ""
  );

  const handleChange = (e) => {
    e.preventDefault();
    setIsActive(true);
  };

  const [isAddressActive, setIsAddressActive] = useState(false);
  const [mapMarker, setMapMarker] = useState({
    lat: latitude || null,
    lng: longitude || null,
  });

  const formSchema = object().shape({
    email: string().email().required(t("emailisnes")),
  });

  const { register, handleSubmit, setValue } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const handleLocationClick = () => {
    setIsAddressActive(true);
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(async function (position) {
        const { latitude, longitude } = position.coords;

        setMapMarker({ lat: latitude, lng: longitude });
        try {
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1&accept-language=${lang}`
          );
          const {
            city: sityRes,
            road,
            house_number,
            town,
          } = response.data.address;

          const city = sityRes || town;

          let uncorrectName = false;

          citiesOptions[0][activeCountry].cities.map((item) => {
            if (
              item.name.toLowerCase() !== city.toLowerCase() &&
              !uncorrectName
            ) {
              uncorrectName = true;
              console.log("check");
              setSelectedCity("");
              setCurrentActive("");
              setActiveCountry("cz");
              setFormCity({
                id: "",
                title: "",
                icon: "",
              });
              return null;
            }
          });

          setBounds({ lat: latitude, lng: longitude });

          const substringsToRemove = [
            "Столица",
            "Capital",
            "The Capital",
            "Hlavní město",
          ];

          if (citiesOptions?.length) {
            if (
              citiesOptions
                .find((item) => activeCountry in item)
                [activeCountry].cities.some((obj) => {
                  return (
                    obj?.name.toLowerCase() ===
                      removeSubstrings(city, substringsToRemove)
                        .trim()
                        .toLowerCase() ||
                    obj.slug.toLowerCase() ===
                      removeSubstrings(city, substringsToRemove)
                        .trim()
                        .toLowerCase()
                  );
                })
            ) {
              const currentCity = citiesOptions
                .find((item) => activeCountry in item)
                [activeCountry].cities.filter((i) => {
                  return (
                    i?.name.toLowerCase() ===
                      removeSubstrings(
                        city,
                        substringsToRemove
                      ).toLowerCase() ||
                    i.slug.toLowerCase() ===
                      removeSubstrings(city, substringsToRemove).toLowerCase()
                  );
                })[0];

              if (currentCity.id) {
                setSelectedCity(currentCity?.name);
                setCurrentActive(currentCity.id);
                setActiveCountry("cz");
                setFormCity({
                  ...currentCity,
                  icon: "CZ",
                });
              }
            }
          }

          dispatch(
            setOneProfileAddress(
              `${road} ${house_number ? ", " + house_number : ""}`
            )
          );
          dispatch(setOneProfileCoords({ latitude, longitude }));
        } catch (error) {
          console.error("Error fetching address:", error);
        }
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const onSubmit = (data, e) => {
    e.preventDefault();

    const { email: newEmail, telegram: newTelegram, name: newName } = data;

    const equalData = {
      email: newEmail,
      whatsapp: Number(String(whatsappData).replace(/[" "+]/g, "")),
      telegram: newTelegram,
      ...(type === "customer" && { name: newName }),
      // address: address[0] || resAdress,
    };

    const isFilesChanged = !_.isEqual(resData, equalData);

    const fetchData = async () => {
      try {
        const route = `user`;
        const method = "PUT";
        const payload = {
          email: newEmail,
          whatsapp: String(whatsappData).replace(/[" "+]/g, ""),
          telegram: newTelegram,
          phone,
          ...(type === "customer" && { name: newName }),
          address: address[0],
          latitude: bounds.lat,
          longitude: bounds.lng,
          city_id: currentActive,
        };

        setIsActive(false);
        showToast({
          message: t("success"),
          variant: "success",
        });

        await makeRequest({ route, method, payload });
      } catch (error) {
        const res = error.response;

        // eslint-disable-next-line default-case
        switch (res.status) {
          case 401: {
            showToast({
              message: t("noauth"),
              variant: "error",
            });
            break;
          }
          case 422: {
            showToast({
              message: t("oops"),
              variant: "error",
            });
            break;
          }
        }
      }
    };

    isFilesChanged ? fetchData() : setIsActive(false);
  };

  useEffect(() => {
    setValueLoad(false);
    setFormCity({
      id: city_id,
      title: city,
      icon: "CZ",
      name: city,
    });
    setValue("name", name);
    setValue("email", email);
    setValue("telegram", telegram);
    setValue("whatsapp", whatsappData);
    setValueLoad(true);
  }, [email, setValue, telegram, whatsappData, name, city_id, city]);

  useEffect(() => {
    if (!!citiesOptions?.length) {
      const filteredCity = citiesOptions
        .find((item) => activeCountry in item)
        [activeCountry].cities.filter(
          (city) => city?.name === city || city.id === city
        );
      if (filteredCity[0]) {
        setFormCity({ ...filteredCity[0], icon: activeCountry });
      }
    }
  }, []);

  return (
    <>
      <form
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={`info-form-${type}`}>
          {type === "customer" && (
            <InputInLabel
              type={"text"}
              id={"name_id"}
              placeholder={t("names")}
              disabled={!isActive}
              register={{ ...register("name") }}
            >
              {t("names")} ({t("nick")})
            </InputInLabel>
          )}
          <InputInLabel
            type={"tel"}
            id={"whatsappPhone"}
            placeholder={t("phonenumber")}
            disabled={!isActive}
            register={{ ...register("whatsapp") }}
            maskValue={whatsappData}
            setMaskValue={setWhatsappData}
            valueLoad={valueLoad}
          >
            {t("whatsapp")}
          </InputInLabel>
          <InputInLabel
            type={"input"}
            id={"telegram"}
            placeholder={"Telegram"}
            disabled={!isActive}
            register={{ ...register("telegram") }}
          >
            Telegram
          </InputInLabel>
          <InputInLabel
            type={"email"}
            id={"email"}
            placeholder={"Email"}
            disabled={!isActive}
            register={{ ...register("email") }}
          >
            <EmailLabel />
          </InputInLabel>
        </div>
        <div className="info-address">
          <SelectCountry
            data={citiesOptions}
            name={"city-create"}
            clazz={`${cityError ? "_error" : ""} ${
              !isActive ? "_disabled" : ""
            } size-l-forever justify-start select`}
            clazzSvg={`select__flag ${formCity?.icon || ""}`}
            arrowSize={"m"}
            type={"flag"}
            placeholder={t("yourcity")}
            defaultTitle={selectedCity || formCity?.name}
            withoutIcon={!selectedCity}
            setValue={setSelectedCity}
            currentActiveIndex={currentActive}
            setActiveCountry={setActiveCountry}
            activeCountry={activeCountry}
            setActive={setCurrentActive}
            error={cityError}
            errorMessage={<ErrorMessage message={cityError} />}
            onChange={() => {
              if (cityError) {
                setCityError("");
              }
            }}
            disabled={!isActive}
            setError={setCityError}
            setIsAddressActive={setIsAddressActive}
          />
          <div className={`search filter__map-input`}>
            <div
              className={`search filter__map-input ${
                selectedCity ? "" : "geo-error"
              } ${!isActive ? "_disabled" : ""}`}
              onClick={(e) => {
                if (isActive) {
                  throwError(e);
                }
              }}
            >
              <span className={"filter__map-title"}>{t("youraddress")}</span>
              <label
                htmlFor="geosuggest__input-create"
                className="search__label header__search"
              >
                <GeoInput
                  value={!!address ? address[0] || resAdress : null}
                  name={"location-create"}
                  id="geosuggest__input-create"
                  clazz={`header__search-input ${geoError ? "_error" : ""}`}
                  placeholder={t("fulladdress")}
                  bounds={bounds}
                />

                {geoError && <ErrorMessage message={t("cityfirst")} />}
              </label>
            </div>
            <Button
              title={t("chekmyplace")}
              onClick={handleLocationClick}
              name={"location"}
              square={true}
              buttonType={"button"}
              size={"l"}
              disabled={!isActive}
              clazz={
                isAddressActive
                  ? "search__icon button--tetriary active"
                  : "search__icon button--tetriary"
              }
            >
              <Icon size={"xl"} spritePath={"location"} />
            </Button>
          </div>
        </div>
        {!isActive ? (
          <Button
            size={"s"}
            clazz={
              "button_outline--green-accent justify-center float-right w-160 mt-12"
            }
            onClick={handleChange}
          >
            {t("edit")}
          </Button>
        ) : (
          <Button
            size={"s"}
            buttonType={"submit"}
            clazz={
              "button_outline--green-accent justify-center float-right w-160 mt-12"
            }
          >
            {t("save")}
          </Button>
        )}
      </form>
      {selectedCity && (
        <Map
          setMarkerActive={setIsAddressActive}
          markerActive={isAddressActive}
          region={cities[activeCountry].name}
          city={selectedCity}
          setBounds={setBounds}
          coords={mapMarker}
        />
      )}
    </>
  );
};

export default InfoForm;
