import example1 from "@/assets/img/examplePhotos/verExample.svg";
import { Button, Icon } from "@/components/ui";
import VerApprove from "../verApprove/VerApprove";
import WaitingAdmin from "../waitingAdmin/WaitingAdmin";

import { useEffect, useState } from "react";

import "./verAds.scss";
import ExampleImage from "../exampleImage/ExampleImage";
import moment from "moment";
import useLadyService from "@/services/LadyService";
import { FastVer } from "@/components/popups";
import UploadImage from "../uploadImage/UploadImage";
import { setVerStatus } from "@/stores/slices/lkIndiSlice";
import PhotoUploadSuccess from "../photoUploadSuccess/PhotoUploadSuccess";

const VerAds = ({
  profiles,
  verified_at,
  telegram,
  whatsapp,
  data: dataInfo = {},
  isCreatePage = false
}) => {
  const { verify_photo = {} } = dataInfo;
  

  const data = verify_photo ? verify_photo.data || {} : {};

  const { link = null, moderated } = data;
  const { t, dispatch, windowWidth } = useLadyService();

  // const currentTimestamp = new Date().getTime();

  const verified = moment.unix(verified_at).utc().format("DD.MM.YYYY");

  // const isTrue = currentTimestamp < verified_at * 1000;

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const isMobile = windowWidth < 767.98;

  const [image, setImage] = useState(link);
  
  const [removeLink, setRemoveLink] = useState(link);

  useEffect(() => {
    if (image) {
      dispatch(setVerStatus(true));
    } else {
      dispatch(setVerStatus(false));
    }
  }, [image]);

  useEffect(() => {
    if (!verify_photo?.data?.link) {
      setImage("");
      dispatch(setVerStatus(false));
    }
  }, [verify_photo]);

  useEffect(() => {
    if(link) {
      setImage(link)
      setRemoveLink(link)
    }
  }, [link])

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const formData = new FormData();
  //       formData.append("file", media[0]);
  //       formData.append("file_name", media[0].name);
  //       descValue && formData.append("description", descValue);

  //       const route = `user/${slug}/stories`;

  //       try {
  //         await axios.post(baseUrl + route, formData, {
  //           headers: {
  //             Authorization: token ? `Bearer ${token}` : "",
  //           },
  //         });
  //         setOpen(false);
  //       } catch (error) {
  //         showToast({
  //           message: t("oops"),
  //           variant: "error",
  //         });
  //       } finally {
  //         setIsLoading(false);
  //       }
  //     } catch (error) {}
  //   };

  //   fetchData();
  // }, []);

  return (
    <>
      <div id={"verification"} className="verads control">
        <div className="verads__title mt-16">
          {/* <span className="title">{t("veryourads")}</span> */}
          <div className="d-flex fd-column">
            <b className="fz-16">{t("idphoto")}</b>
            <span>{"(" + t("matchmedia") + ")"}</span>
          </div>
          {/* <Icon spritePath={"verify-fill"} size={"m"} clazz={"_green"} /> */}
          <span
            title={t("photosverified")}
            className={`${isMobile ? "" : "ml-auto"} model__icon`}
          >
            <Icon spritePath={"verify-fill"} size={"l"} />
            {t("verification")}
          </span>{" "}
        </div>
        <span className="color-600 mt-12">{t("uplyourimg")}</span>
        {/* <a href="/" className="green mt-12">
          {t("learnmoreabout")}
        </a> */}
        {!image && (
          <div className="verads__photo mt-32">
            <div className="verads__examplePhoto text-center">
              {t("example")}
              <br></br>
              {t("idphoto")}
              <img src={example1} alt={t("example")} />
            </div>
            <UploadImage
              setData={setImage}
              mainText={t("uploadphoto")}
              subText={t("photodisplay")}
              type={"is_verify"}
              setRemoveLink={setRemoveLink}
            />
          </div>
        )}

        {!!image && (
          <div className="verads__photo mt-32">
            <ExampleImage
              type={"superId"}
              image={image}
              setImage={setImage}
              removeLink={removeLink}
            />
            <PhotoUploadSuccess
              isCreatePage={isCreatePage}
              moderatedVerify={moderated}
              type={"photo"}
              at={verified_at || (new Date().getFullYear() + 1) / 1000}
            />
            {/* <WaitingAdmin /> */}
          </div>
        )}

        {/* {!!image && moderated === 0 && (
          <div className="verads__photo mt-32">
            <ExampleImage
              image={image}
              setImage={setImage}
              moderated={moderated}
            />
            <WaitingAdmin />
          </div>
        )} */}

        {/* {!!image && moderated === 1 && (
          <div className="verads__photo mt-32">
            <VerApprove
              spritePath={"verify-fill"}
              textGreen={t("verification")}
              text={`${t("forfewads")} ${verified}`}
            />
          </div>
        )} */}

        <div className={"verads__secure"}>
          <Icon size={"4xl"} spritePath={"secure"} />
          {t("confyourimg")}
        </div>
        <div className="d-flex justify-sb align-center justify-center mt-16">
          <span className="fz-20 color-main">{t("otherver")}:</span>
          <Button
            title={t("byvideocall")}
            clazz={"button--primary justify-center"}
            size={"m"}
            onClick={handleOpen}
          >
            {t("verification") + " " + t('byvideocall')}
            <span className="verads__ten">
              10{" "}
              <Icon title={t("EroCoins")} spritePath={"erocoin"} size={"s"} />
            </span>
          </Button>
        </div>
      </div>

      {open &&
        <FastVer
          whatsapp={whatsapp}
          telegram={telegram}
          open={open}
          setOpen={setOpen}
          profiles={profiles}
        />
      }
    </>
  );
};

export default VerAds;
