import { Checkbox, Skeleton } from "@/components/ui";
import React, { useEffect, useState } from "react";

import "./history.scss";
import { makeRequest } from "@/services/makeRequest";
import GirlCard from "../../cards/girlCard/GirlCard";
import useTitle from "@/hooks/useTitle";
import {
  setAuthorisationAccessType,
  setAuthorizationStatus,
} from "@/stores/slices/popupSlice";
import { Button, Dropdown } from "../../ui";
import useLadyService from "@/services/LadyService";
import { parseJSON } from "@/helper/parseJSON";
import moment from "moment";
import showToast from "../../toast/Toast";

const History = () => {
  const { t, token, windowWidth, userType, dispatch, lang, scrollToTop } = useLadyService();

  const [isLoading, setIsLoading] = useState(false);
  const favoriteCheckbox = {
    main_title: windowWidth > 479.98 ? t("more") : t("smartfilter"),
    options: [
      {
        id: 23,
        title: t("favorites"),
        name: "favorites",
        value: "favorites",
        checked: false,
        disabled: false,
      },
      {
        id: 22,
        title: t("views"),
        name: "visit",
        value: "visit",
        checked: false,
      },
      {
        id: 33,
        title: t("i-notes"),
        name: "note",
        value: "note",
        checked: false,
      },
      {
        id: 44,
        title: t("reviews"),
        name: "review",
        value: "review",
        checked: false,
      },
      {
        id: 66,
        title: t("i-complaint"),
        name: "ticket",
        value: "ticket",
        checked: false,
      },

      {
        id: 160,
        title: t("videochats"),
        name: "call",
        value: "call",
        checked: false,
      },
      {
        id: 181,
        title: t("erocontent"),
        name: "ero",
        value: "ero",
        checked: false,
      },
      {
        id: 55,
        title: t("myorder"),
        name: "booking",
        value: "booking",
        checked: false,
      },
      // {
      //   id: 77,
      //   title: t("demos"),
      //   name: "watch",
      //   value: "watch",
      //   checked: false,
      // },
    ],
  };

  const [activeIndex, setActiveIndex] = useState("favorites");

  const handleCheckbox = (type) => {
    if (!token && type !== "favorites") {
      dispatch(setAuthorizationStatus(true));
      dispatch(setAuthorisationAccessType("interaction"));
      return;
    }
    setActiveIndex(type);
  };

  const [data, setData] = useState([]);
  const [emptyData, setEmptyData] = useState(false);

  console.log('data', data);
  

  const dataForDefault = JSON.parse(localStorage.getItem("favorites"));

  const deleteBooking = async (id, slug) => {
      try {
        const route = `bookings/${id}/cancel`;
        const method = "PUT";

        const result = await makeRequest({ route, method });

        if (result) {
          setData(prev => prev.filter(item => item.profile.slug !== slug));
          showToast({
            message: t('success'),
            variant: "success",
        });
        }
      } catch (error) {
        showToast({
          message: t("oops"),
          variant: "error",
        });
        console.log(error);
      }
  }
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(false);
      setEmptyData(false);
      setData([]);
      try {
        setIsLoading(false);
        const route = "user/favorites";
        const method = "GET";

        const result = await makeRequest({ route, method });

        if (result) {
          setData(result.data);
        }
        setIsLoading(true);
      } catch (error) {
        setIsLoading(true);
      }
    };

    token && lang && activeIndex === "favorites" && fetchData();
  }, [lang, activeIndex]);

  useEffect(() => {
    const fetchData = async () => {
      setEmptyData(false);
      const routeType = "profiles";
      try {
        setIsLoading(false);
        if (!!!dataForDefault?.length) {
          setEmptyData(true);
          setIsLoading(true);
          return;
        }
        const route = routeType;
        const method = "GET";
        const payload = {
          ...(activeIndex === "favorites" && {
            slug: dataForDefault?.join(","),
          }),
        };

        const result = await makeRequest({ route, method, payload });

        if (result) {
          setData(result.profiles);
          setIsLoading(true);
        }
      } catch (error) {}
    };

    !token && lang && fetchData();
  }, [token, lang, activeIndex]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(false);
      setData([]);
      setEmptyData(false);

      try {
        const route = `user/actions/${activeIndex}`;
        const method = "GET";

        const result = await makeRequest({ route, method });

        if (result) {
          setData(result);
        }
        if (!result.length) {
          setEmptyData(true);
        }

        setIsLoading(true);
      } catch (error) {
        setIsLoading(true);
      }
    };

    activeIndex !== "favorites" && fetchData();
  }, [activeIndex]);

  const skeletons = [...Array(12)].map((_, index) => <Skeleton key={index} />);

  const getTime = (date) => {
    return moment.unix(date).format("DD.MM.YYYY HH:mm");
  };

  const isLessThanOneHourAgo = (timestamp) => {
    const currentTime = Math.floor(Date.now() / 1000);
    const oneHourAgo = currentTime - 3600;

    if (timestamp >= oneHourAgo) {
      return true;
    } else {
      return false;
    }
  };

  const checkboxNavTemplate = (i, index) => (
    <Checkbox
      key={i.id}
      id={i.id}
      checked={i.value === activeIndex}
      title={i.title}
      value={i.value}
      name={i.name}
      onChange={() => handleCheckbox(i.value)}
    />
  );
  useTitle(t("adsinteraction"));

  useEffect(() => {
    scrollToTop()
  }, [])

  return (
    <main>
      <section className="navigation__container">
        <h1>{userType === "indi" ? t("favorites") : t("adsinteraction")}</h1>

        {userType === "indi" ? null : (
          <nav className="navigation__nav">
            {favoriteCheckbox.options.map((i, index) =>
              checkboxNavTemplate(i, index)
            )}

            {windowWidth < 1200 &&
              <Dropdown
                title={favoriteCheckbox.main_title}
                size={"xs"}
                clazzWrapper={"navigation__more"}
                leftIcon={windowWidth < 479.98 && "filter2"}
              >
                {favoriteCheckbox.options.map((i, index) =>
                  checkboxNavTemplate(i, index)
                )}
              </Dropdown>
            }
          </nav>
        )}

        <div className="grid-cards">
          {isLoading &&
            !emptyData &&
            data.map((data, index) => {
              let parsedObject;
              if (activeIndex === "booking" && !data.slug) {
                parsedObject = parseJSON(data.data);
                console.log(`${data.profile.name}`, parsedObject?.data);
              }
              return userType === "indi" ? (
                <GirlCard
                  props={data.slug ? data : data.profile}
                  key={index}
                  big
                />
              ) : (
                <div className={"favorites-page__card"}>
                  {activeIndex === "booking" && (
                    <div className={"favorites-page__card-box"}>
                      <span className={"color-600"}>
                        {getTime(parsedObject?.data?.book_at)}
                      </span>
                      {/* <span className={"color-main"}>{t("orderword")}</span> */}

                      {isLessThanOneHourAgo(parsedObject?.data?.book_at) && (
                        <Button clazz={"button--secondary"} size={"xs"} onClick={() => deleteBooking(parsedObject?.data.id, data.profile.slug)}>
                          {t("cancel")}
                        </Button>
                      )}
                    </div>
                  )}
                  <GirlCard
                    props={data.slug ? data : data.profile}
                    key={data.id}
                    big
                  />
                </div>
              );
            })}
          {isLoading && emptyData && <h3>{t("emptyrecord")}</h3>}
          {!isLoading && skeletons}
        </div>
        {/* {activeIndex !== 0 && (
          <h3 className="text-center mt-12">{t("soon")}</h3>
        )} */}
      </section>
    </main>
  );
};
export default History;
