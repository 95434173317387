import {
  BurgerMenu,
  DropdownLanguage,
  HeaderTabs,
  InstructionLink,
  Logo,
  MenuNavBar,
  UserWrapperDesktop,
  SupportButton
} from "../components";
import {Burger, ButtonLink, Icon, SwitchTheme} from "../../ui";
import useLadyService from "@/services/LadyService";
import TogglesOrLinks from "../components/TogglesOrLinks";


const AgencyLK = () => {
  const { windowWidth,  t } = useLadyService();

  if (windowWidth > 1199.98)
    return (
      <>
        <div className="header__top">
          <ButtonLink
            href={"/lk/photographer"}
            title={t("photographer")}
            size={"xs"}
            clazz={"add-form button_outline--green _no-underline"}
          >
            <Icon size={"m"} spritePath={"camera"} />
            {t("photographer")}
          </ButtonLink>

          <InstructionLink/>

          <TogglesOrLinks/>

          <DropdownLanguage/>

          <SwitchTheme type={"switch"} />
        </div>

        <div className="header__bottom">
          <Logo/>

          <div className={"header__tabs"}>
            <HeaderTabs/>
          </div>

          <UserWrapperDesktop/>
        </div>
      </>
    );

  if (windowWidth > 767.98)
    return (
      <>
        <div className="header__top">
          <Logo/>

          <div className="user__wrapper">
            <TogglesOrLinks/>

            <SupportButton/>

            <Burger />

            <SwitchTheme type={"switch"} />

            <BurgerMenu lk/>
          </div>
        </div>
      </>
    );

  return (
    <>
      <div className="header__top">
        <div className="user__wrapper">
          <Burger />

          <DropdownLanguage/>
        </div>

        <Logo/>

        <div className="user__wrapper">
          <SupportButton/>

          <SwitchTheme type={"switch"} />
        </div>

        <BurgerMenu lk/>

        <MenuNavBar/>
      </div>

      <div className="header__bottom">
        <TogglesOrLinks/>
      </div>
    </>
  );
}

export default AgencyLK