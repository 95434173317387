import useLadyService from "@/services/LadyService"
import QRCodeGenerator from "../QRcode/QRcodeGenerator";

export const BankBlock = ({currency}) => {
    const {t, userId} = useLadyService();
    const currencyBankNumber = {
        czk: "1641093002/2700",
        eur: "1641093010/2700",
      }
      const currencyIban = {
        czk: "CZ8827000000001641093002",
        eur: "CZ6627000000001641093010",
      }
  return (
    <div
        className={`d-flex justify-sb mt-16 topup-pay__details`}
      >
        <div className="model__params">
          <p className="p1 color-main grid-col-2">{t("sendtoreq")}</p>
          <span className="model__param">
            {t("numberofacc")}
            <span>{currencyBankNumber[currency]}</span>
          </span>
          <span className="model__param">
            {t("masterofacc")}
            <span>IT4L s.r.o.</span>
          </span>
          <span className="model__param">
            IBAN
            <span>{currencyIban[currency]}</span>
          </span>
          <span className="model__param">
            BIC/SWIFT
            <span>BACXCZPP</span>
          </span>
          <span className="model__param">
            {t("idofpayment") + " (varsymbol)"}
            <b className="fz-15">{userId}</b>
          </span>
          <span className="color-green grid-col-2">{t("varsymbol")}</span>
        </div>
        <div
          className={`height-fit d-flex gap-8 align-center`}
        >
          <QRCodeGenerator currency={currency} iban={currencyIban[currency]} />
          <div className={`d-flex fd-column min-w-160`}>
            <b className="fz-16">{t("qrpay")}</b>
            <span className="mt-8">{t("qrcode")}</span>
          </div>
        </div>
      </div>
  )
}
