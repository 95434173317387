import { Button, Icon } from "@/components/ui";
import { makeRequest } from "@/services/makeRequest";
import showToast from "../../toast/Toast";
import useLadyService from "@/services/LadyService";
import { Popup } from "../index";

const ActivateProfilePopup = (props) => {
  const { open, setOpen, setStatus, status } = props;

  const { t } = useLadyService();

  const handleCloseDelete = () => {
    setOpen(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const fetchData = async () => {
      try {
        const route = `user/set-profiles-status`;
        const method = "PUT";
        const payload = {
          status: !status,
        };

        await makeRequest({ route, method, payload });
        setStatus((prev) => !prev);
        setOpen(false);

        showToast({
          message: t("success"),
          variant: "success",
        });
      } catch (error) {
        setStatus((prev) => !prev);
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };

    fetchData();
  };

  return (
    <Popup
      open={open}
      setOpen={handleCloseDelete}
      clazz={"gap-16"}
      id={"activateProfile"}
      container={"div"}
    >
      <h3 className="m-12-24">{t("realywant")}</h3>
      <div className="popup-form__inner text-center">
        <span className={"color-700"}>{t("alladsdeactivate")}</span>
      </div>

      <div className="popup-form__buttons">
        <Button
          size={"l"}
          clazz={"button--green justify-center"}
          onClick={handleCloseDelete}
        >
          {t("cancel")}
        </Button>

        <Button
          size={"l-forever"}
          buttonType={"submit"}
          clazz={"button--error justify-center"}
          onClick={onSubmit}
        >
          {t("yes")}, {t("deactivate")}
        </Button>
      </div>
    </Popup>
  );
};

export default ActivateProfilePopup;
