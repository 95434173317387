import { Link } from "react-router-dom";
import React, { useRef, useState } from "react";

import { Button, ButtonLink, Icon, RatingStars } from "@/components/ui";
import { setStoriesMap } from "@/stores/slices/popupSlice";
import { setOneModelPosition } from "@/stores/slices/mapModelSlice";
import useLadyService from "@/services/LadyService";

import GirlCardButtons from "./components/GirlCardButtons";
import GirlCardSlider from "./components/GirlCardSlider";
import GirlCardIconsAndTags from "./components/GirlCardIconsAndTags";

import "./girlCard.scss";

const GirlCard = ({ props, big, checkbox = false, clazz, edit, status, targetBlank = false }) => {
  const {
    gender,
    orientation,
    id,
    slug,
    name,
    rating,
    address,
    city,
    age,
    height,
    weight,
    breast,
    penis_size,
    prices,
    languages,
    services,
    is_online,
    is_individual,
    is_top,
    is_elite,
    is_escort,
    is_healthy,
    is_verified,
    is_pornstar,
    is_new,
    tag_video,
    tag_friends,
    tag_booking,
    tag_bdsm,
    ready_travel,
    count_photos,
    count_videos,
    photos,
    is_favorite,
    healthy_at,
    verified_at,
    has_ero,
    price,
    main_photo,
    media,
    statistic = {},
  } = props;

  const { favorites } = statistic;

  const { windowWidth, dispatch, setMediaSize, t, travelVariants } = useLadyService();

  const toggleMapOpen = (slug) => {
    dispatch(setOneModelPosition(slug));
    dispatch(setStoriesMap(true));
  };

  const linkRef = useRef(null);

  const [isHover, setIsHover] = useState(false);

  const setServices = Array.from(new Set(services));

  let newArray =
    !!services?.length && !services[0]["category_id"]
      ? setServices
      : !!services?.length
      ? services
          .map((category) => {
            return category.services.map((service) => service.slug);
          })
          .flat()
      : [];

  const postAltphoto = t("altphoto").replace(/%([^%]+)%/g, name);

  const ParamTemplate = (translateKey, param, decimal) => (
    <span className={"girl-card__param"}>
      <span>{t(translateKey)}</span>
      {`${param} ${decimal ? t(decimal) : ""}`}
    </span>
  );

  const PriceTemplate = (clazz, translateKey, price) => (
    <span className={`girl-card__price--${clazz}`}>
      <span>{t(translateKey)}</span>
      {price}€
    </span>
  );

  const IconTemplate = (translateKey, icon) => {
    let clazz = translateKey === "erocontent" ? is_top? "" : "color-green" : "";
    let travelTitle = translateKey === "travel" ? `: ${travelVariants[ready_travel]}` : "";
    return (
      <Icon
        square
        clazz={clazz}
        title={`${t(translateKey)}${travelTitle}`}
        spritePath={icon}
        size={"l"}
      />
    );
  };

  const link = big ? `/profile/${slug}` : `/lk/indi/profile/${slug}/edit`;

  const topClass = is_top ? " girl-card--top" : "";

  const eliteClass = is_elite ? " girl-card--elite" : "";

  const statusClass = edit ? (!status ? " not_active" : "") : "";

  const setBigClass =
    (windowWidth < 767.98 && windowWidth !== 0) || big ? " girl-card__big" : "";

  const setClass = clazz ? ` ${clazz}` : "";

  const classes = `girl-card${topClass}${eliteClass}${statusClass}${setBigClass}${setClass}`;

  const photo = !!main_photo
    ? main_photo
    : !!media?.photo?.length
    ? media.photo[0]
    : null;

  return (
    <div className={classes}>
      <div className={`girl-card__wrapper`}>
        {edit ? (
          <>
            <div
              className="girl-card__slider _edit"
              onMouseLeave={() => setIsHover(false)}
              onMouseEnter={() => setIsHover(true)}
            >
              <Button clazz={`girl-card__favorite`}>
                <span className="color-green">{favorites}</span>
                <Icon size={"l"} spritePath={"favorite-fill"} />
              </Button>

              <ButtonLink
                target={'_blank'}
                size={"xs"}
                href={`/lk/indi/profile/${slug}/edit`}
                title={t("edit") + " " + t("onpage") + " " + t("myads")}
                clazz={`button--secondary girl-card__edit`}
              >
                <Icon spritePath={"edit-2"} size={"xs"} />
                {t("edit")}
              </ButtonLink>

              <div
                onClick={() => linkRef.current.click()}
                className="swiper-slide girl-card__slide"
              >
                {!!photo ? (
                  <img
                    src={
                      big
                        ? windowWidth < 599.98
                          ? setMediaSize(photo, "s")
                          : setMediaSize(photo, "m")
                        : setMediaSize(photo, "s")
                    }
                    alt={postAltphoto}
                  />
                ) : null}
              </div>

              <div className="girl-card__descr">
                <div className="girl-card__tags-wrapper">
                  <GirlCardIconsAndTags
                    props={{
                      gender,
                      orientation,
                      is_individual,
                      is_healthy,
                      is_verified,
                      is_pornstar,
                      is_new,
                      tag_bdsm,
                      healthy_at,
                      verified_at,
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <GirlCardSlider
            isHover={isHover}
            setIsHover={setIsHover}
            checkbox={checkbox}
            linkRef={linkRef}
            props={{
              gender,
              orientation,
              id,
              slug,
              name,
              is_healthy,
              is_verified,
              count_photos,
              count_videos,
              photos,
              is_favorite,
              has_ero,
              is_individual,
              is_new,
              tag_bdsm,
              is_pornstar,
              verified_at,
              healthy_at,
              big,
            }}
          />
        )}

        <div className="girl-card__info-wrapper">
          <div className="girl-card__age">
            {age} <span>{t("years")}</span>
          </div>

          <div className="girl-card__info">
            <Link
              target={targetBlank && "_blank"}
              to={edit ? link : `/profile/${slug}`}
              ref={linkRef}
              onMouseLeave={() => setIsHover(false)}
              onMouseEnter={() => setIsHover(true)}
              title={
                is_online ? `${name} ${t("online")}` : `${name} ${t("offline")}`
              }
              className={`girl-card__name${isHover ? " _hover" : ""}${
                is_online ? " _online" : " _offline"
              }`}
            >
              {name}
            </Link>

            {!!address ? (
              <Link
                to={"#"}
                title={t("onmap")}
                className="girl-card__address"
                onClick={() => toggleMapOpen(slug)}
              >
                <Icon spritePath={"map"} size={"xs"} />
                {city}, {address}
              </Link>
            ) : !!city ? (
              <span title={t("onmap")} className="girl-card__address">
                <Icon spritePath={"map-pin"} size={"xs"} />
                {city}
              </span>
            ) : null}
          </div>
        </div>

        <div className="girl-card__group">
          {!!height || !!weight || !!breast || !!penis_size ? (
            <div className="girl-card__params">
              {!!height && ParamTemplate("height", height, "cm")}
              {!!weight && ParamTemplate("weight", weight, "kg")}
              {!!penis_size && ParamTemplate("penissize", penis_size)}
              {!!breast && ParamTemplate("breastsize", breast)}
            </div>
          ) : null}

          <div className="girl-card__modes">
            <GirlCardIconsAndTags
              props={{
                gender,
                orientation,
                is_individual,
                is_healthy,
                is_verified,
                is_pornstar,
                is_new,
                tag_bdsm,
                healthy_at,
                verified_at,
              }}
            />

            {!!tag_video ? IconTemplate("video", "video-play") : null}
            {!!tag_friends ? IconTemplate("girlfriend", "girlfriend") : null}
            {!!has_ero ? IconTemplate("erocontent", "pants") : null}
            {!!tag_booking ? IconTemplate("onlineorder", "pay-content") : null}
            {!!ready_travel ? IconTemplate("travel", "travel") : null}
            {!!is_escort ? IconTemplate("escort", "escort") : null}

            {!!newArray.length
              ? newArray.map((item) => {
                  return <span key={item}>{t(`${item}-s`)}</span>;
                })
              : null}
          </div>
        </div>

        {!!rating || !!languages?.length ? (
          <div className="girl-card__advantages">
            {!!rating ? <RatingStars value={rating} readOnly={true} /> : null}

            {!!languages.length ? (
              <div className="girl-card__speak">
                <span>{t("ispeak") + ":"}</span>
                {languages.map((lang, index) => {
                  const key = Object.keys(lang)[0];

                  return (
                    <Icon
                      key={index}
                      title={lang[key].name}
                      type={"flag"}
                      spritePath={lang[key].code.toUpperCase()}
                    />
                  );
                })}
              </div>
            ) : null}
          </div>
        ) : null}

        <div className="girl-card__prices">
          {edit && !!price?.incall
            ? PriceTemplate("private", "incall", price?.incall)
            : !!prices?.incall && !edit
            ? PriceTemplate("private", "incall", prices?.incall)
            : null}

          {edit && !!price?.outcall
            ? PriceTemplate("escort", "outcall", price?.outcall)
            : !!prices?.outcall && !edit
            ? PriceTemplate("escort", "outcall", prices?.outcall)
            : null}

          {edit && !!price?.escort
            ? PriceTemplate("accompaniment", "accompaniment", price?.escort)
            : !!prices?.escort && !edit
            ? PriceTemplate("accompaniment", "accompaniment", prices?.escort)
            : null}
        </div>

        <GirlCardButtons props={props} edit={edit} />
      </div>
    </div>
  );
};

export default GirlCard;
