import { useState, useRef } from "react";
import showToast from "@/components/toast/Toast";
import { Icon } from "@/components/ui";
import axios from "axios";
import baseUrl from "@/services/apiConfig";
import useLadyService from "@/services/LadyService";

const UploadImage = ({
  setData,
  mainText,
  subText,
  infoText,
  isMultiLoad = false,
  setFiles,
  type,
  pdf = false,
  setRemoveLink,
}) => {
  const { token, t } = useLadyService();
  const [onDrag, setOnDrag] = useState(false);

  const fileInputRef = useRef(null);
  const multiResult = [];

  function loadImage(url) {
    if (!isMultiLoad) {
      let datas = [];
      datas.push(url);

      setData(datas);
    } else {
      multiResult.push(url);
      setData(multiResult);
    }
  }

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    setOnDrag(false);
  };
  const handleDragEnter = (e) => {
    e.preventDefault();
    setOnDrag(true);
  };

  const handleFileChange = async (e)  => {
    if (e.target.files) {
      const selectedFile = e.target.files[0];

      const acceptsFiles = pdf
        ? ["image/jpeg", "image/png", "image/webp"].includes(
            selectedFile.type
          ) || selectedFile.type === "application/pdf"
        : selectedFile.type.startsWith("image/") &&
          ["image/jpeg", "image/png", "image/webp"].includes(selectedFile.type);

      if (acceptsFiles) {
        if (selectedFile.type === "application/pdf") {
          setData("pdf");
        } else {
          const imageUrl = URL.createObjectURL(selectedFile);
          loadImage(imageUrl);
        }

        try {
          const formData = new FormData();
          formData.append("file", selectedFile);
          formData.append("file_name", selectedFile.name);
          const data = await axios.post(baseUrl + `user/media`, formData, {
            headers: {
              Authorization: token ? `Bearer ${token}` : "",
              "Content-Type": "multipart/form-data",
            },
            params: {
              ...(type === "is_verify" && { is_verify: true }),
              ...(type === "is_super_verify" && { is_super_verify: true }),
              ...(type === "is_healthy" && { is_healthy: true }),
            },
          });
          const link = data.data.link;
          setRemoveLink(link)
        } catch (error) {}

        if (isMultiLoad) {
          loadImage(null);
          const file = {
            selectedFile: selectedFile,
            type: type,
          };

          setFiles((prev) => [...prev, file]);
        }
      } else {
        const acceptsFilesMessage = `${t('accepttypes')}: ${`jpg, jpeg, png, webp, ${
          pdf ? "pdf" : ""
        }`}`;
        showToast({
          message: acceptsFilesMessage,
          variant: "error",
        });
        e.target.value = "";
      }
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (!isMultiLoad) {
      if (files && files.length > 0) {
        const droppedFile = files[0];
        const imageUrl = URL.createObjectURL(droppedFile);
        loadImage(imageUrl);
      }
    } else {
      if (files && files.length > 0) {
        const droppedFiles = Array.from(files);
        const length = droppedFiles.length;
        droppedFiles.forEach((file, index) => {
          if (
            file.type.startsWith("image/") &&
            ["image/jpeg", "image/png", "image/webp"].includes(file.type)
          ) {
            const droppedFile = files[index];
            const imageUrl = URL.createObjectURL(droppedFile);

            loadImage(imageUrl);

            if (index + 1 === length) {
              loadImage(null);
            }

            const result = {
              selectedFile: droppedFile,
              type: type,
            };

            setFiles((prev) => [...prev, result]);
          } else {
            showToast({
              message: `Допускаемый формат: ${"jpg, jpeg, png, webp"}`,
              variant: "error",
            });
            e.target.value = "";
          }
        });
      }
    }
  };

  return (
    <div
      className={`upload__zone ${onDrag ? "drag" : ""}`}
      onClick={handleClick}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div className="upload__zone__main">
        <Icon clazz={"_green"} size={"xl"} spritePath={"plus-1"} />
        <span className="p1 color-main mb-12">{mainText}</span>
        {subText && <span className="text-center">{subText}</span>}
        {infoText && (
          <span className="color-green text-center">{infoText}</span>
        )}
      </div>

      <input
        ref={fileInputRef}
        type="file"
        onChange={handleFileChange}
        className={`inputDisplay ${type === 'is_healthy' ? "health-input" : ""}`}
      />
    </div>
  );
};

export default UploadImage;
