import { Link } from "react-router-dom";
import useTitle from "../../hooks/useTitle";

const ErrorPage = () => {
  useTitle("404 - Page Not Found");
  return (
    <div className="error-page__container text-center">
      <p>Sorry, an unexpected error has occurred.</p>
      <Link to={`/`}>Go to main page</Link>
    </div>
  );
};

export default ErrorPage;
