import React, { useEffect, useRef, useState } from "react";
import Icon from "../icon/Icon";
import "./select.scss";
import { setAllModels, setStories } from "@/stores/slices/modelsState";
import { useTranslation } from "react-i18next";
import Input from "../input/Input";
import { useSelector } from "react-redux";
import {
  cleanSorted,
  setPageDefault,
  setSearchValue,
  setSortedModelsByFilter,
} from "@/stores/slices/modelsState";
import { resetAll, setSearchFilter } from "@/stores/slices/filterSlice";
import { resetAllCounters } from "@/stores/slices/counterFilterSlice";
import { useNavigate } from "react-router-dom";
import { setCity, setCurrentCity } from "@/stores/slices/userSlice";
import { clearTitle } from "@/stores/slices/titleSlice";
import useCityChange from "../../../hooks/useCityChange";
import { getUserLang } from "../../../stores/slices/userSlice";
import { showFilter } from "../../../stores/slices/showFilterSlice";
import {
  fetchRanges,
  resetDefaultRange,
} from "../../../stores/slices/filterSlice";
import { setProfileCount } from "../../../stores/slices/mapModelSlice";
import useLadyService from "../../../services/LadyService";

const SelectCountry = ({ ...props }) => {
  const {
    arrowSize,
    clazz,
    clazzSvg,
    clazzWrapper,
    name,
    subTitle = "",
    cutTitle,
    setValue = undefined,
    onChange = false,
    paramsOnChange = false,
    defaultTitle = "",
    withoutIcon = false,
    withoutTitle,
    withoutArrow,
    setActive,
    setIsAddressActive,
    data,
    activeCountry,
    setActiveCountry = false,
    error,
    errorMessage,
    setError,
    isMain = false,
    open = false,
    disabled = false,
    placeholder,
    required,
  } = props;

  const transformedArray =
    data.length > 0 &&
    data.flatMap((item) =>
      Object.entries(item).map(([code, { name }]) => ({ code, name }))
    );
  const { dispatch, t } = useLadyService();
  const navigate = useNavigate();
  const toggleCountry = (code) => {
    setIsOpen(true);
    setActiveCountry && setActiveCountry(code);
    setCode(code.toUpperCase());
  };

  let [title, setTitle] = useState("");

  const [code, setCode] = useState("CZ");

  useEffect(() => {
    setTitle(defaultTitle);
  }, [defaultTitle]);

  let [searchVal, setSearchVal] = useState("");

  let [mainIcon, setMainIcon] = useState("CZ");

  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(open);
  }, [open]);
  const selectRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
      event.stopPropagation();
    }
  };

  const changeCity = useCityChange();
  const lang = useSelector(getUserLang);
  const filterValue = useSelector(showFilter);

  function setValues(i, paramsOnChange) {
    setTitle(i.name);
    setMainIcon(code);
    !!setValue && setValue(i.name);
    if (onChange) {
      if (paramsOnChange) {
        onChange(i, paramsOnChange);
      } else {
        onChange(i);
      }
    }
    if (isMain) {
      changeCity(i.slug);
      dispatch(
        setCurrentCity({
          id: i.id,
          title: i.name,
          icon: activeCountry,
          highlight: i.highlight,
          slug: i.slug,
        })
      );
      dispatch(resetDefaultRange());
      if (!filterValue) {
        navigate(`/${lang}/${i.slug}`);
        dispatch(setPageDefault());
        dispatch(setAllModels([]));
        dispatch(setStories([]));
        dispatch(resetAllCounters());
        dispatch(setCity(i.slug));
        // if (!filterOpen) {
        //   dispatch(setAllModels([]));
        // }
        dispatch(setProfileCount(i.profiles_count));
        dispatch(resetAll());
        dispatch(setSearchFilter(false));
        dispatch(cleanSorted());
        dispatch(setSearchValue(""));
        dispatch(setSortedModelsByFilter(""));
        dispatch(clearTitle());
      } else {
        dispatch(fetchRanges(i.id));
      }
    }
  }

  const handleClick = (i, e) => {
    e.stopPropagation();

    if (setIsAddressActive) {
      setIsAddressActive(false);
    }
    if (setError) {
      setError("");
    }
    setValues(i, paramsOnChange);
    setActive(i.id);
    setIsOpen(false);
  };

  if (!activeCountry) {
    return;
  }

  const searchedData = !!data.length
    ? data[0][activeCountry.toLowerCase()]?.cities.filter((item) =>
        searchVal
          ? item.name.toLowerCase().includes(searchVal.toLowerCase())
          : item
      )
    : [];

  if (!!searchedData.length) {
    const highlightIndex = searchedData.findIndex(
      (item) => item.highlight === 2
    );
    const highlightedItem = searchedData.splice(highlightIndex, 1)[0];

    searchedData.sort((a, b) => a.name.localeCompare(b.name));

    searchedData.unshift(highlightedItem);
  }

  return (
    <div
      className={`select-countries${required ? " _required" : ""}${
        isOpen ? " select_active" : ""
      }${clazzWrapper ? ` ${clazzWrapper}` : ""}`}
      ref={selectRef}
    >
      <button
        type={"button"}
        className={`select__button${clazz ? ` ${clazz}` : ""}${
          disabled ? "_disabled" : ""
        }${clazzWrapper ? ` ${clazzWrapper}` : ""}`}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {placeholder ? (
          title !== "" ? (
            <span className={"select__subtitle"}>{placeholder}</span>
          ) : (
            subTitle && <span className="select__placeholder">{subTitle}</span>
          )
        ) : null}

        {!withoutIcon ? (
          <Icon
            type={"flag"}
            spritePath={mainIcon}
            clazz={`select__icon${clazzSvg ? ` ${clazzSvg}` : ""}`}
          />
        ) : null}

        {!withoutTitle && (
          <span className={cutTitle ? "text-dots" : ""}>{title}</span>
        )}

        {!withoutArrow ? (
          <Icon
            clazz={"select__arrow"}
            size={arrowSize}
            spritePath={"chevrone-down"}
          />
        ) : null}

        {error && errorMessage}
      </button>

      <div className={`select__body`} onClick={(e) => e.stopPropagation()}>
        <div className="select__body-left">
          {!!transformedArray.length &&
            transformedArray.map((i) => {
              return (
                <div
                  onClick={() => toggleCountry(i.code)}
                  className={`checkbox`}
                  key={i.code}
                >
                  <Icon type={"flag"} spritePath={i.code.toUpperCase()} />
                  <span>{i.name}</span>
                </div>
              );
            })}
        </div>

        <div className="select__body-right">
          <label htmlFor={`${name}-search`} className="select__search">
            <Input
              type="search"
              placeholder={t("searchfield")}
              id={`${name}-search`}
              clazz={"search__input"}
              name={`${name}-search`}
              value={searchVal}
              setValue={setSearchVal}
            />
          </label>

          <div className="dropdown__search-select">
            {!!data.length && !!searchedData?.length ? (
              searchedData.map((i, index) => {
                return (
                  <button
                    key={index + 1}
                    type={"button"}
                    onClick={(e) => handleClick(i, e)}
                    className={`select__item ${
                      i?.highlight === 2 ? "p1" : ""
                    } ${i.highlight === 0 ? "" : ""}`}
                  >
                    {i.highlight === 2 || i.highlight === 1 ? (
                      <b>{i.title || i.name}</b>
                    ) : (
                      i.title || i.name
                    )}
                  </button>
                );
              })
            ) : (
              <>{t("nosearchcountry")}</>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectCountry;
