import { Icon } from "../index";
import { useRef, useState, useEffect } from "react";

import "./checkbox.scss";

const Checkbox = ({ ...props }) => {
  const {
    id,
    name,
    title,
    checked,
    disabled,
    value,
    linkCheckbox,
    buttonCheckbox,
    clazz,
    border = true,
    flag,
    onChange,
    onClick,
    subTitle = "",
    readOnly,
    icon,
    iconSize = "",
    iconClazz,
    radio,
    onKeyUp,
    children,
  } = props;

  let defauldCheckboxProps = {
    type: "checkbox",
    tabIndex: 1,
    disabled: disabled,
    id: id,
    name: name,
    onChange: onChange,
    checked: checked,
    value: value,
    readOnly: readOnly,
  };

  if (linkCheckbox) {
    return (
      <a
        className={`checkbox${checked ? " checkbox_checked" : ""}${
          disabled ? " checkbox_disabled" : ""
        }`}
        tabIndex={disabled ? null : 0}
        onClick={disabled ? null : onClick}
        onKeyUp={onKeyUp}
        href="/"
      >
        <input {...defauldCheckboxProps} />
        <label htmlFor={id} onClick={() => (disabled ? null : onClick)}>
          {title}
        </label>
      </a>
    );
  }

  if (buttonCheckbox) {
    return (
      <label
        className={`checkbox-button${
          disabled ? " checkbox-button_disabled" : ""
        }${checked ? " checkbox-button_checked" : ""}${
          clazz ? ` ${clazz}` : ""
        }`}
        htmlFor={id}
        tabIndex={0}
        onKeyUp={onKeyUp}
      >
        <input onClick={disabled ? null : onClick} {...defauldCheckboxProps} />
        {title}
      </label>
    );
  }

  return (
    <div
      className={`checkbox${checked ? " checkbox_checked" : ""}${
        disabled ? " checkbox_disabled" : ""
      }${border ? `` : ` _no-border`}${clazz ? ` ${clazz}` : ""}`}
      onClick={disabled ? null : onClick}
      onKeyUp={onKeyUp}
    >
      <input {...defauldCheckboxProps} className={`${radio ? " radio" : ""}`} />
      <label htmlFor={id}>
        {!!flag ? (
          <Icon icon type="flag" spritePath={`${flag.toUpperCase()}`} />
        ) : null}
        {icon && (
          <Icon icon size={iconSize} spritePath={`${icon}`} clazz={iconClazz} />
        )}
        {title}
        {children}
        {subTitle && typeof subTitle === "string" ? (
          <span>{subTitle}</span>
        ) : !!subTitle ? (
          subTitle
        ) : null}
      </label>
    </div>

    // <label
    //   htmlFor={id}
    //   className={`checkbox${checked ? " checkbox_checked" : ""}${
    //     disabled ? " checkbox_disabled" : ""
    //   }${border ? `` : ` _no-border`}${clazz ? ` ${clazz}` : ""}`}
    //   onClick={disabled ? null : onClick}
    //   onKeyUp={onKeyUp}
    // >
    //
    //   <input {...defauldCheckboxProps} className={`${radio ? " radio" : ""}`} />
    //
    //   {!!flag ? (
    //     <Icon icon type="flag" spritePath={`${flag.toUpperCase()}`} />
    //   ) : null}
    //
    //   {icon && (
    //     <Icon icon size={iconSize} spritePath={`${icon}`} clazz={iconClazz} />
    //   )}
    //
    //   {title}
    //
    //   {subTitle && typeof subTitle === "string" ? (
    //     <span>{subTitle}</span>
    //   ) : !!subTitle ? (
    //     subTitle
    //   ) : null}
    // </label>
  );
};

// Checkbox.propTypes = {
//   id: PropTypes.any,
//   name: PropTypes.string,
//   value: PropTypes.string,
//   title: PropTypes.string,
//   disabled: PropTypes.bool,
//   checked: PropTypes.bool,
// };

export default Checkbox;
