import { useSelector } from "react-redux";
import {
  getUserCurrentCity,
  setLanguagesWords,
  setNationalistWords,
  setServicesWords,
  setCountry,
  setCurrentCity,
  setCountriesData,
} from "@/stores/slices/userSlice";
import { getTitle } from "@/stores/slices/titleSlice";
import useDefiningCity from "@/hooks/useDefiningCity";
import useDefiningLang from "@/hooks/useDefiningLang";
import useNavigateToMain from "@/hooks/useNavigateToMain";
import { useLocation } from "react-router-dom";
import IndiLayoutLk from "./IndiLayoutLk";
import AgencyLayoutLk from "./AgencyLayoutLk";
import Layout from "./Layout";
import useLadyService from "@/services/LadyService";
import { useEffect, useState } from "react";
import { makeRequest } from "@/services/makeRequest";
import { setProfileCount } from "@/stores/slices/mapModelSlice";
import { getUserRegion, setRegion } from "@/stores/slices/userSlice";
import { setGmapsApiReady } from "@/stores/slices/mapModelSlice";
import useLanguageRedirect from "@/hooks/useLanguageRedirect";
import { getIsMainPage } from "@/stores/slices/modelsState";

const MainLayout = ({ children }) => {
  const { userType, scrollToTop, dispatch, lang, userCity, navigate, t, city } =
    useLadyService();

  const { pathname } = useLocation();

  useDefiningLang();
  useDefiningCity();
  useNavigateToMain();
  useLanguageRedirect();

  const currentCity = useSelector(getUserCurrentCity);
  const region = useSelector(getUserRegion);

  const postDesc = t("description")?.replace(/%([^%]+)%/g, (match, p1) => {
    switch (p1) {
      case "City":
        return currentCity.title;
      case "Country":
        return region;
      default:
        return match;
    }
  });

  const postCity = t("incity")?.replace(
    /%([^%]+)%/g,
    currentCity.title ? currentCity.title : ""
  );
  const postTitle =
    t("title")?.replace(
      /%([^%]+)%/g,
      currentCity.title ? currentCity.title : ""
    ) +
    " " +
    postCity;
  const title = useSelector(getTitle);
  const titleString = !!title.length
    ? title
        .filter((item) => !!item)
        .map((item) => t(item))
        .join(", ")
    : "";

  const titleParams = titleString
    ? `${userCity.title} - ${t("titleselect")}: ${titleString}`
    : postTitle;

  // let { city: citySegment } = useParams();
  // citySegment = citySegment ?? "prague";

  const isMainPage = useSelector(getIsMainPage);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = "services";
        const method = "GET";

        makeRequest({ route, method }).then((response) => {
          const data = response.data;
          if (!!Object.values(data).length) {
            const citiesData = data.cities;
            const languages = data.languages;
            const nationalities = data.nationalities;
            const services = data.services;

            dispatch(setLanguagesWords(languages));
            dispatch(setNationalistWords(nationalities));
            dispatch(setServicesWords(services));
            const url = window.location.pathname;
            const segments = url.split("/");
            const citySegment = segments[2] || "prague";
            if (
              (isMainPage || url.includes("lk/customer")) &&
              citySegment !== "call" &&
              citySegment !== "cancel"
            ) {
              const country = Object.keys(citiesData)[0];
              let cityExist = isMainPage ? false : true;

              const cities = citiesData[country].cities.map((item) => {
                if (!cityExist && item.slug === citySegment) {
                  cityExist = true;
                }
                return {
                  id: item.id,
                  title: item.name,
                  icon: citiesData[country].code.toUpperCase(),
                  highlight: item.highlight,
                  slug: item.slug,
                  profiles_count: item.profiles_count,
                  sort: item.sort,
                };
              });
              if (!cityExist) {
                console.log("cityExist", cityExist);
                navigate("/404");
                return;
              }
              const currentCity = cities.filter((item) => {
                return item.slug === citySegment;
              });
              dispatch(setCountry(cities));
              dispatch(setCountriesData(citiesData));

              if (!!currentCity?.length) {
                dispatch(setProfileCount(currentCity[0].profiles_count));
                dispatch(setCurrentCity(currentCity[0]));
                dispatch(setRegion(citiesData[country].name));
              }
            } else {
              if(!city) {
                dispatch(setCurrentCity({
                  title: citiesData['cz'].cities.filter(item => item.slug === "prague")[0].name,
                  id: "1",
                  icon: "cz"
                }));
              }
            }
          }
        });
      } catch (error) {
        console.log(error);
      }
    };

    lang && fetchData();
  }, [lang, isMainPage]);

  // const resultLang = lang === "cz" ? "cs" : lang;

  // const mapLink = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA81vyhvrBl3jUTFDK6LwYNm317prcxfVo&loading=async&libraries=places&language=${resultLang}`;
  const mapLink = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA81vyhvrBl3jUTFDK6LwYNm317prcxfVo&libraries=places&language=en`;
  useEffect(() => {
    const loadScript = () => {
      const script = document.createElement("script");
      script.src = mapLink;
      script.async = true;

      script.onload = () => {
        dispatch(setGmapsApiReady(true));
      };

      script.onerror = () => {
        console.error("Google Maps API failed to load.");
      };

      document.body.appendChild(script);
    };

    loadScript();
    
  }, [dispatch]);

  useEffect(() => {
    scrollToTop();
  }, []);

  if (pathname.includes("/lk") && userType === "indi") {
    return (
      <IndiLayoutLk
        titleParams={t("profuser") + " - Lady4Love"}
        postDesc={postDesc}
      >
        {children}
      </IndiLayoutLk>
    );
  }

  if (pathname.includes("/lk") && userType === "agency") {
    return (
      <AgencyLayoutLk titleParams={titleParams} postDesc={postDesc}>
        {children}
      </AgencyLayoutLk>
    );
  }

  return (
    <Layout
      titleParams={titleParams}
      postDesc={
        titleString ? `${t("h1main")}: ${titleString} - ${postCity}` : postDesc
      }
    >
      {children}
    </Layout>
  );
};

export default MainLayout;
