import useLadyService from "@/services/LadyService";
import "./button.scss";

const LoadingButton = (props) => {
  const {
    children,
    size,
    clazz,
    buttonType = "button",
    title,
    square = false,
    onClick,
    disabled = false,
    counter,
    isLoading,
  } = props;

  const { t } = useLadyService();

  let squareClass = `${square ? "button-square" : "button"}`
  let sizeClass = `${size ? square ? ` button-square-${size}` : ` button-${size}` : ""}`
  let classNames = `${squareClass}${sizeClass}${clazz ? ` ${clazz}` : ""}${disabled ? " _disabled" : ""}${counter < 1 ? " empty" : ""}`

  const defaultProps = {
    disabled: isLoading,
    "data-counter": counter,
    title: title,
    type: buttonType,
    onClick: onClick,
    className: classNames,
  };

  return (
    <button
      {...defaultProps}
    >
      {isLoading ? (
        <div className="spinner_root">
          <svg className={`spinner ${size === "s" ? 'spinner-s' : ""}`} viewBox="0 0 50 50">
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="5"
            ></circle>
          </svg>
          {/* <span className="ml-8 videochat__dotted">{t("loading")}</span> */}
          {square ? null : <span className="ml-8">{t("loading")}...</span>}
        </div>
      ) : (
        children
      )}
    </button>
  );
};

export default LoadingButton;
