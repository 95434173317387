import { Icon } from "@/components/ui";
import "./verHeader.scss";
import moment from "moment";
import { HashLink } from "react-router-hash-link";

import { useTranslation } from "react-i18next";
import useLadyService from "@/services/LadyService";

const VerHeader = (props) => {
  const { scrollToVer, scrollToHealt, data } = props;
  const { windowWidth } = useLadyService();

  const { t } = useTranslation("translation");

  const {healthy_at, verified_at, super_verified_at, verify_count } = data;

  const currentTimestamp = new Date().getTime();

  const isSqure = windowWidth < 500;

  const healthy = moment.unix(healthy_at).utc().format("DD.MM.YYYY");
  const verified = moment.unix(verified_at).utc().format("DD.MM.YYYY");

  const isVerifyActive = (!!verified_at && !!verify_count);

  const Money = () => {
    switch (true) {
      case windowWidth >= 700: {
        return (
          <span className="ver__block__info">
            <span className="p2">{t("incomesmeth")}</span>
            <span className="p3 color-600">{t("getmoneyonline")}</span>
          </span>
        );
      }

      default: {
        return <span className="p3 ml-4">{t("moneyfromads")}</span>;
      }
    }
  };

  return (
    <div className="ver__root">
      {windowWidth > 1199 && (
        <div onClick={scrollToVer} className={"td-none ver__block__control"}>
          <Icon
            spritePath={"verify-fill"}
            size={"l"}
            clazz={
              isVerifyActive 
                ? "_green"
                : "_red"
            }
          />
          <div className="ver__block__info">
            {isVerifyActive ? (
              <>
                <span className="p2 color-green">
                  {t("adsver")} ({verify_count})
                </span>
                <span className="p3 color-600">
                  {t("valid")} {t("until")} {verified}
                </span>
              </>
            ) : (
              <>
                <span className="p2 color-green">{t("adsver")}</span>
                <span className="p3  color-red-700">
                  {t("not")} {t("valid")}
                </span>
              </>
            )}
          </div>
        </div>
      )}
      {windowWidth > 1199 && (
        <div onClick={scrollToHealt} className={"td-none ver__block__control"}>
          <Icon
            spritePath={"health-fill"}
            size={"l"}
            clazz={
              !!healthy_at && currentTimestamp < healthy_at * 1000
                ? "_green"
                : "_red"
            }
          />
          <div className="ver__block__info">
            <span className="p2 color-green">{t("healthy")}</span>
            {!!healthy_at && currentTimestamp < healthy_at * 1000 ? (
              <span className="p3 color-600">
                {t("valid")} {t("until")} {healthy}
              </span>
            ) : (
              <span className="p3 color-red-700">
                {t("not")} {t("valid")}
              </span>
            )}
          </div>
        </div>
      )}

      <HashLink
        to="/guide#eroCoins"
        className={"td-none ver__block__money"}
        target="_blank"
      >
        <Icon spritePath={"euro"} size={"l"} />
        {!isSqure && <Money />}
      </HashLink>
    </div>
  );
};

export default VerHeader;
