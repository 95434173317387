import { Button } from "@/components/ui";
import { useTranslation } from "react-i18next";

import { useState } from "react";
import { makeRequest } from "@/services/makeRequest";

import statusElite from "../../../assets/img/status/elite.svg";

import showToast from "../../toast/Toast";

import "./makeEliteAd.scss";
import { Popup } from "../index";
import { Link } from "react-router-dom";

const MakeEliteAdOnePopup = (props) => {
  const { setOpen, id, main_photo, name, setElite, slug } = props;

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const route = `user/elite/set`;

        const method = "POST";
        const payload = {
          profiles: [id],
        };

        const result = await makeRequest({ route, method, payload });
        if (result) {
          setElite(true);
          setIsLoading(false);
          onClose();
          showToast({
            message: t("success"),
            variant: "success",
          });
        }
      } catch (error) {
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };

    fetchData();
  };

  return (
    <Popup
      {...props}
      setOpen={onClose}
      isLoading={isLoading}
      container={"div"}
      clazz={"gap-16"}
    >
      <h3 className="m-12-24">{t("makead")}</h3>

      <picture className={"text-center"}>
        <source srcSet={statusElite} media="(min-width: 767.98px)" />
        <img src={statusElite} alt="" />
      </picture>

      <span className={"p1 text-center"}>
        {t("adelitebonus")}{" "}
        <span className={"color-green"}>
          (+50% {t("toviews")} {t("and")} +25% {t("toorders")}){" "}
        </span>
      </span>

      <Link
        target="_blank"
        to={`/profile/${slug}`}
        className="elite-block hover-line"
      >
        <img src={main_photo} alt={name} title={name} />

        <span className="elite-block__link-to">{name}</span>
      </Link>

      <p className="p1 color-main text-center">
        <span className="fz-24 currency--erocoin old-price">{40}</span>
        {" "}
        <span className="fz-24 currency--erocoin">{20}</span> / {t('month')}
      </p>

      <Button
        size={"l"}
        onClick={onSubmit}
        clazz={"button--green justify-center mt-auto"}
      >
        {t("confirm")}
      </Button>
    </Popup>
  );
};

export default MakeEliteAdOnePopup;
