import useLadyService from "@/services/LadyService";
import {
  Icon,
  Accordion,
  InputInLabel,
  Button,
  LoadingButton,
} from "../../../ui";
import "./ticketContainer.scss";
import { useRef, useState } from "react";
import moment from "moment";
import { makeRequest } from "@/services/makeRequest";
import showToast from "../../../toast/Toast";
import { Dialog } from "./Dialog";
import { Link } from "react-router-dom";
import axios from "axios";
import baseUrl from "@/services/apiConfig";

const TicketContainer = ({ data, setData, allData, handleFileChange }) => {
  const { subject, id, profile, new_count, messages_count } = data;

  const { t, token } = useLadyService();

  const [isActive, setIsActive] = useState(false);

  const [response, setResponse] = useState("");

  const [files, setFiles] = useState(null);

  const handleInput = (e) => {
    handleFileChange(e.target.files, setFiles);
  };
  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const [messages, setMessages] = useState(data?.message?.data || {});
  const [isLoading, setIsLoading] = useState(false);
  const [dialogData, setDialogData] = useState([]);

  const fetchAnswer = async () => {

    setIsLoading(true);
    
    try {
      const resultFiles = [];
      const resultFilesState = [];

      if (files && !!files.length) {
        files.forEach((element) => {
          resultFiles.push(element);
          const fileUrl = URL.createObjectURL(element);

          resultFilesState.push({
            link: fileUrl,
            file_name: element.name,
          });
        });
      }

      const result = await axios.post(
        baseUrl + `tickets/${id}`,
        {
          message: response,
          ...(files && !!files.length && { files: resultFiles }),
        },
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if(result) {
        const newMessage = {
          attach: resultFilesState,
          message: response,
          created_at: Math.floor(Date.now() / 1000),
          self: true,
        };
  
        setDialogData((prev) => [...prev, newMessage]);
  
        setResponse("");
        setIsLoading(false);
        setFiles(null)
      }
      
    } catch (error) {
      setIsLoading(false);
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };
  const fetchArchive = async () => {
    try {
      const route = `tickets/${id}`;

      const method = "DELETE";

      await makeRequest({ route, method });
      const updateData = allData.filter((item) => item.id !== id);
      setData(updateData);

      showToast({
        message: t("success"),
        variant: "success",
      });
    } catch (error) {
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  const [loading, setLoading] = useState(false);

  const createdMoment = moment
    .unix(messages.created_at)
    .format("DD.MM.YYYY HH:mm");

  return (
    <div className="review__root">
      <div className="navigation__top mb-0">
        <div className="res__user__block">
          <Icon size={"l"} spritePath={"edit"} />
          <h3 className="m-0">{subject}</h3>
        </div>
        {!profile && <span>{t("notanad")}</span>}
        {profile && (
          <span className="d-flex gap-8">
            {t("isad")}
            <Link
              target="_blank"
              className="color-green hover_underline"
              to={`/profile/${profile.slug}`}
            >
              {profile.name}
            </Link>
          </span>
        )}
      </div>
      <div className={messages.self ? "" : "message-admin"}>
        <span>
          <span className="p1 color-main">
            {messages.self ? `${t("you")}` : ""}
          </span>{" "}
          {createdMoment}
        </span>

        <p className="p1 color-main">{messages.message}</p>
        {messages.attach && !!messages.attach.length && (
          <div className="d-flex gap-16 flex-wrap">
            {messages.attach.map((item) => {
              return (
                <Link target="_blank" to={item.link} className="color-main">
                  {item.file_name}
                </Link>
              );
            })}
          </div>
        )}
      </div>
      {!isActive && (
        <div
          className="d-flex gap-12 ml-auto cursor-pointer align-center"
          onClick={() => setIsActive((prev) => !prev)}
        >
          {new_count ? <p className="color-green">{t("newmessage")}</p> : <></>}
          <Button square={true} clazz={"button--secondary"} size={"s"}>
            <Icon size={"s"} spritePath={"chevrone-down"} />
          </Button>
        </div>
      )}

      <Accordion
        onLoad={loading}
        title={""}
        open={isActive}
        clazz={"no-border"}
        noHead={true}
      >
        {isActive && (
          <Dialog
            new_count={new_count}
            messages_count={messages_count}
            setDialogData={setDialogData}
            dialogData={dialogData}
            ticketId={data.id}
            setLoading={setLoading}
            loading={loading}
          />
        )}
        <div className={"d-flex fd-column gap-10"}>
          <InputInLabel
            type={"textarea"}
            id={"create-AI"}
            placeholder={""}
            value={response}
            onChange={(e) => setResponse(e.target.value)}
          >
            {t("youranswer")}
          </InputInLabel>
          {files && !!files.length && (
            <div className="d-flex gap-16 flex-wrap">
              {files.map((item) => {
                return <span className="color-main">{item.name}</span>;
              })}
            </div>
          )}
          <div className="add__right">
            {!isLoading && (
              <Button
                buttonType={"button"}
                size={"s"}
                square={false}
                clazz="button--green"
                disabled={!response}
                onClick={() => fetchAnswer()}
              >
                {t("send")}
                <Icon size={"s"} spritePath={"send"} />
              </Button>
            )}
            {isLoading && (
              <LoadingButton
                buttonType={"button"}
                size={"s"}
                square={false}
                isLoading={isLoading}
                clazz="button--green"
              >
                {t("send")}
                <Icon size={"s"} spritePath={"send"} />
              </LoadingButton>
            )}
            <Button
              buttonType={"button"}
              size={"s"}
              square={false}
              clazz="button--secondary"
              onClick={handleButtonClick}
            >
              <input
                ref={fileInputRef}
                type="file"
                multiple="multiple"
                style={{ display: "none" }}
                onChange={handleInput}
              ></input>
              <Icon size={"s"} spritePath={"import"} />
              {t("addfile")}
            </Button>

            <Button
              buttonType={"button"}
              size={"s"}
              square={false}
              clazz={"button_outline--black ml-auto"}
              onClick={() => fetchArchive()}
            >
              {t("toarch")}
            </Button>

            <div
              className="d-flex gap-12 cursor-pointer align-center"
              onClick={() => setIsActive((prev) => !prev)}
            >
              <p>{t("i-collapse")}</p>
              <Button square={true} clazz={"button--secondary"} size={"s"}>
                <Icon size={"s"} spritePath={"chevrone-up"} />
              </Button>
            </div>
          </div>
        </div>
      </Accordion>
    </div>
  );
};

export default TicketContainer;
