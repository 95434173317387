import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { Button, Icon, InputInLabel } from "@/components/ui";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import {
  getFilterMiniPopupStatus,
  getFilterPopupStatus,
  setFilterMiniPopupStatus,
} from "@/stores/slices/filterPopupSlice";
import {
  setAuthorizationStatus,
  setFilterMode,
} from "@/stores/slices/popupSlice";
import useLadyService from "@/services/LadyService";

import "./notifications.scss";
import showToast from "../toast/Toast";
import { makeRequest } from "@/services/makeRequest";
import { getSortedModelsByFilter } from "@/stores/slices/modelsState";
import ReCAPTCHA from "react-google-recaptcha";
import {
  getSavedFiltersIds,
  setSavedFiltersIds,
} from "@/stores/slices/filterPopupSlice";
import { Checkbox } from "../ui";
import { getAllSorted } from "@/stores/slices/modelsState";

const SaveFilterForm = ({ onSubmitForm, fetchData }) => {
  const { dispatch, userType, navigate, t } = useLadyService();

  const isLogin = userType === "default" ? false : true;

  const formSchema = object().shape({
    name: string().required(t("required")),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const [saveText, setSaveText] = useState(false);

  const onSubmit = async (data) => {
    const result = await fetchData(data, "post");

    if (!!result) {
      setSaveText(true);
      reset();
      dispatch(setSavedFiltersIds(result));
    }
    // setTimeout(() => {
    //   onSubmitForm(false);
    //   dispatch(setFilterMiniPopupStatus(false));
    // }, 3000);
  };

  var lkLink = "";
  switch (userType) {
    case "indi":
      lkLink = `/lk/indi/profiles`;
      break;
    case "photograph":
      lkLink = `/lk/photograph`;
      break;
    case "agency":
      lkLink = `/lk/agency`;
      break;
    case "customer":
      lkLink = `/lk/customer`;
      break;
    default:
      lkLink = `/`;
  }

  const toLk = (e) => {
    if (userType === "default") {
      dispatch(setFilterMode(true));
      dispatch(setAuthorizationStatus(true));
    } else {
    }
    e.preventDefault();
    navigate(`${lkLink}`);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="input-label__wrapper notifications__safe-filter"
      >
        <InputInLabel
          clazz={`${errors.name ? " _error" : ""}`}
          id={"loginRegistration"}
          type={"text"}
          placeholder={t("filterplaceholder")}
          register={{ ...register("name") }}
          autocomplete={"off"}
        >
          {(errors.name && (
            <span className={"input-label__error"}>{errors.name.message}</span>
          )) || <span>{t("filtername")}</span>}
        </InputInLabel>

        <Button
          size={"l-forever"}
          buttonType={isLogin ? "submit" : "button"}
          onClick={() => {
            !isLogin && dispatch(setFilterMode(true));
            !isLogin && dispatch(setAuthorizationStatus(true));
          }}
          clazz={"button--primary"}
        >
          {" "}
          {t("save")}
        </Button>
      </form>
      {saveText ? (
        <p className={"notifications__text"}>{t("filterissave")}</p>
      ) : (
        <p className={"notifications__text"}>
          {t("savedfiltersaviable")}{" "}
          <Link to={lkLink} onClick={(e) => toLk(e)}>
            {t("profile")}
          </Link>
        </p>
      )}
    </>
  );
};

const Notifications = () => {
  const { dispatch, t, windowWidth, city, userType, emailVerifyAt } = useLadyService();

  const statusFullPopup = useSelector(getFilterPopupStatus);
  const statusMiniPopup = useSelector(getFilterMiniPopupStatus);

  const formSchema = object().shape({
    email: string().email().required(t("emailisnes")),
    text: string(),
  });
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });
  const filterParams = useSelector(getSortedModelsByFilter);
  const sortByStatus = useSelector(getAllSorted);

  function queryStringToObject(queryString) {
    const params = queryString.split("&");
    const result = {
      city: city,
    };

    params.forEach((param) => {
      const [key, value] = param.split("=");
      result[key] = value;
    });
    if(sortByStatus.length) {
      return {...result, status: sortByStatus.join(',')}
    } else {
      return result
    }
  }
  const [email, setEmail] = useState();
  const recaptchaRef = useRef(null);

  const fetchData = async (data, type = "post", id = false) => {
    try {
      const resultUrl = `user/filter${type === "put" ? `/${id}` : ""}`;
      const route = resultUrl;

      const method = type === "post" ? "POST" : "PUT";
      const payload = {
        filter: queryStringToObject(filterParams),
        title: data.name,
        email: data.email,
        message: data.text,
      };

      const result = await makeRequest({ route, method, payload });
      if (result && type === "post") {
        return result.data.id;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      showToast({
        message: t("oops"),
        variant: "error",
      });
      return false;
    }
  };

  const existSavedFiltersIds = useSelector(getSavedFiltersIds);
  const onSubmit = async (data, e) => {
    e.preventDefault();

    const token = await recaptchaRef.current.executeAsync();

    if (token) {
      const result = await fetchData(
        data,
        !!existSavedFiltersIds ? "put" : "post",
        existSavedFiltersIds ?? false
      );

      if (!!result) {
        reset();
        let resultValues = getValues("email");
        setEmail(resultValues);
        reset();
        setTimeout(() => {
          setIsActive(false);
          dispatch(setFilterMiniPopupStatus(false));
        }, 3000);
      }
      recaptchaRef.current.reset();
    }
  };
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (
      statusFullPopup &&
      statusMiniPopup &&
      windowWidth &&
      windowWidth > 998
    ) {
      setIsActive(true);
    }
  }, [statusFullPopup]);

  useEffect(() => {
    if (statusFullPopup && !statusMiniPopup) {
      setIsActive(false);
    }
  }, [statusMiniPopup]);

  // useEffect(() => {
  //     if (isActive) {
  //         const timeout = setTimeout(() => {
  //             setIsActive(false);
  //         }, 5000);
  //         setTimeoutId(timeout);
  //     }
  // }, [isActive]);

  // const handleMouseLeave = () => {
  //     if (timeoutId) {
  //         const timeout = setTimeout(() => {
  //             setIsActive(false);
  //         }, 5000);
  //         setTimeoutId(timeout);
  //     }
  // }

  // const handleMouseEnter = () => {
  //     if (timeoutId) {
  //         clearTimeout(timeoutId);
  //     }
  // }
  const handleClick = () => {
    setIsActive(true);
  };

  const [notifyType, setNotifyType] = useState([]);

  const [isEmailConfirm, setIsEmailConfirm] = useState(false);
  const [isWaConfirm, setIsWaConfirm] = useState(false);

  const handleNotifyType = (value) => {
    if(value === "email" && !emailVerifyAt) {
      setIsEmailConfirm(true)
      return
    }
    if(value === "whatsapp") {
      setIsWaConfirm(true)
      return
    }
    setNotifyType((prev) => {
      if (notifyType.includes(value)) {
        return prev.filter((item) => item !== value);
      } else {
        return [...prev, value];
      }
    });
  };
  return (
    <>
      {statusMiniPopup && (
        <Button
          size={"s"}
          onClick={() => handleClick()}
          clazz="button--tetriary notifications__main-button user__save"
        >
          <span>{t("savesub")}</span>
          <Icon size={"m"} spritePath={"save"} />
        </Button>
      )}
      {isActive ? (
        <div
          className={`popup-form__body notifications${
            isActive ? " _show" : ""
          }`}
          // onMouseEnter={() => handleMouseEnter()}
          // onMouseLeave={() => handleMouseLeave()}
        >
          <Button
            onClick={() => {
              setIsActive(false);
              dispatch(setFilterMiniPopupStatus(false));
            }}
            clazz={"button__close"}
          >
            <Icon size={"l"} spritePath={"close"} />
          </Button>

          <span className="title_h3 popup-form__title">{t("savefilters")}</span>

          <SaveFilterForm
            recaptchaRef={recaptchaRef}
            onSubmitForm={setIsActive}
            fetchData={fetchData}
          />

          <form
            className={"notifications__subscribe"}
            onSubmit={handleSubmit(onSubmit)}
          >
            <span className="title_h3 popup-form__title">
              {t("notifyaboutnew")}
            </span>
            <label
              htmlFor="saveFilterText"
              className={`input-label popup-form__textarea p1`}
            >
              <span>{t("notificationmessage")}</span>
              <textarea
                {...register("text")}
                placeholder={t("wenotifyaboutnew")}
                id={"saveFilterText"}
              />
            </label>

            {userType === "default" ? (
              <InputInLabel
                clazz={`${errors.email ? " _error" : ""} mb-12`}
                id={"subscribeFilterEmail"}
                type={"email"}
                placeholder={"example@google.com"}
                register={{ ...register("email") }}
                autocomplete={"email"}
              >
                {(errors.email && (
                  <span className={"input-label__error"}>
                    {errors.email.message}
                  </span>
                )) || <span>{t("youremail")}</span>}
              </InputInLabel>
            ) : (
              <>
                <span>{t("notify") + ":"}</span>
                <div className="d-flex gap-8 align-center mt-12">
                  <Checkbox
                    onChange={() => {
                      handleNotifyType("email");
                    }}
                    checked={notifyType.includes("email")}
                    id={"notify-by-email"}
                    clazz={"width-max"}
                    title={"Email"}
                  ></Checkbox>
                  <Checkbox
                    onChange={() => {
                      handleNotifyType("telegram");
                    }}
                    checked={notifyType.includes("telegram")}
                    id={"notify-by-telegram"}
                    clazz={"width-max"}
                    title={"Telegram"}
                  ></Checkbox>
                  <Checkbox
                    onChange={() => {
                      handleNotifyType("whatsapp");
                    }}
                    checked={notifyType.includes("whatsapp")}
                    id={"notify-by-whatsapp"}
                    clazz={"width-max"}
                    title={"WhatsApp"}
                  ></Checkbox>
                </div>
                {isEmailConfirm && 
                  <div className="d-flex gap-8 align-center">
                    <span className="color-red-700">{'Email - ' + t("notconfirmed")}</span>
                    <Link className="color-700" to={`/lk/${userType}#notify`}>{t('confirm')}</Link>
                  </div>
                }
                {isWaConfirm && 
                  <div className="d-flex gap-8 align-center">
                    <span className="color-red-700">{'WhatsApp - ' + t("notconfirmed")}</span>
                    <Link className="color-700" to={`/lk/${userType}#notify`}>{t('confirm')}</Link>
                  </div>
                }
              </>
            )}

            <Button
              size={"l-forever"}
              buttonType={"submit"}
              clazz={"button--green mt-12"}
            >
              {" "}
              {t("subscribe")}
            </Button>
            {email ? (
              <p className={""}>
                {t("onthe")} {email} {t("linksent")}
              </p>
            ) : null}
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LfP8iUqAAAAAEPL4hfdJluwEE6cnuzXd_au2QuP"
              size="invisible"
            />
          </form>
        </div>
      ) : null}
    </>
  );
};
export default Notifications;
