import { Button, ButtonLink, Icon, InputInLabel, Tag } from "../../../ui";
import React, { useEffect, useRef, useState } from "react";
import { makeRequest } from "@/services/makeRequest";
import showToast from "@/components/toast/Toast";
import useLadyService from "@/services/LadyService";
import { show } from "@/stores/slices/showFilterSlice";
import { setFilterEditId, toggleSelected } from "@/stores/slices/filterSlice";
import { toggleState } from "@/stores/slices/filterSlice";
import { Link } from "react-router-dom";

const keysForBreast = [
  "hair",
  "eyes",
  "lens",
  "lips",
  "ethnos",
  "hair_len",
  "intim_hs",
  "piercing",
  "smoking",
  "tattoo",
  "breast",
  "glass",
  'sil', "nat"
];
const valueBreastSizeMapping = {
  small: "breastSmall",
  medium: "breastMedium",
  big: "breastBig",
};
const valueBreastTypeNatMapping = {
  1: "nat",
};
const valueBreastTypeSilMapping = {
  1: "sil",
};
const valueEyeColorMapping = {
  brown: "browns",
};
const valueEyeTypeGlassesMapping = {
  1: "glasses",
};
const valueEyeTypeLensesMapping = {
  1: "lenses",
};
const valueLimpsTypeMapping = {
  natural: "naturale",
};
const valueTattooMapping = {
  few: "few-t",
  many: "many-t",
  no: "no-t",
};
const valuePiercingMapping = {
  few: "few-p",
  many: "many-p",
  no: "no-p",
};
const valueSmokingMapping = {
  sometimes: "sometimes-s",
  regularly: "regularly-s",
  no: "no-s",
};

export const SubsItem = ({ props, setFilterData }) => {
  const { title, email, whatsapp, telegram, filter, id, emailVerifyAt } = props;

  console.log('filter', filter);

  const { t, dispatch, userType } = useLadyService();

  const [showElem, setShowElem] = useState(false);

  const ref = useRef(null);
  const deleteFilter = async (id) => {
    try {
      const route = `user/filter/${id}`;

      const method = "DELETE";

      const result = await makeRequest({ route, method });
      if (result) {
        setFilterData((prev) => prev.filter((item) => item.id !== id));
        showToast({
          message: t("success"),
          variant: "success",
        });
      }
    } catch (error) {
      console.log(error);

      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };
  const editFilter = async (id) => {
    Object.entries(filter).forEach(([key, value]) => {
      const valuesArray = value.split(",");
      valuesArray.forEach((val) => {
        if (keysForBreast.includes(key)) {
          dispatch(toggleSelected({ value: val, name: "breast" }));
          
          if (key === "glass") {
            dispatch(
              toggleState({
                value: valueEyeTypeGlassesMapping[val] || val,
                StateName: "breast",
              })
            );
            return;
          }
          if(key === "nat") {
            dispatch(
              toggleState({
                value: valueBreastTypeNatMapping[val] || val,
                StateName: "breast",
              })
            );
            return;
          }
          if(key === "sil") {
            dispatch(
              toggleState({
                value: valueBreastTypeSilMapping[val] || val,
                StateName: "breast",
              })
            );
            return;
          }
          if (key === "breast") {
            dispatch(
              toggleState({
                value: valueBreastSizeMapping[val] || val,
                StateName: "breast",
              })
            );
            return;
          }
          if (key === "eyes") {
            dispatch(
              toggleState({
                value: valueEyeColorMapping[val] || val,
                StateName: "breast",
              })
            );
            return;
          }
          if (key === "lens") {
            dispatch(
              toggleState({
                value: valueEyeTypeLensesMapping[val] || val,
                StateName: "breast",
              })
            );
            return;
          }
          if (key === "lips") {
            dispatch(
              toggleState({
                value: valueLimpsTypeMapping[val] || val,
                StateName: "breast",
              })
            );
            return;
          }
          if (key === "tattoo") {
            dispatch(
              toggleState({
                value: valueTattooMapping[val] || val,
                StateName: "breast",
              })
            );
            return;
          }
          if (key === "piercing") {
            dispatch(
              toggleState({
                value: valuePiercingMapping[val] || val,
                StateName: "breast",
              })
            );
            return;
          }
          if (key === "smoking") {
            dispatch(
              toggleState({
                value: valueSmokingMapping[val] || val,
                StateName: "breast",
              })
            );
            return;
          }

          dispatch(toggleState({ value: val, StateName: "breast" }));
        }
        if (key === "orient") {
          console.log("gender");

          dispatch(toggleSelected({ value: val, name: "sex" }));
          dispatch(toggleState({ value: val, StateName: "sex" }));
        }
      });
    });
    dispatch(
      setFilterEditId({
        id: id,
        title: title,
      })
    );
    dispatch(show());
  };
  //
  // useEffect(() => {
  //   ref?.current?.innerHeight > 54 && setShow(true);
  // }, [ref]);

  const showMoreParamCount = t("onlyparam")?.replace(/%([^%]+)%/g, "12");
  const [isEmailConfirm, setIsEmailConfirm] = useState(false);
  const [emailCheck, setEmailCheck] = useState(!!email);
  const [tgCheck, setTgCheck] = useState(!!telegram);
  const [waCheck, setWaCheck] = useState(!!whatsapp);

  return (
    <div className={"subs-item"}>
      <h3>
        <Button onClick={() => editFilter(id)} clazz="">
          <Icon size={"m"} spritePath={"edit-2"} />
        </Button>
        {title && title}
        <Button onClick={() => deleteFilter(id)} clazz="ml-auto color-red-700">
          <Icon size={"m"} spritePath={"close"} />
        </Button>
      </h3>

      {filter && (
        <div
          ref={ref}
          className={`subs-item__tags${!!showElem ? " _show" : ""}`}
        >
          {Object.entries(filter).map(([key, value]) => {
            let resultKey = value.split(",");

            return (
              <Tag clazz={"p2 subs-item__tag"}>
                {t(`${[key]}`)}:
                <span>
                  {resultKey?.map((i, index) => {
                    let checkLastComma =
                      index === resultKey.length - 1 ? "" : ", ";

                    return `${t(`${i}`).toLowerCase()}${checkLastComma}`;
                  })}
                </span>
              </Tag>
            );
          })}

          {/*<Tag clazz={`p2 subs-item__tag`}>*/}
          {/*  <Icon title={"Чехия"} type={"flag"} spritePath={"CZ"} />*/}
          {/*  <span>Praha</span>*/}
          {/*</Tag>*/}

          {/*<Tag clazz={"p2 subs-item__tag"}>*/}
          {/*  Место:*/}
          {/*  <span>Выезд</span>*/}
          {/*</Tag>*/}

          {/*<Tag clazz={"p2 subs-item__tag"}>*/}
          {/*  Цена:*/}
          {/*  <span>100-150 €/час</span>*/}
          {/*</Tag>*/}

          {/*<Tag clazz={"p2 subs-item__tag"}>*/}
          {/*  Возраст:*/}
          {/*  <span>18-25 лет</span>*/}
          {/*</Tag>*/}

          {/*<Tag clazz={"p2 subs-item__tag"}>*/}
          {/*  Грудь:*/}
          {/*  <span>большая, средняя</span>*/}
          {/*</Tag>*/}

          {/*<Tag clazz={"p2 subs-item__tag"}>*/}
          {/*  Цена:*/}
          {/*  <span>100-150 €/час</span>*/}
          {/*</Tag>*/}

          {/*<Tag clazz={"p2 subs-item__tag"}>*/}
          {/*  Возраст:*/}
          {/*  <span>18-25 лет</span>*/}
          {/*</Tag>*/}
        </div>
      )}

      {!showElem && Object.entries(filter)?.length >= 3 && (
        <Button
          clazz={"p2 color-green justify-start width-max"}
          onClick={() => setShowElem(true)}
        >
          {showMoreParamCount}
        </Button>
      )}

      <div className="subs-item__notify">
        <span className={"p1 color-main mr-auto"}>{t("notify")}:</span>

        <div>
          <InputInLabel
            type="checkbox"
            size={"none"}
            clazz={`p2 fd-row bg-none`}
            checked={emailCheck}
            onClick={() => {
              if(!emailVerifyAt) {
                setIsEmailConfirm(true)
                return
              } 
              setEmailCheck(prev => !prev)
            }}
          >
            Email
          </InputInLabel>
          <InputInLabel
            type="checkbox"
            size={"none"}
            clazz={`p2 fd-row bg-none`}
            checked={tgCheck}
            onClick={() => {
              setTgCheck(prev => !prev)
            }}
          >
            Telegram
          </InputInLabel>

          <InputInLabel
            type="checkbox"
            size={"none"}
            clazz={`p2 fd-row bg-none`}
            checked={waCheck}
            onClick={() => {
              setWaCheck(prev => !prev)
            }}
          >
            WhatsApp
          </InputInLabel>
        </div>
        {isEmailConfirm && (
          <div className="d-flex gap-8 align-center">
            <span className="color-red-700">
              {"Email - " + t("notconfirmed")}
            </span>
            <Link className="color-700" to={`/lk/${userType}#notify`}>
              {t("confirm")}
            </Link>
          </div>
        )}
      </div>

      <ButtonLink href={"#"} size={"l-forever"} clazz={"button--green"}>
        {t("matching")}
      </ButtonLink>
    </div>
  );
};

export default SubsItem;
