import { Fragment } from "react";
import { ButtonLink } from "../../../../../ui";
import useLadyService from "@/services/LadyService";

import "./steps.scss";

const Steps = ({
  changeStep,
  step,
  availableSteps,
  onClick,
  filled,
  allSteps,
  rootElem,
  setVisualElem,
}) => {
  const { windowWidth, t } = useLadyService();

  const stepsArray = [
    {
      step: "1",
      text: t("main"),
    },
    {
      step: "2",
      text: t("photosandvid"),
    },
    {
      step: "3",
      text: t("appearance"),
    },
    {
      step: "4",
      text: t("services"),
    },
    {
      step: "5",
      text: t("tariffs") + " " + t("and") + " " + t("shedule"),
    },
    {
      step: "6",
      text: t("confirmation"),
    },
  ];

  const handleStep = (index) => {
    changeStep(index + 1);
    if (index + 1 === 2) {
      setVisualElem("photo");
    }
    rootElem?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const isMobile = windowWidth < 767.98;

  return (
    <>
      <div className={`steps__root ${isMobile ? "" : "fd-column"}`}>
        <div className="steps__progressbar">
          <span className="steps__progressbar-text">
            {t("fullon")}
            <span className="progressbar">{filled}%</span>
          </span>
          <div className="progressbar-value">
            <span
              className="progressbar-current"
              style={{ width: `${filled}%` }}
            ></span>
          </div>
        </div>
        {isMobile && (
          <ButtonLink
            clazz={`button_outline--black _no-underline`}
            size={"l"}
            href={"/lk/indi/profiles"}
            onClick={onClick}
          >
            {t("cancel")}
          </ButtonLink>
        )}
        {!isMobile && (
          <div className={"steps__main"}>
            {stepsArray.map((item, index) => {
              return (
                <Fragment key={index}>
                  <button
                    onClick={() => handleStep(index)}
                    className={`step ${step === index + 1 ? "_active" : ""} ${
                      !availableSteps.includes(index + 1) && !allSteps
                        ? "_disabled"
                        : ""
                    }`}
                  >
                    {Number(item.step) === step ? (
                      <span className="step__current complete">
                        {item.step}
                      </span>
                    ) : (
                      <span className="step__current">{item.step}</span>
                    )}

                    <span
                      className={`step__text ${
                        Number(item.step) === step
                          ? "color-green"
                          : allSteps || availableSteps.includes(index + 2)
                          ? "color-main"
                          : ""
                      }`}
                    >
                      {item.text}
                    </span>
                  </button>
                  {index !== stepsArray.length - 1 && (
                    <hr
                      className={
                        !availableSteps.includes(index + 2) && !allSteps
                          ? "disabled"
                          : ""
                      }
                    />
                  )}
                </Fragment>
              );
            })}
          </div>
        )}
      </div>
      {isMobile && (
        <div className={"steps__main"}>
          {stepsArray.map((item, index) => {
            return (
              <Fragment key={index}>
                <button
                  onClick={() => handleStep(index)}
                  className={`step ${step === index + 1 ? "_active" : ""} ${
                    !availableSteps.includes(index + 1) && !allSteps
                      ? "_disabled"
                      : ""
                  }`}
                >
                  {Number(item.step) === step ? (
                    <span className="step__current complete">{item.step}</span>
                  ) : (
                    <span className="step__current">{item.step}</span>
                  )}
                </button>
                {index !== stepsArray.length - 1 && (
                  <hr
                    className={
                      !availableSteps.includes(index + 2) && !allSteps
                        ? "disabled"
                        : ""
                    }
                  />
                )}
              </Fragment>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Steps;
